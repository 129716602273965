<template>
  <v-app>
    <ortho-nav-bar :action="null" v-if="!$route.meta.hideNavbarAndSidebar"></ortho-nav-bar>
      <ortho-side-bar v-if="!$route.meta.hideNavbarAndSidebar"></ortho-side-bar>
    <v-main>
      <v-container :fluid="$route.meta.hideNavbarAndSidebar"
                   :class="{'padding-1px': $route.meta.hideNavbarAndSidebar}">
      <router-view></router-view>
    </v-container>
    </v-main>
  </v-app>
</template>

<script>
import OrthoNavBar from '@/components/OrthoNavBar.vue';
import OrthoSideBar from '@/components/OrthoSideBar.vue';

export default {
  components: {
    OrthoNavBar,
    OrthoSideBar
  },
};
</script>

<style scoped>
.padding-1px {
  padding: 0px !important;
}
</style>
