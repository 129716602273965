import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import OrthoLogin from "@/components/OrthoLogin.vue";
import OrthoUserProfile from "@/components/OrthoUserProfile.vue";
import OrthoSetting from "@/components/OrthoSettings.vue";
import OrthoDash from "@/components/OrthoDash.vue";
import OrthoNewPatient from "@/components/OrthoNewPatient.vue";
import OrthoPatients from "@/components/OrthoPatients.vue";
import OrthoPatientHistory from "@/components/OrthoPatientHistory.vue";
import OrthoExportModels from "@/components/OrthoExportModels.vue";
import Ortho3DEditor from "@/components/Ortho3DEditor.vue"
import OrthoAddPartners from "@/components/OrthoAddPartners.vue"
import OrthoPartners from "@/components/OrthoPartners.vue"
import OrthoPartnersDetail from "@/components/OrthoPartnersDetail.vue"

const routes = [
  { path: "/ortho-login", redirect: "/" },
  {
    path: "/",
    name: "OrthoLogin",
    component: OrthoLogin,
    meta: { requiresAuth: false, hideNavbarAndSidebar: true },
  },
  {
    path: "/ortho-dashboard",
    component: OrthoDash,
    name: "OrthoDash",
    meta: { requiresAuth: true },
  },
  {
    path: "/user-profile",
    component: OrthoUserProfile,
    name: "OrthoUserProfile",
    meta: { requiresAuth: true },
  },
  {
    path: "/new-patient",
    component: OrthoNewPatient,
    name: "OrthoNewPatient",
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-patients",
    component: OrthoPatients,
    name: "OrthoPatients",
    meta: { requiresAuth: true },
  },
  {
    path: "/patient-history",
    component: OrthoPatientHistory,
    name: "OrthoPatientHistory",
    meta: { requiresAuth: true },
  },
  {
    path: "/export-models",
    component: OrthoExportModels,
    name: "OrthoExportModels",
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    component: OrthoSetting,
    name: "OrthoSetting",
    meta: { requiresAuth: true },
  },
  {
    path: "/editor",
    component: Ortho3DEditor,
    name: "Ortho3DEditor",
    meta: { requiresAuth: true, hideNavbarAndSidebar: true },
  },
  {
    path: "/add-partner",
    component: OrthoAddPartners,
    name: "OrthoAddPartners",
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-partners",
    component: OrthoPartners,
    name: "OrthoPartners",
    meta: { requiresAuth: true },
  },
  {
    path: "/partner-details",
    component: OrthoPartnersDetail,
    name: "OrthoPartnersDetail",
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Verifique se a rota requer autenticação
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Este caminho requer autenticação, verifique se o usuário está logado
    // e redirecione para a página de login se não estiver.
    if (!store.state.isLoggedIn) {
      store.commit("setRedirectMessage", "login.unauthorizedAccess");
      next({
        path: "/",
        query: { redirect: to.fullPath }, // Armazena o caminho que o usuário tentou acessar
      });
    } else {
      next(); // está logado, então continue para a rota pretendida
    }
  } else {
    next(); // não requer autenticação, então sempre permita
  }
});

export default router;
