<template>
  <h2>{{$t('settings')}}</h2>
</template>

<script>
export default {
    name: 'ortho-settings'
}
</script>

<style>

</style>
