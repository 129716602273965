export default {
  namespaced: true,

  state: {
    tasks: [],
    scene: null,
    update: null,
    currentAction: null,
    occlusionPlanPoints: [],
    contourPoints: [],
    objectRef: null,
    smoothingRadius: 0,
    currentToothId: 0,
    activeDentalArch: null,
  },

  mutations: {
    ADD_CONTOUR_POINT(state, point) {
      state.contourPoints.push(point);
    },
    CLEAR_CONTOUR_POINTS(state) {
      state.contourPoints = [];
    },
    SET_TASK_EXECUTED(state, taskId) {
      const task = state.tasks.find((t) => t.id === taskId);
      if (task) {
        task.executed = true;
      }
    },
    ADD_TASK(state, task) {
      state.tasks.push(task);
      state.tasks = [...state.tasks];
    },

    CLEAR_TASKS(state) {
      state.tasks = [];
    },
    SET_SCENE(state, scene) {
      state.scene = scene;
    },
    SET_DATE(state, update) {
      state.update = update;
    },
    UNSET_ACTION(state) {
      state.currentAction = null;
    },
    SET_ACTION(state, action) {
      state.currentAction = state.currentAction !== null ? null : action;
    },
    SET_DENTAL_ARCH(state, arch) {
      state.activeDentalArch = arch;
      console.warn('Arco Ativo '+state.activeDentalArch)
    },
    ADD_POINT(state, { point, objectRef }) {
      state.occlusionPlanPoints.push(point);
      state.objectRef = objectRef;
    },
    CREATE_PLAN(state) {
      const newTask = {
        id: Date.now(),
        action: "createOcclusionPlan",
        params: {
          points: state.occlusionPlanPoints,
          objectRef: state.objectRef,
        },
        executed: false,
      };
      state.tasks.push(newTask);
      state.tasks = [...state.tasks];
    },
    CLEAR_OCCLUSION_PLAN_POINTS(state) {
      state.occlusionPlanPoints = [];
    },
    CLEAR_OBJECT_REF(state) {
      state.objectRef = null;
    },
    REMOVE_LAST_CONTOUR_POINT(state) {
      if (state.contourPoints.length > 0) {
        state.contourPoints.pop();
      }
    },
    UPDATE_CONTOUR_POINT(state, { index, newPosition }) {
      if (index >= 0 && index < state.contourPoints.length) {
        // Atualiza diretamente, já que Vue 3 lida com a reatividade através do Proxy
        state.contourPoints[index] = newPosition;
      }
    },
    START_EDGE_SMOOTHING(state, { radius }) {
      state.currentAction = "smoothingEdges";
      state.smoothingRadius = radius;
    },
    STOP_EDGE_SMOOTHING(state) {
      state.currentAction = null;
      state.smoothingRadius = 0;
    },
    SET_CONTOUR_POINTS(state, points) {
      state.contourPoints = points;
    },
    ADD_ARCH_POINT(state, point) {
      if (state.contourPoints.length < 5) {
        state.contourPoints.push(point);
      }
    },
    // Define o dente atualmente selecionado para edição
    SET_CURRENT_TOOTH(state, toothId) {
      state.currentToothId = toothId;
    },
  },

  actions: {
    executeTask({ commit }, taskId) {
      commit("SET_TASK_EXECUTED", taskId);
    },
    updateContourPoint({ commit }, payload) {
      commit("UPDATE_CONTOUR_POINT", payload);
    },
    addTask({ commit }, task) {
      commit("ADD_TASK", task);
    },
    setContourPoints({ commit }, newPoints) {
      commit("SET_CONTOUR_POINTS", newPoints);
    },
    clearTasks({ commit }) {
      commit("CLEAR_TASKS");
    },
    updateScene({ commit }, scene) {
      commit("SET_SCENE", scene);
      console.warn('scene update');
    },
    updateDate({ commit }, update) {
      commit("SET_DATE", update);
    },
    startOcclusionPlanCreation({ commit }) {
      commit("SET_ACTION", "creatingOcclusionPlan");
    },
    endOcclusionPlanCreation({ commit }) {
      commit("UNSET_ACTION");
    },
    startDentalArchCreation({ commit }, dentalArch) {
      console.table(dentalArch)
      commit("SET_DENTAL_ARCH", dentalArch);
      commit("SET_ACTION", "creatingDentalArch");
    },
    endDentalArchCreation({ commit }) {
      commit("SET_DENTAL_ARCH", null);
      commit("SET_ACTION", "creatingDentalArch");
    },
    createDentalArch({ commit, state }) {
      if (state.contourPoints.length === 5 && state.activeDentalArch) {
        const newTask = {
          id: Date.now(),
          action: "createDentalArch",
          params: {
            points: state.contourPoints,
            arch : state.activeDentalArch
          },
          executed: false,
        };
        commit("ADD_TASK", newTask);
      }
    },
    addPointToOcclusionPlan({ commit, state }, { point, objectRef }) {
      if (state.occlusionPlanPoints.length < 3) {
        commit("ADD_POINT", { point, objectRef });
        if (state.occlusionPlanPoints.length === 3) {
          commit("CREATE_PLAN");
        }
      }
    },
    clearOcclusionPlanData({ commit }) {
      commit("CLEAR_OCCLUSION_PLAN_POINTS");
      commit("CLEAR_OBJECT_REF");
    },
    addContourPoint({ commit, state }, point) {
      if (state.currentAction === "markingContour") {
        commit("ADD_CONTOUR_POINT", point);
      }
    },
    startMarkingContour({ commit }) {
      commit("SET_ACTION", "markingContour");
    },
    finishMarkingContour({ commit }) {
      commit("SET_ACTION", null);
    },
    clearContourPoints({ commit }) {
      commit("CLEAR_CONTOUR_POINTS");
    },
    removeLastContourPoint({ commit }) {
      commit("REMOVE_LAST_CONTOUR_POINT");
    },
    startEdgeSmoothing({ commit }, radius) {
      commit("START_EDGE_SMOOTHING", { radius });
    },
    stopEdgeSmoothing({ commit }) {
      commit("STOP_EDGE_SMOOTHING");
    },
    addPointToDentalArch({ commit, state }, point) {
      if (state.contourPoints.length < 5) {
        commit("ADD_ARCH_POINT", point);
      }
    },
    // Ação chamada quando um dente é selecionado para edição
    enterMedialDistalEditMode({ commit }, toothId) {
      // Armazena o ID do dente atualmente selecionado para edição
      commit("SET_CURRENT_TOOTH", toothId);

      // Define a ação atual para o modo de edição de pontos medial/distal
      //commit("SET_ACTION", "definingMedialDistalPoints");
    },
  },
  getters: {
    currentAction: (state) => state.currentAction,
    contourPoints: (state) => state.contourPoints,
    currentUpdate: (state) => state.update,
  },
};
