<template>
    <v-card>
        <v-card-title class="d-flex align-center">

            <h2><v-icon left class="mr-2">mdi-account-plus</v-icon>{{ $t('newPatient') }}</h2>
        </v-card-title>

        <v-tabs v-model="tab" class="bg-primary" dark>
            <v-tab value="patient">{{ $t('patientData') }}</v-tab>
            <v-tab value="model">{{ $t('modelData') }}</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item value="patient">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('patientId')" id="patientId"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('patientExternalId')"
                                id="patientExternalId"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('ssn')" id="ssn"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('firstName')" id="firstName"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('lastName')" id="lastName"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('address')" id="address"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('city')" id="city"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('country')" id="country"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field variant="underlined" :label="$t('clinic')" id="clinic"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
        </v-window>
        <v-window class="mt-3">
            <v-window-item value="model">
                <!-- Conteúdo da aba Dados do Modelo -->
                <v-form>
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex flex-column align-center">
                            <label class="thumbnail-label">{{ $t('maxillaryModel') }}</label>
                            <canvas id="maxillaryCanvas" class="thumbnail-canvas"></canvas>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column align-center">
                            <label class="thumbnail-label">{{ $t('mandibularModel') }}</label>
                            <canvas id="mandibularCanvas" class="thumbnail-canvas"></canvas>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-file-input variant="underlined" :label="$t('maxillaryModelFilename')" accept=".stl,.STL"
                                @change="loadMaxillaryModel"></v-file-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-file-input variant="underlined" :label="$t('mandibularModelFilename')" accept=".stl,.STL"
                                @change="loadMandibularModel"></v-file-input>
                        </v-col>
                    </v-row>
                </v-form>
            </v-window-item>
        </v-window>


        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="2" variant="tonal" class="bg-highlight" text to="/ortho-dashboard">{{ $t('close') }}</v-btn>
            <v-btn elevation="2" variant="tonal" class="bg-primary" text to="/editor">{{ $t('savePatient')
            }}</v-btn>
        </v-card-actions>
    </v-card>
</template>
  
<script>
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import segmentationMethods from "@/components/methods/segmentationMethods";

export default {
    name: 'new-patient',
    data() {
        return {
            tab: null,
            maxillaryPolygons: 0,
            maxillaryVertices: 0,
            mandibularPolygons: 0,
            mandibularVertices: 0
        };
    },
    methods: {
        ...segmentationMethods.methods,
        loadMaxillaryModel(event) {
            //this.loadSTLModel(event.target.files[0], 'maxillaryModelCanvas', 'maxillary');
            this.loadSTLModel(event.target.files[0], 'maxillaryCanvas', 'maxillary');
        },
        loadMandibularModel(event) {
            //this.loadSTLModel(event.target.files[0], 'mandibularModelCanvas', 'mandibular');
            this.loadSTLModel(event.target.files[0], 'mandibularCanvas', 'mandibular');
        },
        modelToLoad(arcType, file) {
                            const newTask = {
                    id: Date.now(),
                    action: 'loadModel',
                    params: { modelType: arcType, file: file },
                    executed: false
                };

                // Adicionando a tarefa na lista de tarefas do editor 3D
                this.$store.dispatch('editor3D/addTask', newTask);
                    },
        loadSTLModel(file, canvasId, arcType) {
            const loader = new STLLoader();
            const reader = new FileReader();

            this.modelToLoad(arcType,file);

            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                const buffer = e.target.result;
                const geometry = loader.parse(buffer);

                geometry.center();
                geometry.computeBoundingSphere();
                geometry.computeBoundingBox();

                const material = new THREE.MeshPhongMaterial({ color: 0xaaaaaa, specular: 0x222222, shininess: 200, side: THREE.DoubleSide });
                const mesh = new THREE.Mesh(geometry, material);

                const vertices = geometry.attributes.position.count;
                const polygons = vertices / 3;

                if (arcType === 'maxillary') {
                    this.maxillaryVertices = vertices;
                    this.maxillaryPolygons = polygons;
                } else if (arcType === 'mandibular') {
                    this.mandibularVertices = vertices;
                    this.mandibularPolygons = polygons;
                }

                const scene = new THREE.Scene();
                // Luz ambiente
                const ambientLight = new THREE.AmbientLight(0xff8040);
                scene.add(ambientLight);

                // Luz direcional principal
                const directionalLight1 = new THREE.DirectionalLight(0xffffff, 3);
                directionalLight1.position.set(0, 500, 500); // Posicionada para iluminar de cima e da frente
                scene.add(directionalLight1);

                // Luz direcional secundária para iluminar o lado oposto do modelo
                const directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
                directionalLight2.position.set(0, -500, -500); // Posicionada para iluminar de baixo e de trás
                scene.add(directionalLight2);

                scene.add(mesh);

                const camera = new THREE.PerspectiveCamera(5, 1, 0.1, 2000);
                camera.position.set(0, arcType === 'maxillary' ? -800 : 800, 0);
                camera.lookAt(0, 0, 0);

                const renderer = new THREE.WebGLRenderer({ canvas: document.getElementById(canvasId), antialias: true });
                renderer.setSize(400, 400);
                renderer.setClearColor(0xf0f0f0);

                const controls = new OrbitControls(camera, renderer.domElement);
                controls.target.copy(geometry.boundingSphere.center);

                // Configura os limites de zoom
                controls.minDistance = 500;
                controls.maxDistance = 2000;

                // Ativa o amortecimento para uma experiência de zoom mais suave (opcional)
                controls.enableDamping = true;
                controls.dampingFactor = 0.25;

                controls.zoomSpeed = 0.2;

                controls.update();

                const animate = function () {
                    requestAnimationFrame(animate);
                    controls.update();
                    renderer.render(scene, camera);
                };

                animate();
            };
        },
    },
    mounted() {
        // Inicialize os modelos 3D aqui, se necessário
    }
};
</script>
  
<style scoped>
.v-card-title {
    font-size: 1rem;
}

.thumbnail-canvas {
    width: 100%;
    height: auto;
    min-width: 400px;
    min-height: 400px;
    border: 1px solid #e4e4e4;
    margin: auto;
}

.thumbnail-label {
    display: block;
    text-align: center;
    margin-bottom: 8px;
    font-size: 1.25rem;
}


/* Estilos adicionais conforme necessário */</style>
  