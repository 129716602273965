<template>
  <h2>{{$t('userProfile')}}</h2>
</template>

<script>
export default {
    name: 'user-profile'
}
</script>

<style>

</style>
