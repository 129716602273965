<template>
    <v-navigation-drawer expand-on-hover rail permanent>


        <v-list dense nav>
            <!-- Dashboard -->
            <v-list-item link to="/ortho-dashboard" prepend-icon="mdi-view-dashboard" :title="$t('dashboard')"
                class="menu-item" active-class="menu-item-active"></v-list-item>

            <!-- Pacientes -->
            <v-list-item link to="/new-patient" prepend-icon="mdi-account-plus" :title="$t('newPatient')" class="menu-item"
                active-class="menu-item-active"></v-list-item>
            <v-list-item link to="/manage-patients" prepend-icon="mdi-account-edit" :title="$t('managePatients')"
                class="menu-item" active-class="menu-item-active"></v-list-item>
            <v-list-item link to="/patient-history" prepend-icon="mdi-history" :title="$t('patientHistory')"
                class="menu-item" active-class="menu-item-active"></v-list-item>

            <!-- Exportar Modelos -->
            <v-list-item link to="/export-models" prepend-icon="mdi-export" :title="$t('exportModel')" class="menu-item"
                active-class="menu-item-active"></v-list-item>

            <!-- Parceiros -->
            <v-list-item link to="/add-partner" prepend-icon="mdi-account-multiple" :title="$t('addPartners')"
                class="menu-item" active-class="menu-item-active"></v-list-item>
            <v-list-item link to="/manage-partners" prepend-icon="mdi-account-multiple-outline"
                :title="$t('managePartners')" class="menu-item" active-class="menu-item-active"></v-list-item>
            <v-list-item link to="/partner-details" prepend-icon="mdi-account-details" :title="$t('partnerDetails')"
                class="menu-item" active-class="menu-item-active"></v-list-item>

            <!-- Configurações -->
            <v-list-item link to="/settings" prepend-icon="mdi-cog" :title="$t('settings')" class="menu-item"
                active-class="menu-item-active"></v-list-item>
        </v-list>



    </v-navigation-drawer>
</template>
  
<script>
export default {
    name: 'ortho-side-bar',

};
</script>
  
<style scoped>

.menu-item:hover {
    background-color: rgb(var(--v-theme-secondary));
    color: rgb(var(--v-theme-text-light));
    /* Cor de fundo para hover */
}

.menu-item-active {
    background-color: rgb(var(--v-theme-primary));
    color: rgb(var(--v-theme-text-light));
}
</style>
  