import * as THREE from "three";

export default {
  methods: {
    createCollisionSphere(position, isCollision = true) {
      // Define o tamanho da esfera com base na sua finalidade
      const radius = isCollision ? 0.2 : 0.1; // Menor para pontos mais próximos

      const geometry = new THREE.SphereGeometry(radius, 32, 32);
      const material = new THREE.MeshPhongMaterial({
          color: isCollision ? 0xffa726 : 0x4287f5, // Cor laranja para colisões, azul para pontos mais próximos
          specular: 0x222222,
          shininess: 400,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.5
      });
      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.copy(position);
      sphere.userData.isCollision = isCollision; // Indica se a esfera marca uma colisão

      return sphere;
  },
    calculateClosestDistanceBetweenTeeth(toothNumberA, toothNumberB) {
      const toothMeshA = this.findToothMesh(toothNumberA);
      const toothMeshB = this.findToothMesh(toothNumberB);

      if (!toothMeshA || !toothMeshB) {
        console.warn("One or both teeth not found.");
        return;
      }

      // Transformações aplicadas
      const transformedGeometryA = this.applyTransformToGeometry(
        toothMeshA.geometry,
        toothMeshA.matrixWorld
      );
      const transformedGeometryB = this.applyTransformToGeometry(
        toothMeshB.geometry,
        toothMeshB.matrixWorld
      );

      const positionsA = transformedGeometryA.attributes.position.array;
      const positionsB = transformedGeometryB.attributes.position.array;

      let collisionPoints = [];
      let minimumDistance = Infinity; // Inicializa o registro da menor distância
      let minimumDistancePoints = null; // Pares de pontos com a menor distância

      for (let i = 0; i < positionsA.length; i += 3) {
        for (let j = 0; j < positionsB.length; j += 3) {
          const dx = positionsA[i] - positionsB[j];
          const dy = positionsA[i + 1] - positionsB[j + 1];
          const dz = positionsA[i + 2] - positionsB[j + 2];
          const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);

          // Atualiza a menor distância e os pontos correspondentes
          if (distance < minimumDistance) {
            minimumDistance = distance;
            minimumDistancePoints = {
              pointA: [positionsA[i], positionsA[i + 1], positionsA[i + 2]],
              pointB: [positionsB[j], positionsB[j + 1], positionsB[j + 2]],
            };
          }

          // Verifica se a distância está abaixo do limiar de colisão
          if (distance <= 0.2) {
            collisionPoints.push({
              pointA: [positionsA[i], positionsA[i + 1], positionsA[i + 2]],
              pointB: [positionsB[j], positionsB[j + 1], positionsB[j + 2]],
              distance: distance,
            });
          }
        }
      }

      let collisionDetected = collisionPoints.length > 0;

      return {
        collisionDetected: collisionDetected,
        collisionPoints: collisionPoints,
        minimumDistance: minimumDistance, // Adiciona a menor distância encontrada ao resultado
        minimumDistancePoints: minimumDistancePoints, // Adiciona os pontos da menor distância ao resultado
      };
    },
    analyzeAdjacentTeeth(toothNumber) {
      const adjacentTeeth = this.getAdjacentTeeth(toothNumber);

      // Encontrando o mesh de colisão ou criando um novo se necessário
      let collisionMesh = this.scene.getObjectByName('collisionMesh');
      if (!collisionMesh) {
          collisionMesh = new THREE.Group();
          collisionMesh.name = 'collisionMesh';
          this.scene.add(collisionMesh);
      } else {
          while (collisionMesh.children.length) {
              collisionMesh.remove(collisionMesh.children[0]);
          }
      }



      adjacentTeeth.forEach(adjacentToothNumber => {
          const analysisResult = this.calculateClosestDistanceBetweenTeeth(toothNumber, adjacentToothNumber);

          if (analysisResult && analysisResult.collisionDetected && "alfa" == "beta") {
              // Itera sobre todos os pontos de colisão
              analysisResult.collisionPoints.forEach(collisionPoint => {
                  const sphere = this.createCollisionSphere(new THREE.Vector3(...collisionPoint.pointA), true);
                  collisionMesh.add(sphere);
              });
          } else if (analysisResult && analysisResult.minimumDistancePoints) {
              // Adiciona esferas azuis e uma linha para os pontos mais próximos se não houve colisão
              const materialLine = new THREE.LineBasicMaterial({ color: 0x0000ff });
              const geometryLine = new THREE.BufferGeometry().setFromPoints([
                  new THREE.Vector3(...analysisResult.minimumDistancePoints.pointA),
                  new THREE.Vector3(...analysisResult.minimumDistancePoints.pointB)
              ]);
              const line = new THREE.Line(geometryLine, materialLine);
              collisionMesh.add(line);

              const sphereA = this.createCollisionSphere(new THREE.Vector3(...analysisResult.minimumDistancePoints.pointA), false);
              const sphereB = this.createCollisionSphere(new THREE.Vector3(...analysisResult.minimumDistancePoints.pointB), false);
              collisionMesh.add(sphereA);
              collisionMesh.add(sphereB);

              // Calcula a posição média entre os dois pontos
              const midPosition = new THREE.Vector3(
                  (analysisResult.minimumDistancePoints.pointA[0] + analysisResult.minimumDistancePoints.pointB[0]) / 2,
                  (analysisResult.minimumDistancePoints.pointA[1] + analysisResult.minimumDistancePoints.pointB[1]) / 2,
                  (analysisResult.minimumDistancePoints.pointA[2] + analysisResult.minimumDistancePoints.pointB[2]) / 2
              );

              // Calcula a direção da linha
              const direction = new THREE.Vector3().subVectors(
                  new THREE.Vector3(...analysisResult.minimumDistancePoints.pointB),
                  new THREE.Vector3(...analysisResult.minimumDistancePoints.pointA)
              ).normalize();

              this.createDistanceLabel(analysisResult.minimumDistance.toFixed(2), midPosition, direction, adjacentToothNumber, collisionMesh);

          }
      });
  },
    alignDentalMapToArch() {
      const toothArch = this.activeDentalArch; // 'maxillary' ou 'mandibular'
      const archPoints = this.idealArchPoints[toothArch];
      const criticalPoint = this.findCriticalPointIndex(archPoints); // Encontrar o ponto mais alto
      const existingTeethIndices = [];

      // Matriz de multiplicadores
      const toothMovementMultipliers = {
        1: 1.0, // Incisivos centrais
        2: 1.0, // Incisivos laterais
        3: 1.0, // Caninos
        4: 1.0, // Primeiros pré-molares
        5: 0.1, // Segundos pré-molares
        6: 0.1, // Primeiros molares
        7: 0.1, // Segundos molares
        8: 0.1, // Terceiros molares
      };

      // Coletar índices dos dentes existentes
      for (const toothNumber in this.dentalMap) {
        if (
          this.findTeethInScene().some(
            (t) => t.userData.toothNumber == toothNumber
          )
        ) {
          existingTeethIndices.push(toothNumber);
        }
      }

      let factorMiddle = toothArch === "maxillary" ? 14 : 10;
      let leftSideIndex = criticalPoint - factorMiddle;
      let rightSideIndex = criticalPoint + factorMiddle;

      existingTeethIndices.forEach((toothNumber) => {
        const toothData = this.dentalMap[toothNumber];
        const direction = this.calculateDirection(toothNumber);

        const medial = toothData.medial;
        const distal = toothData.distal;
        let chosenPoint;

        // Escolher o ponto mais próximo do sideIndex atual
        if (direction < 0) {
          chosenPoint =
            this.calculateDistance(medial, archPoints[leftSideIndex]) <
            this.calculateDistance(distal, archPoints[leftSideIndex])
              ? medial
              : distal;
        } else {
          chosenPoint =
            this.calculateDistance(medial, archPoints[rightSideIndex]) <
            this.calculateDistance(distal, archPoints[rightSideIndex])
              ? medial
              : distal;
        }

        chosenPoint = toothData.centerOfMass;

        // Ajustar a posição do dente em relação ao ponto escolhido
        const sideIndex = direction < 0 ? leftSideIndex : rightSideIndex;
        const targetPoint = archPoints[sideIndex];
        let deltaX = targetPoint.x - chosenPoint.x;
        let deltaZ = targetPoint.z - chosenPoint.z;

        // Aplicar o multiplicador ao deslocamento
        const lastDigit = toothNumber % 10;
        const multiplier = toothMovementMultipliers[lastDigit] || 1.0;
        deltaX *= multiplier;
        deltaZ *= multiplier;

        this.adjustToothPosition(
          toothNumber,
          toothData.centerOfMass.x + deltaX,
          toothData.centerOfMass.z + deltaZ
        );
        toothData.centerOfMass.x += deltaX;
        toothData.centerOfMass.z += deltaZ;
        toothData.closestPoint = targetPoint;
        toothData.pointIndex = sideIndex / 256;

        // Ajustar os pontos medial e distal com base no deslocamento
        if (toothData.medial && toothData.distal) {
          toothData.medial.x += deltaX;
          toothData.medial.z += deltaZ;
          toothData.distal.x += deltaX;
          toothData.distal.z += deltaZ;
        }

        // Calcular o novo targetIndex considerando a distância entre medial e distal
        const distanceBetweenPoints = this.calculateDistance(medial, distal);
        let newTargetIndex = this.findNearestPointIndexByDistance(
          sideIndex,
          direction,
          distanceBetweenPoints,
          archPoints
        );

        // Atualizar o sideIndex correspondente
        if (direction < 0) {
          leftSideIndex = Math.max(
            0,
            Math.min(archPoints.length - 1, newTargetIndex - 2)
          );
        } else {
          rightSideIndex = Math.max(
            0,
            Math.min(archPoints.length - 1, newTargetIndex + 2)
          );
        }
      });
    },
    alignDentalMapToArch__() {
      const toothArch = this.activeDentalArch; // 'maxillary' ou 'mandibular'
      const archPoints = this.idealArchPoints[toothArch];
      const criticalPoint = this.findCriticalPointIndex(archPoints); // Encontrar o ponto mais alto
      const existingTeethIndices = [];

      // Coletar índices dos dentes existentes
      for (const toothNumber in this.dentalMap) {
        if (
          this.findTeethInScene().some(
            (t) => t.userData.toothNumber == toothNumber
          )
        ) {
          existingTeethIndices.push(toothNumber);
        }
      }

      let leftSideindex = criticalPoint - 50;
      let rightSideindex = criticalPoint + 50;

      existingTeethIndices.forEach((toothNumber) => {
        const toothData = this.dentalMap[toothNumber];
        const direction = this.calculateDirection(toothNumber);

        const sideIndex = direction < 0 ? leftSideindex : rightSideindex;
        const factor =
          sideIndex === criticalPoint
            ? toothArch === "maxillary"
              ? 0.5
              : 0.4
            : 1 +
              0.25 * this.isMolar(toothNumber) -
              (toothArch !== "maxillary" && this.isIncisor(toothNumber)
                ? 0.4
                : 0);

        console.table({ toothArch, factor });

        let targetIndex = this.findNearestPointIndexByDistance(
          sideIndex,
          direction,
          factor * toothData.radius,
          archPoints
        );

        leftSideindex = direction < 0 ? targetIndex : leftSideindex;
        rightSideindex = direction > 0 ? targetIndex : rightSideindex;

        targetIndex = Math.max(0, Math.min(archPoints.length - 1, targetIndex));
        const targetPoint = archPoints[targetIndex];
        const deltaX = targetPoint.x - toothData.centerOfMass.x;
        const deltaZ = targetPoint.z - toothData.centerOfMass.z;

        this.adjustToothPosition(toothNumber, targetPoint.x, targetPoint.z);
        toothData.centerOfMass.x = targetPoint.x;
        toothData.centerOfMass.z = targetPoint.z;
        toothData.closestPoint = targetPoint;
        toothData.pointIndex = targetIndex / 256;

        // Aplica o mesmo deslocamento aos pontos medial e distal
        if (toothData.medial && toothData.distal) {
          toothData.medial.x += deltaX;
          toothData.medial.z += deltaZ;
          toothData.distal.x += deltaX;
          toothData.distal.z += deltaZ;
        }
      });
    },
    alignDentalMapToArch_() {
      const teethInScene = this.findTeethInScene(); // Esta função deveria ser definida em algum lugar do seu código

      for (const toothNumber in this.dentalMap) {
        const toothData = this.dentalMap[toothNumber];
        // Encontra e atualiza o dente na cena
        const toothInScene = teethInScene.find(
          (t) => t.userData.toothNumber == toothNumber
        );

        if (toothInScene) {
          // Encontrar o ponto do arco mais próximo ao centro de massa do dente
          let closestPoint = null;
          let minDistance = Infinity;
          let pointIndex = 0;

          const toothArch =
            parseInt(toothNumber) < 31 ? "maxillary" : "mandibular";

          this.idealArchPoints[toothArch].forEach((point, index) => {
            // Ignora a componente Y ao calcular a distância
            const distXZ = Math.sqrt(
              Math.pow(point.x - toothData.centerOfMass.x, 2) +
                Math.pow(point.z - toothData.centerOfMass.z, 2)
            );

            if (distXZ < minDistance) {
              minDistance = distXZ;
              closestPoint = point;
              pointIndex = index / 256;
            }
          });

          // Calcula o deslocamento antes de atualizar o centro de massa
          const deltaX = closestPoint.x - toothData.centerOfMass.x;
          const deltaZ = closestPoint.z - toothData.centerOfMass.z;

          // Atualiza a posição do dente na cena 3D
          this.adjustToothPosition(toothNumber, closestPoint.x, closestPoint.z);

          // Atualiza o centro de massa no dentalMap
          toothData.centerOfMass.x = closestPoint.x;
          toothData.centerOfMass.z = closestPoint.z;
          toothData.closestPoint = closestPoint;
          toothData.pointIndex = pointIndex;

          // Aplica o mesmo deslocamento aos pontos medial e distal
          if (toothData.medial && toothData.distal) {
            toothData.medial.x += deltaX;
            toothData.medial.z += deltaZ;
            toothData.distal.x += deltaX;
            toothData.distal.z += deltaZ;
          }
        }
      }
    },
    findNewIdealPoint(toothNumber, adjacentToothNumber) {
      let bestPoint = null;
      let minimumDistanceWithoutCollision = Infinity;
      const adjacentToothData = this.dentalMap[adjacentToothNumber];

      // Calcula a distância máxima para considerar um ponto no arco ideal baseado no raio do dente
      const maxDistanceFromToothCenter = 0.5; // Raio do dente + margem de segurança
      const toothCenter = adjacentToothData.closestPoint;

      const toothArch = parseInt(toothNumber) < 38 ? "maxillary" : "mandibular";
      // Filtra os pontos do arco ideal que estão dentro do raio máximo de distância
      const potentialIdealPoints = this.idealArchPoints[toothArch].filter(
        (point) => {
          // Calcula a distância no plano XZ, ignorando a componente Y
          const distXZ = Math.sqrt(
            Math.pow(point.x - toothCenter.x, 2) +
              Math.pow(point.z - toothCenter.z, 2)
          );

          return distXZ <= maxDistanceFromToothCenter;
        }
      );

      potentialIdealPoints.forEach((point) => {
        // Ajusta a posição x e z do dente adjacente no arco ideal
        this.adjustToothPosition(adjacentToothNumber, point.x, point.z);

        // Calcula a distância entre os dentes
        const distanceInfo = this.calculateClosestDistanceBetweenTeeth(
          toothNumber,
          adjacentToothNumber
        );

        // Verifica se o ponto atual é melhor que o melhor ponto encontrado até agora
        if (
          !distanceInfo.collisionDetected &&
          distanceInfo.minimumDistance < minimumDistanceWithoutCollision
        ) {
          minimumDistanceWithoutCollision = distanceInfo.minimumDistance;
          bestPoint = point.clone();
        }

        // Restaura a posição original do dente adjacente
        this.restoreToothPosition(adjacentToothNumber);
      });

      return bestPoint;
    },

    // Método auxiliar para ajustar a posição do dente
    adjustToothPosition(toothNumber, x, z) {
      const toothMesh = this.findToothMesh(toothNumber);
      if (toothMesh) {
        toothMesh.position.set(x, toothMesh.position.y, z);
        toothMesh.updateMatrixWorld();
      }
    },

    // Método auxiliar para restaurar a posição original do dente
    restoreToothPosition(toothNumber) {
      const toothData = this.dentalMap[toothNumber];
      const toothMesh = this.findToothMesh(toothNumber);
      if (toothMesh) {
        toothMesh.position.set(
          toothData.centerOfMass.x,
          toothMesh.position.y,
          toothData.centerOfMass.z
        );
        toothMesh.updateMatrixWorld();
      }
    },

    alignInterdental() {
      const teethInScene = this.findTeethInScene();
      Object.keys(this.dentalMap).forEach((toothNumber) => {
        const toothData = this.dentalMap[toothNumber];

        const toothInScene = teethInScene.find(
          (t) => t.userData.toothNumber == toothNumber
        );

        if (toothData && toothInScene) {
          const adjacentTeethNumbers = this.getAdjacentTeeth(toothNumber);

          adjacentTeethNumbers.forEach((adjacentToothNumber) => {
            const adjacentToothData = this.dentalMap[adjacentToothNumber];

            if (adjacentToothData.centerOfMass) {
              const newIdealPoint = this.findNewIdealPoint(
                toothNumber,
                adjacentToothNumber
              );

              if (newIdealPoint) {
                adjacentToothData.centerOfMass.x = newIdealPoint.x;
                adjacentToothData.centerOfMass.z = newIdealPoint.z;
                adjacentToothData.closestPoint = newIdealPoint.clone();

                // Atualiza a posição do dente na cena 3D
                this.adjustToothPosition(
                  adjacentToothNumber,
                  newIdealPoint.x,
                  newIdealPoint.z
                );
              }
            }
          });
        }
      });
    },
    alignDentalAngular() {
      Object.keys(this.dentalMap).forEach((toothNumber) => {
        const toothArch =
          parseInt(toothNumber) < 38 ? "maxillary" : "mandibular";
        const curve = new THREE.CatmullRomCurve3(
          this.idealArchStartPoints[toothArch],
          false,
          "catmullrom",
          0.5
        );
        const toothData = this.dentalMap[toothNumber];
        // Certifique-se de que os dados necessários estão presentes

        if (
          toothData &&
          toothData.medial &&
          toothData.distal &&
          toothData.pointIndex
        ) {
          // Usar o closestPoint do dentalMap
          const pointIndex = toothData.pointIndex;
          // Calcular a tangente do arco nesse ponto
          const tangent = this.calculateTangentAtPoint(curve, pointIndex);

          // Obter o vetor atual da orientação do dente
          const currentOrientationVector = new THREE.Vector3()
            .subVectors(toothData.distal, toothData.medial)
            .normalize();

          // Calcular o ângulo e o eixo de rotação necessários para alinhar a linha do dente com a tangente do arco
          const angleToRotate = currentOrientationVector.angleTo(tangent);
          const axisOfRotation = new THREE.Vector3()
            .crossVectors(currentOrientationVector, tangent)
            .normalize();

          // Verificar se a rotação é necessária e aplicá-la
          if (angleToRotate > 0.01) {
            // Um pequeno threshold para evitar rotação desnecessária
            this.applyRotationToTooth(
              toothData,
              toothNumber,
              angleToRotate,
              axisOfRotation
            );
          }
        }
      });
    },
    drawLine(geometry, startPoint, endPoint, material) {
      // Atualiza os pontos da geometria
      const points = [startPoint, endPoint];
      geometry.setFromPoints(points);

      // Cria ou atualiza a linha
      if (geometry.userData.line) {
        geometry.userData.line.geometry = geometry;
      } else {
        const line = new THREE.Line(geometry, material);
        geometry.userData.line = line;
        this.scene.add(line);
      }
    },
    alignDentalAngularToTangent(show = false) {
      Object.keys(this.dentalMap).forEach((toothNumber) => {
        // Processa apenas o dente especificado

        console.warn(`Alinhamento Tangencial Dente ${toothNumber}`);

        // Materiais para as linhas
        const lineMaterialRed = new THREE.LineBasicMaterial({
          color: 0xff0000,
        }); // Cor vermelha para destaque
        const lineMaterialGreen = new THREE.LineBasicMaterial({
          color: 0x00ff00,
        }); // Cor verde para destaque
        const lineMaterialBlue = new THREE.LineBasicMaterial({
          color: 0x0000ff,
        }); // Cor azul para destaque

        // Geometrias para as linhas
        const tangentLineGeometry = new THREE.BufferGeometry();
        const directionLineGeometry = new THREE.BufferGeometry();
        const pivotLineGeometry = new THREE.BufferGeometry();

        const toothArch =
          parseInt(toothNumber) < 31 ? "maxillary" : "mandibular";
        const curve = new THREE.CatmullRomCurve3(
          this.idealArchStartPoints[toothArch],
          false,
          "catmullrom",
          0.5
        );

        const toothData = this.dentalMap[toothNumber];
        console.table(toothData);
        if (
          toothData &&
          toothData.medial &&
          toothData.distal &&
          toothData.pointIndex
        ) {
          const toothMesh = this.findToothMesh(toothNumber);
          const tangent = curve.getTangentAt(toothData.pointIndex).normalize();
          tangent.y = 0;
          tangent.normalize();

          const currentDirection = new THREE.Vector3()
            .subVectors(toothData.distal, toothData.medial)
            .normalize();
          currentDirection.y = 0;
          currentDirection.normalize();

          const midPoint = new THREE.Vector3()
            .addVectors(toothData.medial, toothData.distal)
            .multiplyScalar(0.5);

          const pivotDirection = new THREE.Vector3()
            .subVectors(midPoint, toothData.centerOfMass)
            .normalize();

          if (show) {
            // Desenha a tangente, direção atual e pivot
            this.drawLine(
              tangentLineGeometry,
              toothMesh.position,
              new THREE.Vector3().addVectors(
                toothMesh.position,
                tangent.multiplyScalar(10)
              ),
              lineMaterialRed
            );
            this.drawLine(
              directionLineGeometry,
              toothMesh.position,
              new THREE.Vector3().addVectors(
                toothMesh.position,
                currentDirection.multiplyScalar(10)
              ),
              lineMaterialGreen
            );
            this.drawLine(
              pivotLineGeometry,
              toothMesh.position,
              new THREE.Vector3().addVectors(
                toothMesh.position,
                pivotDirection.multiplyScalar(10)
              ),
              lineMaterialBlue
            );
          }

          const zAxis = new THREE.Vector3(0, 0, 1);
          const angleToZ = pivotDirection.angleTo(zAxis);
          const angleToZDegrees = angleToZ * (180 / Math.PI);

          const isUpper = parseInt(toothNumber) <= 28;
          const difference = isUpper ? Math.PI / 6 : Math.PI / 2;
          const angleDifference = angleToZ - difference;

          const quaternionZ = new THREE.Quaternion();
          quaternionZ.setFromAxisAngle(
            new THREE.Vector3(1, 0, 0),
            angleDifference
          );

          if ("9".includes(toothNumber[toothNumber.length - 1])) {
            toothMesh.quaternion.multiplyQuaternions(
              quaternionZ,
              toothMesh.quaternion
            );
            toothMesh.updateMatrixWorld();

            this.logTransformations(toothMesh, toothData, quaternionZ, "Z");
          }

          console.warn(
            `Angulo em Z da Pivot do dente ${toothNumber}: ${angleToZDegrees}º`
          );

          var angle = currentDirection.angleTo(tangent);
          if (angle > Math.PI / 2) {
            angle = Math.PI - angle;
          }

          const cross = new THREE.Vector3().crossVectors(
            currentDirection,
            tangent
          );
          const direction = Math.sign(cross.y);

          const quaternion = new THREE.Quaternion();
          quaternion.setFromAxisAngle(
            new THREE.Vector3(0, 1, 0),
            direction * angle
          );

          toothMesh.quaternion.multiplyQuaternions(
            quaternion,
            toothMesh.quaternion
          );

          this.applyRotationAndLog(toothMesh.quaternion);

          toothMesh.updateMatrixWorld();

          this.logTransformations(toothMesh, toothData, quaternion, "Y");
        }
      });

      this.scene.updateMatrixWorld(true);
    },
    applyRotationAndLog(quaternion) {
      // Converte o quaternion final para Euler
      const euler = new THREE.Euler().setFromQuaternion(quaternion, "XYZ");

      // Converte de radianos para graus
      const rotationX = (euler.x * 180) / Math.PI;
      const rotationY = (euler.y * 180) / Math.PI;
      const rotationZ = (euler.z * 180) / Math.PI;

      // Loga as rotações
      console.log(
        `Rotações aplicadas - X: ${rotationX.toFixed(
          2
        )}°, Y: ${rotationY.toFixed(2)}°, Z: ${rotationZ.toFixed(2)}°`
      );
    },
    logTransformations(toothMesh, toothData, quaternion, axis) {
      // Transforma e loga os detalhes das posições dos pontos
      const medialLocal = toothMesh.worldToLocal(
        new THREE.Vector3(
          toothData.medial.x,
          toothData.medial.y,
          toothData.medial.z
        ).clone()
      );
      const distalLocal = toothMesh.worldToLocal(
        new THREE.Vector3(
          toothData.distal.x,
          toothData.distal.y,
          toothData.distal.z
        ).clone()
      );

      medialLocal.applyQuaternion(quaternion);
      distalLocal.applyQuaternion(quaternion);

      const medialGlobal = toothMesh.localToWorld(medialLocal);
      const distalGlobal = toothMesh.localToWorld(distalLocal);

      // Atualiza e loga as novas posições globais dos pontos
      toothData.medial = {
        x: medialGlobal.x,
        y: medialGlobal.y,
        z: medialGlobal.z,
      };
      toothData.distal = {
        x: distalGlobal.x,
        y: distalGlobal.y,
        z: distalGlobal.z,
      };

      console.log(
        `Após rotação ${axis} - Medial: `,
        toothData.medial,
        "Distal: ",
        toothData.distal
      );
    },
    calculateAllInterdentalDistances() {
      // Objeto para armazenar as distâncias entre os dentes
      const dentalMap = this.dentalMap;
      const interdentalDistances = {};

      // Varrendo todos os dentes no dentalMap
      for (const toothNumber in dentalMap) {
        // Pular se não há dados para o dente atual (exemplo de dente ausente)
        if (dentalMap[toothNumber]) {
          // Obter os dentes adjacentes
          const adjacentTeeth = this.getAdjacentTeeth(toothNumber);
          interdentalDistances[toothNumber] = {};

          // Varrendo cada dente adjacente para calcular a distância
          adjacentTeeth.forEach((adjacentToothNumber) => {
            if (dentalMap[adjacentToothNumber]) {
              // Calcular a distância entre o dente atual e o dente adjacente
              const distanceInfo = this.calculateClosestDistanceBetweenTeeth(
                toothNumber,
                adjacentToothNumber
              );

              // Armazenar a distância no objeto interdentalDistances
              if (distanceInfo && distanceInfo.minimumDistancePoints) {
                interdentalDistances[toothNumber][adjacentToothNumber] =
                  distanceInfo.minimumDistance;
              }
            }
          });
        }
      }
      console.table(interdentalDistances);

      return interdentalDistances;
    },
    findCriticalPointIndex(points, isMax = true) {
      if (points.length === 0) {
        return -1; // Retorna -1 se o array de pontos estiver vazio
      }

      let criticalIndex = 0;
      let criticalPoint = points[0];

      points.forEach((point, index) => {
        if (
          (isMax && point.z > criticalPoint.z) ||
          (!isMax && point.z < criticalPoint.z)
        ) {
          criticalPoint = point;
          criticalIndex = index;
        }
      });

      return criticalIndex;
    },
    calculateDirection(toothNumber) {
      // Checa se o dente está à esquerda dos centrais na arcada superior
      if (
        (toothNumber >= 11 && toothNumber <= 18) ||
        (toothNumber >= 41 && toothNumber <= 48)
      ) {
        return -1; // Mover para a esquerda
      } else {
        return 1; // Mover para a direita
      }
    },
    findNearestPointIndexByDistance(
      startIndex,
      direction,
      distance,
      archPoints
    ) {
      let accumulatedDistance = 0;
      let currentIndex = startIndex;
      let previousPoint = archPoints[startIndex];

      console.table({
        startIndex,
        direction,
        distance,
        archPoints,
        previousPoint,
      });

      while (accumulatedDistance < distance) {
        currentIndex += direction;

        // Verifica se o índice está dentro dos limites da lista de pontos
        if (currentIndex < 0 || currentIndex >= archPoints.length) {
          console.log("Reached the end of the points array");
          return currentIndex - direction; // Retorna o último índice válido
        }

        let currentPoint = archPoints[currentIndex];
        accumulatedDistance += this.calculateDistance(
          previousPoint,
          currentPoint
        );
        previousPoint = currentPoint;

        // Se a distância acumulada exceder ou igualar a distância desejada, pare.
        if (accumulatedDistance >= distance) {
          return currentIndex;
        }
      }

      return currentIndex; // Retornará este índice se a condição de parada for atingida dentro do loop
    },
    calculateDistance(pointA, pointB) {
      console.table(pointA, pointB);
      return Math.sqrt(
        Math.pow(pointB.x - pointA.x, 2) +
          Math.pow(pointB.y - pointA.y, 2) +
          Math.pow(pointB.z - pointA.z, 2)
      );
    },
    isIncisor(toothNumber) {
      const lastDigit = toothNumber % 10;

      return lastDigit === 1 || lastDigit === 2 || lastDigit === 3;
    },
    isMolar(toothNumber) {
      const lastDigit = toothNumber % 10;

      return lastDigit === 6 || lastDigit === 7 || lastDigit === 8;
    },
    isPreMolar(toothNumber) {
      const lastDigit = toothNumber % 10;

      return lastDigit === 5;
    },
  },
};
