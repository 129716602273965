<template>
  <v-app-bar app>
    <div class="d-flex align-center">
      <!-- Título da Aplicação -->
      <v-toolbar-title class="mx-5">
        Smart Evolution
      </v-toolbar-title>

      <!-- Links de Navegação -->
      <v-btn text to="/ortho-dashboard">{{ $t('dashboard') }}</v-btn>

      <v-btn class="ml-2" text to="/editor">{{ $t('editor') }}</v-btn>

      <v-btn prepend-icon="mdi-cog-play" class="ml-2" v-if="action" text>{{ $t(action) }}</v-btn>

      <v-btn prepend-icon="mdi-comment-processing" class="ml-2" v-if="message" text>{{ message }}</v-btn>

    </div>

    <v-spacer></v-spacer>
    <v-btn text :class=iaActive>I.A.
      <v-icon>mdi-head-cog-outline</v-icon>
    </v-btn>
    <!-- Ícones e Funcionalidades Adicionais -->
    <ortho-languages />
    <v-btn icon @click="adjustFontSize">
      <v-icon>mdi-format-size</v-icon>
    </v-btn>

    <v-btn icon @click="toggleTheme">
      <v-icon>{{ themeIcon }}</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <v-btn icon @click="goToUserProfile">
      <v-icon>mdi-account-circle</v-icon>
    </v-btn>
    <v-btn icon @click="logout">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script setup>
import { useTheme } from 'vuetify'
import OrthoLanguages from '@/components/OrthoLanguages.vue'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineProps } from 'vue'

// Definindo props
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  action: String,
  message: String,
  iaActive: String
})

const theme = useTheme()
const router = useRouter();
const store = useStore();

const fontSize = ref(getSavedFontSize());

function adjustFontSize() {
  // Incrementa o tamanho da fonte em 2px, com mínimo de 10px e máximo de 16px
  fontSize.value = fontSize.value < 20 ? fontSize.value + 2 : 10;
  // Aplica o tamanho da fonte ao body
  document.documentElement.style.fontSize = `${fontSize.value}px`;
  // Salva o tamanho da fonte no localStorage
  localStorage.setItem('fontSize', fontSize.value.toString());
}

// Carrega o tamanho da fonte salvo ao iniciar a aplicação
function getSavedFontSize() {
  const savedSize = localStorage.getItem('fontSize');
  return savedSize ? parseInt(savedSize, 10) : 12; // 12px é o tamanho padrão se não houver nada salvo
}

onMounted(() => {
  // Aplica o tamanho da fonte salvo ao carregar a página
  document.documentElement.style.fontSize = `${fontSize.value}px`;
});

const logout = () => {
  store.commit('setLoggedIn', false);
  store.dispatch('editor3D/clearTasks')
  router.push('/ortho-login');
};

const goToUserProfile = () => {
  router.push('/user-profile');
}

const themeIcon = computed(() => {
  return theme.global.name.value === 'dark' ? 'mdi-weather-night' : 'mdi-weather-sunny';
})

function toggleTheme() {
  theme.global.name.value = theme.global.name.value === 'dark' ? 'OrthoLightTheme' : 'dark';
}
</script>


<style scoped>
.v-btn--active {
  background-color: rgb(var(--v-theme-highlight))!important; /* substitua #suaCorPreferida pela cor que deseja */
}

.gray {
  background-color: lightgrey;
}

.orange{
  background-color: orange;
}

.yellow{
  background-color: yellow;
}

.blue{
  background-color: blue;
}

</style>
