<template>
    <h2>{{$t('managePartners')}}</h2>
  </template>
  
  <script>
  export default {
      name: 'ortho-partners'
  }
  </script>
  
  <style>
  
  </style>
  