<template>
    <v-navigation-drawer v-model="drawer" app permanent class="side-nav">
        <!-- Seção de botões para mudar o modo -->
        <div class="mode-selector d-flex justify-center">
            <v-btn @click="setMode('')">{{ $t('functions') }}</v-btn>
            <v-btn @click="setMode('alignPlan')">{{ $t('alignPlan') }}</v-btn>
            <!-- Adicione mais botões conforme necessário -->
        </div>
        <div v-if="mode === ''">
            <h3 class="mb-2">{{ $t('functions') }}</h3>
            <div class="buttons-container">
                <v-btn v-for="item in items" :key="item.value" :loading="item.loading" :elevation="2"
                    @click="item.isFile ? handleFileClick(item.action) : handleClick(item.action)"
                    :prepend-icon="item.icon" :text="$t(item.text)" size="small" class="btn-nav" stacked>
                </v-btn>
            </div>
            <input type="file" ref="fileInput" @change="handleFileLoad" style="display: none;" />
            <v-divider class="mt-4"></v-divider>
            <ortho-dental-arch v-if="selectedJaw" :jaw="selectedJaw" @tooth-selected="onToothSelected"
                :dental-map="dentalMap"></ortho-dental-arch>
            <v-divider v-if="selectedJaw" class="mt-4"></v-divider>
            <ortho-3d-item-list @object-click="onObjectClick"></ortho-3d-item-list>
            <v-color-picker @click="handleColorChange" v-model="color" width="100%"></v-color-picker>
        </div>
        <div v-if="mode === 'alignPlan'">
            <h3 class="mb-2">{{ $t('workArea') }}</h3>

            <div class="buttons-container mt-2">
                <!-- Botões de ação -->
                <v-btn prepend-icon="mdi-tooth" @click="emitFilterMeshes('base')" size="small" class="btn-nav"
                    stacked>
                    {{ $t('toothDetails.baseModel') }}
                </v-btn>
                <v-btn prepend-icon="mdi-tooth-outline" @click="emitFilterMeshes(['segmented', 'tooth', 'gums'])"
                    size="small" class="btn-nav" stacked>
                    {{ $t('toothDetails.segmented') }}
                </v-btn>
                <v-btn prepend-icon="mdi-eye" @click="emitFilterMeshes('')" size="small" class="btn-nav"
                    stacked>
                    {{ $t('toothDetails.showAll') }}
                </v-btn>
            </div>

            <ortho-dental-arch v-if="selectedJaw" :jaw="selectedJaw" @tooth-selected="onToothSelected"
                :dental-map="dentalMap"></ortho-dental-arch>
            <v-divider v-if="selectedJaw" class="mt-4"></v-divider>
            <div id="vision2D"></div>
        </div>
    </v-navigation-drawer>
</template>


<script>

import Ortho3dItemList
    from './Ortho3dItemList.vue';
import OrthoDentalArch from './OrthoDentalArch.vue';
export default {
    name: "ortho-3d-side-bar",
    components: {
        Ortho3dItemList,
        OrthoDentalArch
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        mode: {
            type: String,
            default: ''
        },
        dentalMap: {
            type: Object
        }
    },
    data: () => ({
        drawer: true,
        lastAction: null,
        selectedJaw: null, // 'upper', 'lower', ou null
        color: "#7393B3",
        archDirection: {
            1: 'left',
            2: 'all',
            3: 'right'
        }
    }),
    methods: {
        handleClick(action) {
            this.lastAction = action;
            this.$emit('action', action);
        },
        setMode(newMode) {
            this.$emit('mode', newMode);
        },
        handleFileClick(action) {
            // Atualiza lastAction antes de abrir a seleção de arquivo
            this.lastAction = action;
            // Aciona o input de arquivo
            this.$refs.fileInput.click();
        },
        emitFilterMeshes(categories) {
            // Emite um evento 'filter-meshes' para o componente pai
            this.$emit('filter-meshes', categories);
        },
        handleColorChange() {
            // A cor já está sendo atualizada através do v-model, mas você pode emitir um evento adicional se necessário
            this.$emit('colorChanged', this.color);
            console.log(this.color)
        },
        handleFileLoad(event) {
            // Quando um arquivo é selecionado, obtém o arquivo do evento
            const file = event.target.files[0];
            if (file) {
                // Emite o evento com o arquivo como payload
                this.$emit('file-selected', { file, action: this.lastAction });
            }
            // Limpa o input para garantir que o mesmo arquivo possa ser selecionado novamente se necessário
            event.target.value = '';
        },
        onObjectClick(object) {
            if (object.userData.modelType === 'maxillary') {
                this.selectedJaw = 'upper';
            } else if (object.userData.modelType === 'mandibular') {
                this.selectedJaw = 'lower';
            } else {
                this.selectedJaw = null;
            }
            this.$emit('object-clicked-in-list', object);
        },
        onToothSelected(tooth) {
            this.$emit('tooth-clicked-in-list', tooth);
        }
    }
}
</script>

<style scoped>
#vision2D {
    width: 100%;
    /* Largura total do contêiner pai */
    overflow: hidden;
    /* Para garantir que o canvas não saia dos limites */
    position: relative;
    margin-top: 1vh;
}

#vision2D canvas {
    width: 100%;
    /* Redimensiona a largura do canvas para caber na div */
    height: 100%;
    /* Redimensiona a altura do canvas para caber na div */
    position: absolute;
    /* Permite o ajuste total dentro da div */
    top: 0;
    left: 0;
}

.side-nav {
    display: flex;
    flex-direction: column;
    /* Garante que o layout interno seja em coluna */
    align-items: center;
    padding: 4px;
    height: calc(100% - 80px) !important;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* Permite que os botões quebrem a linha quando não couberem */
    gap: 2px;
    /* Espaço entre os botões */
}

.btn-nav {
    width: 80px;
    /* Largura fixa para todos os botões */
    height: 50px;
    /* Altura fixa para todos os botões */
}

.btn-nav-color {
    background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-surface));
}

.jaw {
    width: 100%;
    /* Define a largura da imagem para seguir a largura do contêiner */
    height: auto;
    /* Ajusta automaticamente a altura para manter as proporções */
    object-fit: contain;
    /* Garante que toda a imagem seja visível */
    display: block;
    /* Remove qualquer espaço extra abaixo da imagem */
}
</style>