<template>
  <div class="language-selector">
    <v-img :src="flagBR" alt="Português" @click="setLanguage('pt-BR')" class="flag"></v-img>
    <v-img :src="flagIT" alt="Italiano" @click="setLanguage('it')" class="flag"></v-img>
    <v-img :src="flagEN" alt="English" @click="setLanguage('en')" class="flag"></v-img>
  </div>
</template>

<script>
import flagBR from '@/assets/flags/pt-BR.png';
import flagIT from '@/assets/flags/it.png';
import flagEN from '@/assets/flags/en.png';

export default {
  name: 'ortho-languges',
  data() {
    return {
      flagBR,
      flagIT,
      flagEN
    };
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('currentLanguage', lang);
    }
  }
};
</script>

<style scoped>
.language-selector {
  display: flex;
  justify-content: flex-end;
  margin-left: 0.5rem;
  min-height: 32px;
}

.flag {
  cursor: pointer;
  margin-left: 2px;
  /* Espaçamento entre as bandeiras */
  width: 32px;
  /* Você pode precisar ajustar isso se estiver usando v-img */
  height: auto;
  /* Mantém a proporção da imagem */
  filter: grayscale(100%);
  opacity: 0.25;
  /* Reduz a opacidade da imagem */
  transition: all 0.3s ease;
  /* Adiciona uma transição suave para hover */
}

.flag:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.v-responsive {
  flex: 0 0 auto; /* Isto sobrescreve o flex padrão e evita que a imagem estique */
  width: 32px; /* Largura da bandeira */
  height: 32px; /* Altura da bandeira */
}
</style>
