class Box {
    constructor(x, y, z, halfSize) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.halfSize = halfSize;
    }

    contains(point) {
        return (
            point.x >= this.x - this.halfSize && point.x <= this.x + this.halfSize &&
            point.y >= this.y - this.halfSize && point.y <= this.y + this.halfSize &&
            point.z >= this.z - this.halfSize && point.z <= this.z + this.halfSize
        );
    }

    intersects(range) {
        // eslint-disable-next-line no-prototype-builtins
        if (!range || !range.hasOwnProperty('x') || !range.hasOwnProperty('y') || !range.hasOwnProperty('z') || !range.hasOwnProperty('halfSize')) {
            return false;
        }

        return !(
            range.x - range.halfSize > this.x + this.halfSize ||
            range.x + range.halfSize < this.x - this.halfSize ||
            range.y - range.halfSize > this.y + this.halfSize ||
            range.y + range.halfSize < this.y - this.halfSize ||
            range.z - range.halfSize > this.z + this.halfSize ||
            range.z + range.halfSize < this.z - this.halfSize
        );
    }
}


class Octree {
    constructor(boundary, capacity) {
        this.boundary = boundary;
        this.capacity = capacity;
        this.objects = [];
        this.divided = false;
    }

    subdivide() {
        const { x, y, z, halfSize } = this.boundary;
        const newHalfSize = halfSize / 2;

        this.children = [
            new Octree(new Box(x - newHalfSize, y - newHalfSize, z - newHalfSize, newHalfSize), this.capacity),
            new Octree(new Box(x + newHalfSize, y - newHalfSize, z - newHalfSize, newHalfSize), this.capacity),
            new Octree(new Box(x - newHalfSize, y + newHalfSize, z - newHalfSize, newHalfSize), this.capacity),
            new Octree(new Box(x + newHalfSize, y + newHalfSize, z - newHalfSize, newHalfSize), this.capacity),
            new Octree(new Box(x - newHalfSize, y - newHalfSize, z + newHalfSize, newHalfSize), this.capacity),
            new Octree(new Box(x + newHalfSize, y - newHalfSize, z + newHalfSize, newHalfSize), this.capacity),
            new Octree(new Box(x - newHalfSize, y + newHalfSize, z + newHalfSize, newHalfSize), this.capacity),
            new Octree(new Box(x + newHalfSize, y + newHalfSize, z + newHalfSize, newHalfSize), this.capacity)
        ];

        this.divided = true;
    }

    insert(object) {
        if (!this.boundary.contains(object.position)) {
            return false;
        }

        if (this.objects.length < this.capacity) {
            this.objects.push(object);
            return true;
        } else {
            if (!this.divided) {
                this.subdivide();
            }

            for (let child of this.children) {
                if (child.insert(object)) {
                    return true;
                }
            }
        }

        return false;
    }

    query(range, found = []) {
        if (!this.boundary.intersects(range)) {
            return found;
        }

        for (let p of this.objects) {
            if (range.intersects(p.boundary)) {
                found.push(p);
            }
        }

        if (this.divided) {
            for (let child of this.children) {
                child.query(range, found);
            }
        }

        return found;
    }
}

function computeBoundingBox(vertices) {
    let minX = Infinity, minY = Infinity, minZ = Infinity;
    let maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity;

    vertices.forEach(vertex => {
        if (vertex.x < minX) minX = vertex.x;
        if (vertex.y < minY) minY = vertex.y;
        if (vertex.z < minZ) minZ = vertex.z;
        if (vertex.x > maxX) maxX = vertex.x;
        if (vertex.y > maxY) maxY = vertex.y;
        if (vertex.z > maxZ) maxZ = vertex.z;
    });

    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;
    const centerZ = (minZ + maxZ) / 2;
    const halfSize = Math.max(maxX - minX, maxY - minY, maxZ - minZ) / 2;

    return new Box(centerX, centerY, centerZ, halfSize);
}

function distance(a, b) {
    return Math.sqrt(
        (a.x - b.x) ** 2 +
        (a.y - b.y) ** 2 +
        (a.z - b.z) ** 2
    );
}

function detectCollisions(teeth, octree) {
    const collisions = [];

    teeth.forEach(tooth => {
        const queryRange = tooth.boundary;
        const nearbyTeeth = octree.query(queryRange);

        nearbyTeeth.forEach(otherTooth => {
            if (otherTooth !== tooth) {
                tooth.vertices.forEach(vertex => {
                    otherTooth.vertices.forEach(otherVertex => {
                        if (distance(vertex, otherVertex) < 0.1) {
                            collisions.push({ tooth, otherTooth });
                        }
                    });
                });
            }
        });
    });

    return collisions;
}

export { Box, Octree, computeBoundingBox, detectCollisions };
