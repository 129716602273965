<template>
    <v-dialog v-model="isVisible" persistent max-width="500px">
        <v-card>
            <v-card-title>
                Processando
            </v-card-title>
            <v-card-text>
                <v-col v-for="(task, index) in taskQueue" :key="index" cols="12" class="mb-4">
                    <div>{{ task.step }}</div>
                    <v-col cols="12">
                    <v-progress-linear color="deep-orange-accent-4" height="6" indeterminate
                        rounded></v-progress-linear>
                </v-col>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="cancelProcess">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ortho-progress-bar',
    data() {
        return {
            isVisible: false,
            taskQueue: [], // Fila de tarefas
            currentTaskIndex: 0, // Índice da tarefa atual
        };
    },
    methods: {
        showProgressDialog(step, percent) {
            const newTask = { step, percent };
            const taskIndex = this.taskQueue.length; // Índice da nova tarefa

            // Adiciona a nova tarefa à fila
            this.taskQueue.push(newTask);

            // Se o componente não estiver visível, ativa a primeira tarefa
            if (!this.isVisible) {
                this.activateTask(taskIndex);
            }

            return taskIndex; // Retorna o índice da tarefa
        },
        updateProgress(step, percent) {
            if (this.taskQueue[this.currentTaskIndex]) {
                this.taskQueue[this.currentTaskIndex].step = step;
                this.taskQueue[this.currentTaskIndex].percent = percent;
            }
        },
        activateTask(taskIndex) {
            if (this.taskQueue[taskIndex]) {
                this.currentTaskIndex = taskIndex;
                this.isVisible = true;
            }
        },
        hideProgressDialog() {
            this.isVisible = false;
            this.taskQueue = []; // Limpa a fila ao esconder o diálogo
        },
        cancelProcess(taskIndex = this.currentTaskIndex) {
            if (this.taskQueue[taskIndex]) {
                // Remove a tarefa da fila
                this.taskQueue.splice(taskIndex, 1);

                // Atualiza o índice atual se necessário
                if (taskIndex <= this.currentTaskIndex && this.currentTaskIndex > 0) {
                    this.currentTaskIndex--;
                }

                // Se ainda houver tarefas, ativa a próxima
                if (this.taskQueue.length > 0) {
                    this.activateTask(this.currentTaskIndex);
                } else {
                    this.hideProgressDialog();
                }
            }
        },
    },
};
</script>
