<template>
    <div class="dental-arch-container">
        <h3>{{ jaw === 'upper' ? $t('maxillaryModel') : $t('mandibularModel') }}</h3>
        <img :src="jawImage" @click="handleClick" class="dental-arch-image">
        <div v-if="clickedTooth" class="tooth-number">{{ clickedTooth }}</div>
    </div>
    <ortho-tooth-detail :toothData="selectedToothData" />
</template>

<script>
import OrthoToothDetail from './OrthoToothDetail.vue';
export default {
    emits: ['tooth-selected'],
    name: 'ortho-dental-arch',
    components: {
        OrthoToothDetail
    },
    props: {
        jaw: {
            type: String,
            default: 'upper' // ou 'lower'
        },
        dentalMap: {
            type: Object
        }
    },
    data() {
        return {
            clickedTooth: null,
            teethData: this.initializeTeethData(),
            selectedToothData: null,
            // Coordenadas para a arcada superior
            upperTeethCoordinates: {
                '11': { xPercent: 44, yPercent: 9, rPercent: 5 },
                '12': { xPercent: 32, yPercent: 15, rPercent: 5 },
                '13': { xPercent: 26, yPercent: 22, rPercent: 5 },
                '14': { xPercent: 20, yPercent: 32, rPercent: 5 },
                '15': { xPercent: 17, yPercent: 44, rPercent: 5 },
                '16': { xPercent: 15, yPercent: 58, rPercent: 5 },
                '17': { xPercent: 14, yPercent: 74, rPercent: 5 },
                '18': { xPercent: 14, yPercent: 89, rPercent: 5 },
                '21': { xPercent: 57, yPercent: 11, rPercent: 5 },
                '22': { xPercent: 66, yPercent: 16, rPercent: 5 },
                '23': { xPercent: 73, yPercent: 23, rPercent: 5 },
                '24': { xPercent: 77, yPercent: 34, rPercent: 5 },
                '25': { xPercent: 81, yPercent: 44, rPercent: 5 },
                '26': { xPercent: 83, yPercent: 59, rPercent: 5 },
                '27': { xPercent: 86, yPercent: 75, rPercent: 5 },
                '28': { xPercent: 85, yPercent: 90, rPercent: 5 },
            },
            // Coordenadas para a arcada inferior
            lowerTeethCoordinates: {
                '31': { xPercent: 54, yPercent: 86, rPercent: 5 },
                '32': { xPercent: 61, yPercent: 84, rPercent: 5 },
                '33': { xPercent: 67, yPercent: 76, rPercent: 5 },
                '34': { xPercent: 73, yPercent: 66, rPercent: 5 },
                '35': { xPercent: 75, yPercent: 55, rPercent: 5 },
                '36': { xPercent: 80, yPercent: 41, rPercent: 5 },
                '37': { xPercent: 80, yPercent: 24, rPercent: 5 },
                '38': { xPercent: 84, yPercent: 8, rPercent: 5 },
                '41': { xPercent: 47, yPercent: 87, rPercent: 5 },
                '42': { xPercent: 38, yPercent: 83, rPercent: 5 },
                '43': { xPercent: 31, yPercent: 76, rPercent: 5 },
                '44': { xPercent: 26, yPercent: 66, rPercent: 5 },
                '45': { xPercent: 22, yPercent: 58, rPercent: 5 },
                '46': { xPercent: 21, yPercent: 42, rPercent: 5 },
                '47': { xPercent: 18, yPercent: 24, rPercent: 5 },
                '48': { xPercent: 16, yPercent: 8, rPercent: 5 },
            },
            upperJawImage: require('@/assets/upper_jaw.png'),
            lowerJawImage: require('@/assets/lower_jaw.png')
        };
    },
    computed: {
        jawImage() {
            return this.jaw === 'upper' ? this.upperJawImage : this.lowerJawImage;
        },
        teethCoordinates() {
            return this.jaw === 'upper' ? this.upperTeethCoordinates : this.lowerTeethCoordinates;
        },
    },
    methods: {
        updateTeethData() {
            if (this.dentalMap)
                this.teethData.forEach(tooth => {
                    const dentalInfo = this.dentalMap[tooth.toothNumber];
                    if (dentalInfo && dentalInfo.centerOfMass && dentalInfo.angles && dentalInfo.actualAngles) {
                        // Atualizar os dados com base em dentalMap
                        if (tooth.initialPosition.x === 0 && tooth.initialPosition.y === 0 && tooth.initialPosition.z === 0) {
                            tooth.initialPosition = {
                            x: dentalInfo.centerOfMass.x / 10, // Convertido de volta para mm
                            y: dentalInfo.centerOfMass.y / 10,
                            z: dentalInfo.centerOfMass.z / 10
                        }
                        }
                        tooth.currentPosition = {
                            x: dentalInfo.centerOfMass.x / 10, // Convertido de volta para mm
                            y: dentalInfo.centerOfMass.y / 10,
                            z: dentalInfo.centerOfMass.z / 10
                        };
                        tooth.initialRotation = {
                            x: dentalInfo.angles.x,
                            y: dentalInfo.angles.y,
                            z: dentalInfo.angles.z
                        }
                        tooth.currentRotation = {
                            x: dentalInfo.actualAngles.x,
                            y: dentalInfo.actualAngles.y,
                            z: dentalInfo.actualAngles.z
                        };
                        tooth.isAbsent = false; // Supondo que a presença de dados significa que o dente não está ausente
                    } else {
                        // Considerar ausente se não houver informações
                        tooth.isAbsent = true;
                    }
                });
        },
        initializeTeethData() {
            const teethNames = {
                1: this.$t('teethNames.centralIncisor'),
                2: this.$t('teethNames.lateralIncisor'),
                3: this.$t('teethNames.canine'),
                4: this.$t('teethNames.firstPremolar'),
                5: this.$t('teethNames.secondPremolar'),
                6: this.$t('teethNames.firstMolar'),
                7: this.$t('teethNames.secondMolar'),
                8: this.$t('teethNames.thirdMolarOrWisdomTooth'),
            };

            const teethData = [];
            // Faixas numéricas dos dentes
            const ranges = [11, 21, 31, 41];

            ranges.forEach(start => {
                for (let i = 0; i < 8; i++) {
                    const toothNumber = start + i;
                    teethData.push({
                        toothNumber,
                        name: `${teethNames[i + 1]}`,
                        initialPosition: { x: 0, y: 0, z: 0 },
                        currentPosition: { x: 0, y: 0, z: 0 },
                        initialRotation: { x: 0, y: 0, z: 0 },
                        currentRotation: { x: 0, y: 0, z: 0 },
                        isAbsent: false
                    });
                }
            });

            return teethData;
        },
        handleClick(event) {
            // Obter as dimensões e posição da imagem
            const rect = event.target.getBoundingClientRect();
            const width = rect.width;
            const height = rect.height;

            // Calcular a posição do clique como uma porcentagem da imagem
            const xPercent = ((event.clientX - rect.left) / width) * 100;
            const yPercent = ((event.clientY - rect.top) / height) * 100;

            // Encontrar o dente mais próximo clicado com base nas coordenadas percentuais
            this.clickedTooth = this.findClosestTooth(xPercent, yPercent);

            if (this.clickedTooth) {
                this.$emit('tooth-selected', this.clickedTooth);
                const clickedToothData = this.teethData.find(tooth => tooth.toothNumber == this.clickedTooth);
                if (clickedToothData) {
                    this.selectedToothData = clickedToothData;
                }
            }
        },
        findClosestTooth(xPercent, yPercent) {
            let closestTooth = null;
            let minDistance = Infinity;

            for (const [toothNumber, coordinates] of Object.entries(this.teethCoordinates)) {
                // Convertendo as coordenadas de porcentagem dos dentes em relação à imagem
                const toothX = coordinates.xPercent;
                const toothY = coordinates.yPercent;
                const toothR = coordinates.rPercent;

                const distance = this.calculateDistancePercent(xPercent, yPercent, toothX, toothY);

                if (distance < toothR && distance < minDistance) {
                    closestTooth = toothNumber;
                    minDistance = distance;
                }
            }

            return closestTooth;
        },
        calculateDistancePercent(x1, y1, x2, y2) {
            return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
        }
    },
    watch: {
        jaw(newVal, oldVal) {
            // Se a arcada mudar, resetar o dente selecionado
            if (newVal !== oldVal) {
                this.clickedTooth = null;
            }
        },
        dentalMap: {
            deep: true,
            immediate: true,
            handler() {
                console.warn('Alterado dentalMap')
                this.updateTeethData();
            }
        }
    }

};
</script>

<style scoped>
.dental-arch-container {
    position: relative;
    display: inline-block;
    /* Ou block, dependendo de como você deseja exibir a imagem */
}

.dental-arch-image {
    display: block;
    /* Isso remove o espaço extra abaixo da imagem */
    max-width: 100%;
    height: auto;
    cursor: pointer;
}

.tooth-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Isso centraliza o número sobre a imagem */
    background: rgba(255, 255, 255, 0.5);
    /* Fundo semi-transparente para melhor visibilidade */
    padding: 5px;
    /* Ou o suficiente para visualização adequada */
    border-radius: 10px;
    /* Estilização opcional */
    pointer-events: none;
    /* Isso permite que os cliques na caixa do número passem para a imagem abaixo */
    font-weight: bold;
    /* Torna o texto do número mais visível */
    color: #000;
    /* Cor do texto */
    font-size: 1.5em;
    /* Tamanho do texto */
}
</style>