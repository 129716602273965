import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import store from "./store";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { md3 } from "vuetify/blueprints";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

import "./assets/global.css";

import { messages_en } from "./locales/en/messages_en";
import { messages_it } from "./locales/it/messages_it";
import { messages_pt_BR } from "./locales/pt-BR/messages_pt-BR";

const messages = {
  en : messages_en,
  it : messages_it,
  "pt-BR" : messages_pt_BR
};

const OrthoLightTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    "surface-bright": "#f8f8ff",
    "surface-light": "#EEEEEE",
    "surface-variant": "#424242",
    "on-surface-variant": "#EEEEEE",
    primary: "#1867C0",
    "primary-darken-1": "#1F5592",
    secondary: "#48A9A6",
    "secondary-darken-1": "#018786",
    highlight: "#FFA726",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    "text-light": "#FFFFFF",
  },
  variables: {
    "border-color": "#000000",
    "border-opacity": 0.12,
    "high-emphasis-opacity": 0.87,
    "medium-emphasis-opacity": 0.6,
    "disabled-opacity": 0.38,
    "idle-opacity": 0.04,
    "hover-opacity": 0.04,
    "focus-opacity": 0.12,
    "selected-opacity": 0.08,
    "activated-opacity": 0.12,
    "pressed-opacity": 0.12,
    "dragged-opacity": 0.08,
    "theme-kbd": "#212529",
    "theme-on-kbd": "#FFFFFF",
    "theme-code": "#F5F5F5",
    "theme-on-code": "#000000",
  },
};

const i18n = createI18n({
  locale: localStorage.getItem("currentLanguage") || "pt-BR",
  fallbackLocale: "en",
  messages,
});

const vuetify = createVuetify({
  components,
  directives,
  md3,
  theme: {
    defaultTheme: "OrthoLightTheme",
    themes: {
      OrthoLightTheme,
    },
  },
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(i18n);
app.use(vuetify);
app.mount("#app");
