<template>
  <div v-if="toothData" class="tooth-details">
    <h3>
      <v-icon small>mdi-tooth</v-icon>
      {{ $t('toothDetails.tooth') }} {{ toothData.toothNumber }}<br>
      {{ toothData.name }}
    </h3>
    <p>
      <v-icon small>{{ toothData.isAbsent ? 'mdi-close-circle' : 'mdi-check-circle' }}</v-icon>
      {{ $t('toothDetails.status') }}: {{ toothData.isAbsent ? $t('toothDetails.absent') : $t('toothDetails.present') }}
    </p>

    <div v-if="!toothData.isAbsent">
      <div class="buttons-container">
        <!-- Botões de ação -->
        <v-btn prepend-icon="mdi-swap-horizontal" @click="editToothNumber" size="small" class="btn-nav" stacked>
          {{ $t('toothDetails.changeToothNumber') }}
        </v-btn>
        <v-btn prepend-icon="mdi-delete-empty" @click="confirmDelete" size="small" class="btn-nav" stacked>
          {{ $t('toothDetails.deleteToothData') }}
        </v-btn>
        <v-btn prepend-icon="mdi-content-save-move" @click="updateToothData" size="small" class="btn-nav" stacked>
          {{ $t('toothDetails.updateData') }}
        </v-btn>
      </div>

      <div class="data-section">
        <div class="data-header">
          <v-icon small>mdi-axis-arrow-lock</v-icon>
          <span>{{ $t('toothDetails.initialPosition') }} (mm)</span>
        </div>
        <div class="data-values">
          <div class="data-value"><v-chip>X</v-chip> <span>{{ toothData.initialPosition.x.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Y</v-chip> <span>{{ toothData.initialPosition.y.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Z</v-chip> <span>{{ toothData.initialPosition.z.toFixed(2) }}</span></div>
        </div>
      </div>
      <div class="data-section">
        <div class="data-header">
          <v-icon small>mdi-axis-arrow</v-icon>
          <span>{{ $t('toothDetails.currentPosition') }} (mm)</span>
        </div>
        <div class="data-values">
          <div class="data-value"><v-chip>X</v-chip> <span>{{ toothData.currentPosition.x.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Y</v-chip> <span>{{ toothData.currentPosition.y.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Z</v-chip> <span>{{ toothData.currentPosition.z.toFixed(2) }}</span></div>
        </div>
      </div>
      <div class="data-section">
        <div class="data-header">
          <v-icon small>mdi-rotate-3d</v-icon>
          <span>{{ $t('toothDetails.initialRotation') }} (º)</span>
        </div>
        <div class="data-values">
          <div class="data-value"><v-chip>X</v-chip> <span>{{ toothData.initialRotation.x.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Y</v-chip> <span>{{ toothData.initialRotation.y.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Z</v-chip> <span>{{ toothData.initialRotation.z.toFixed(2) }}</span></div>
        </div>
      </div>
      <div class="data-section">
        <div class="data-header">
          <v-icon small>mdi-rotate-3d</v-icon>
          <span>{{ $t('toothDetails.currentRotation') }} (º)</span>
        </div>
        <div class="data-values">
          <div class="data-value"><v-chip>X</v-chip> <span>{{ toothData.currentRotation.x.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Y</v-chip> <span>{{ toothData.currentRotation.y.toFixed(2) }}</span></div>
          <div class="data-value"><v-chip>Z</v-chip> <span>{{ toothData.currentRotation.z.toFixed(2) }}</span></div>
        </div>
      </div>
      <!-- Restante do código original -->

      <!-- Diálogo de seleção de número de dente -->
      <v-dialog v-model="showToothNumberChangeDialog" max-width="600">
        <v-card>
          <v-card-title>{{ $t('toothDetails.changeToothNumber') }}</v-card-title>
          <v-card-text>
            <div class="d-flex flex-wrap justify-center">
              <v-btn prepend-icon="mdi-tooth-outline" v-for="number in toothNumbers" :key="number"
                @click="updateToothNumber(number)" :disabled="number === toothData.toothNumber" class="ma-1" stacked>
                {{ number }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>


      <!-- Confirmação de exclusão -->
      <v-dialog v-model="showDeleteDialog" max-width="300">
        <v-card>
          <v-card-title>{{ $t('toothDetails.confirmDelete') }}</v-card-title>
          <v-card-text>{{ $t('toothDetails.deleteConfirmation') }}</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" @click="deleteToothData">{{ $t('toothDetails.confirm') }}</v-btn>
            <v-btn color="red darken-1" @click="showDeleteDialog = false">{{ $t('toothDetails.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Barra de progresso para atualização -->
      <v-dialog v-model="showUpdateDialog" max-width="400">
        <v-card>
          <v-card-title>{{ $t('toothDetails.updating') }}</v-card-title>
          <v-card-text>
            <v-progress-linear indeterminate color="blue"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: ['toothData'],
  data() {
    return {
      showToothNumberChangeDialog: false,
      showDeleteDialog: false,
      showUpdateDialog: false,
      toothNumbers: []
    };
  },
  watch: {
    toothData: {
      immediate: true,
      handler() {
        this.updateToothNumbers();
      }
    }
  },
  methods: {
    editToothNumber() {
      this.showToothNumberChangeDialog = true;
    },
    updateToothNumber(newNumber) {
      this.$emit('update-tooth-number', {
        oldNumber: this.toothData.toothNumber,
        newNumber,
      });
      this.showToothNumberChangeDialog = false;
    },
    confirmDelete() {
      this.showDeleteDialog = true;
    },
    deleteToothData() {
      // Lógica para excluir os dados do dente
      this.showDeleteDialog = false;
      console.log("Dados do dente excluídos");
    },
    updateToothData() {
      this.showUpdateDialog = true;
      // Lógica para atualizar os dados no banco de dados
      setTimeout(() => {
        this.showUpdateDialog = false;
        console.log("Dados atualizados no banco de dados");
      }, 2000); // Simulação de tempo de atualização
    },
    updateToothNumbers() {

      if (!this.toothData || !this.toothData.toothNumber) return;
      const toothNumber = this.toothData.toothNumber;

      if (toothNumber >= 11 && toothNumber <= 18 || toothNumber >= 21 && toothNumber <= 28) {
        // Arcada superior (direita e esquerda)
        this.toothNumbers = [
          ...Array.from({ length: 8 }, (_, i) => 11 + i),  // 11-18
          ...Array.from({ length: 8 }, (_, i) => 21 + i)   // 21-28
        ];
      } else if (toothNumber >= 31 && toothNumber <= 38 || toothNumber >= 41 && toothNumber <= 48) {
        // Arcada inferior (esquerda e direita)
        this.toothNumbers = [
          ...Array.from({ length: 8 }, (_, i) => 31 + i),  // 31-38
          ...Array.from({ length: 8 }, (_, i) => 41 + i)   // 41-48
        ];
      }
    }

  }
};
</script>

<style scoped>
.tooth-details {
  /* Ajuste os estilos conforme necessário */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.position-info,
.rotation-info {
  margin-top: 10px;
}

.v-icon {
  margin-right: 5px;
}

data-section {
  margin-top: 10px;
}

.data-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 4px;
  background-color: rgb(var(--v-theme-surface-light));
}

.data-header>.v-icon {
  margin-right: 5px;
}

.data-values {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.data-value {
  display: flex;
  align-items: baseline;
}

.data-value>v-chip:first-child {
  font-weight: bold;
  margin-right: 5px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
  /* Permite que os botões quebrem a linha quando não couberem */
  gap: 2px;
  /* Espaço entre os botões */
}

.btn-nav {
  width: 70px;
  /* Largura fixa para todos os botões */
  height: 50px;
  /* Altura fixa para todos os botões */
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-surface));
}
</style>