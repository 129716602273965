export const messages_pt_BR = {
  login: {
    title: "Entrar",
    username: "Usuário",
    usernamePlaceholder: "Digite seu usuário",
    password: "Senha",
    passwordPlaceholder: "Digite sua senha",
    submitButton: "Entrar",
    unauthorizedAccess: "Você precisa estar logado para acessar essa página.",
    wrongPassword: "Usuário ou senha incorreto, por favor verifique.",
    usernameRequired: "O campo de usuário é obrigatório.",
    passwordRequired: "O campo de senha é obrigatório.",
  },
  teethNames: {
    centralIncisor: "Incisivo Central",
    lateralIncisor: "Incisivo Lateral",
    canine: "Canino",
    firstPremolar: "Primeiro Pré-Molar",
    secondPremolar: "Segundo Pré-Molar",
    firstMolar: "Primeiro Molar",
    secondMolar: "Segundo Molar",
    thirdMolarOrWisdomTooth: "Terceiro Molar ou Siso",
  },
  toothDetails: {
      "tooth": "Dente",
      "status": "Status",
      "absent": "Ausente",
      "present": "Presente",
      "initialPosition": "Posição Inicial",
      "currentPosition": "Posição Atual",
      "initialRotation": "Rotação Inicial",
      "currentRotation": "Rotação Atual",
      "changeToothNumber": "Alterar Número",
      "deleteToothData": "Excluir Dados",
      "updateData": "Atualizar Dados",
      "confirmDelete": "Confirmar Exclusão",
      "deleteConfirmation": "Tem certeza de que deseja excluir os dados do dente?",
      "confirm": "Confirmar",
      "cancel": "Cancelar",
      "updating": "Atualizando Dados",
      "baseModel" : "Modelo Base",
      "segmented" : "Segmentado",
      "showAll" : "Mostrar Tudo"
    },
  bothArchs : "Ambas Arcadas",
  workArea: "Área de Trabalho",
  dashboard: "Dashboard",
  newPatient: "Novo Paciente",
  logout: "Sair",
  savePatient: "Salvar Paciente",
  exportModel: "Exportar Modelo",
  adjustmentRequests: "Solicitações de Ajuste",
  addNewPatients: "Adicione novos pacientes ao sistema.",
  export3DModels: "Exporte modelos 3D para impressão.",
  export: "Exportar",
  patientData: "Dados do Paciente",
  modelData: "Dados dos Modelos",
  loadModel: "Carregar Modelos",
  close: "Fechar",
  patientId: "ID do Paciente",
  patientExternalId: "ID Externo do Paciente",
  ssn: "CPF",
  firstName: "Nome",
  lastName: "Sobrenome",
  address: "Endereço",
  city: "Cidade",
  country: "País",
  clinic: "Clínica",
  attachModel: "Anexar Modelo",
  modelSetId: "ID do Conjunto de Modelos",
  modelSetExternalId: "ID Externo do Conjunto de Modelos",
  comment: "Comentário",
  operator: "Operador",
  modelSetDate: "Data do Conjunto de Modelos",
  scanDate: "Data do Escaneamento",
  scanTime: "Hora do Escaneamento",
  maxillaryModel: "Modelo Maxilar",
  maxillaryModelFilename: "Nome do Arquivo do Modelo Maxilar",
  mandibularModel: "Modelo Mandibular",
  mandibularModelFilename: "Nome do Arquivo do Modelo Mandibular",
  modelInformation: "Informações do Modelo",
  polygons: "Polígonos",
  vertices: "Vértices",
  statistics: "Estatísticas",
  appointmentStats: "Estatísticas de Agendamentos",
  appointments: "Agendamentos",
  latestPatients: "Últimos Pacientes",
  patientsToday: "Pacientes Hoje",
  totalPatients: "Total de Pacientes",
  requests: "Requisições",
  adjustments: "Ajustes",
  managePatients: "Gerenciar Pacientes",
  patientHistory: "Histórico de Pacientes",
  addPartners: "Adicionar Parceiros",
  managePartners: "Gerenciar Parceiros",
  partnerDetails: "Detalhes dos Parceiros",
  settings: "Configurações",
  editor: "Editor 3D",
  userProfile: "Perfil do Usuário",
  ambientLight: "Luz Ambiente",
  directionalLight: "Luz Direcional",
  boxHelper: "Caixa Auxiliar",
  objects: "Objetos",
  creatingOcclusionPlan: "Criar Plano de Oclusão",
  functions: "Funções",
  occlusionPlan: "Plano de Oclusão",
  cutModel: "Cortar Modelo",
  orientateTooth: "Orientar Dente",
  extrusion: "Extrusão",
  measureDistance: "Medir Distância",
  dentalArch: "Arco Dentário",
  instructions: "Instruções",
  planeInstructions:
    "Clique em três pontos no modelo para definir o plano de oclusão.",
  maxillary: "Maxilar",
  mandibular: "Mandibola",
  transformControl: "Controle de Transformação",
  cuttingPlane: "Plano de Corte",
  markingContour: "Marcando Contorno",
  smoothBorder: "Suavizar Borda",
  smoothingEdges: "Suavizando Bordas",
  saveActiveObject: "Salvar Objeto",
  extractToothBorder: "Borda Dente",
  loadSegmentation: "Importar Dentes",
  saveSegmentation: "Exportar Dentes",
  createDentalMesh: "Criar Modelo",
  toggleVisibility: "Altera Visibilidade do objeto selecionado",
  assignMotionController:
    "Atribui controlador de movimentos ao objeto selecionado",
  showHideUpperJaw: "Exibe ou Oculta Arcada Superior",
  showHideLowerJaw: "Exibe ou Oculta Arcada Inferior",
  activateTranslation: "Ativa Transformação Translação",
  activateRotation: "Ativa Transformação Rotação",
  turnOffAmbientLight: "Desliga Luz Ambiente",
  turnOffDirectionalLight1: "Desliga Luz Direcional 1",
  turnOffDirectionalLight2: "Desliga Luz Direcional 2",
  applyCutAbovePlane: "Aplica corte acima do plano de corte",
  applyCutBelowPlane: "Aplica corte abaixo do plano de corte",
  activateContourMarking: "Ativa Marcação de Contorno",
  clearContours: "Limpar Contornos",
  frontView: "Visão Frontal",
  leftView: "Visão Esquerda",
  topView: "Visão Topo",
  toggleGumsVisibility: "Alterna Visibilidade da Gengiva",
  toggleTeethVisibility: "Alterna Visibilidade dos Dentes",
  clearTasks: "Remover Todas as Tarefas do Buffer",
  dynamicGum : "Gengiva Dinâmica",
  idealDentalArch: "Arco Ideal",
  creatingDentalArch : "Criar Arco Dental",
  alignPlan: "Plano de Alinhamento",
  definingMedialDistalPoints : "Configuração Medial e Distal",
  applyChanges :  "Aplicar Transformação ao Modelo",
  noArchSelected: "Nenhuma Arcada Selecionada",
  archMoveType: "Move Arco",
  fullArch: "Arco Completo",
  ends: "Extremidades do Arco",
  midPoints: "Pontos Médios do Arco",
  centerPoint: "Ponto Central do Arco",
  endsLeft: "Extremidades do Arco Esquerda",
  midPointsLeft : "Pontos Médios Esquerdo do Arco",
  endsRight: "Extremidades do Arco Direita",
  midPointsRight : "Pontos Médios Direito do Arco",
};
