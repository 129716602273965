import { createStore } from 'vuex';
import editor3D from '@/store/modules/editor3d';

export default createStore({
  modules: {
    editor3D, // Adiciona o módulo editor3D
  },
  state() {
    return {
      isLoggedIn: false
    };
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    setRedirectMessage(state, messageKey) {
      state.redirectMessage = messageKey;
    }  
  }
});
