<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div :key="componentKey">
    <v-list v-if="scene" density="compact">
      <h3>{{ $t('objects') }}</h3>

      <v-list-item v-for="(child, index) in filteredChildren" :key="index"
        @click="handleItemClick(child)">
        <template v-slot:prepend>
          <v-icon icon="mdi-shape"></v-icon>
        </template>

        <v-list-item-title v-text="child.userData.name || 'Objeto sem nome'"></v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>
  
<script>

export default {
  name: 'ortho-3d-item-list',
  data() {
    return {
      componentKey: 0,
      scene : null
    }
  },
  computed: {
    // Filtra os filhos da cena para excluir os que têm a propriedade hide como true
    filteredChildren() { 
      if (!this.scene || !this.scene.children) {
        return [];
      }
      return this.scene.children;
    }
  },
  methods: {
    handleItemClick(object) {
      this.$emit('object-click', object);
    },
    handleSceneUpdate() {
      // Atualiza algo no componente ou realiza outras ações necessárias
      this.scene = this.$store.state.editor3D.scene;
      this.componentKey++;
    }

  },
  created() {
    this.scene = this.$store.state.editor3D.scene;
    this.$store.watch(
      (state) => state.editor3D.update, // função que retorna a parte do estado que estamos observando
      () => {
        this.handleSceneUpdate();
      },
      {
        deep: true // Para observar mudanças internas
      }
    );
  },

};
</script>
  
<style scoped>
/* Estilos personalizados aqui, se necessário */
</style>
  