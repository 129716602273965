<template>
    <!-- Estatísticas do Dashboard com ícones e cor de fundo -->
    <v-row class="rounded-0">
        <!-- Pacientes Hoje -->
        <v-col cols="12" md="3">
            <v-hover v-slot="{ isHovering, props }">
                <v-card outlined class="rounded-0" v-bind="props" :elevation="isHovering ? 8 : 2">
                    <v-card-title class="bg-primary text-white">
                        <v-icon left>mdi-hospital-building</v-icon>
                        {{ $t("patientsToday") }}
                    </v-card-title>
                    <v-card-text class="text-h5 pa-5">8</v-card-text>
                </v-card>
            </v-hover>
        </v-col>
        <!-- Total de Pacientes -->

        <v-col cols="12" md="3">
            <v-hover v-slot="{ isHovering, props }">
                <v-card outlined class="rounded-0" v-bind="props" :elevation="isHovering ? 8 : 2">
                    <v-card-title class="bg-primary text-white">
                        <v-icon left>mdi-account-multiple</v-icon>
                        {{ $t("totalPatients") }}
                    </v-card-title>
                    <v-card-text class="text-h5 pa-5">364</v-card-text>
                </v-card>
            </v-hover>
        </v-col>
        <!-- Requisições -->
        <v-col cols="12" md="3">
            <v-card outlined class="rounded-0">
                <v-card-title class="bg-primary text-white">
                    <v-icon left>mdi-file-document</v-icon>
                    {{ $t("requests") }}
                </v-card-title>
                <v-card-text class="text-h5 pa-5">20</v-card-text>
            </v-card>
        </v-col>
        <!-- Ajustes -->
        <v-col cols="12" md="3">
            <v-card outlined class="rounded-0">
                <v-card-title class="bg-primary text-white">
                    <v-icon left>mdi-alert</v-icon>
                    {{ $t("adjustments") }}
                </v-card-title>
                <v-card-text class="text-h5 pa-5">12</v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <!-- Gráficos de Estatísticas de Agendamentos -->
    <v-row>
        <!-- Calendário de Agendamentos -->
        <v-col cols="12" md="4">
            <v-card outlined class="rounded-0">
                <v-card-title class="bg-primary text-white">{{ $t("appointments") }}</v-card-title>
                <v-card-text>
                    <v-date-picker></v-date-picker>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="8">
            <v-card outlined class="rounded-0">
                <v-card-title class="bg-primary text-white">{{ $t("latestPatients") }}</v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="item in patients" :key="item.id">
                            <v-list-item>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.treatment }}</v-list-item-subtitle>
                            </v-list-item>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card outlined class="rounded-0 mt-5">
                <!-- Substitua por um componente de gráfico real -->
                <v-card-title class="bg-primary text-white">{{ $t("appointmentStats") }}</v-card-title>
                <v-card-text class="mt-5">
                    <div style="height: 275px; background-color: #e9e9e9;"></div>
                    <!-- Placeholder para gráfico -->
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>

    <!-- Lista dos Últimos Pacientes -->
    <v-row>

    </v-row>
</template>
  
<script>

export default {
    name: 'OrthoDash',
    components: {
        // Seus componentes aqui...
    },
    data: () => ({
        patients: [
            { id: 96, name: 'Kiki Allman', treatment: 'Membro - Canal Radicular' },
            { id: 95, name: 'Corbin Oakley', treatment: 'Não Membro - Consulta' },
            { id: 94, name: 'Seren Lennon', treatment: 'Membro - Limpeza' }
            // ... Mais pacientes fictícios
        ],
    }),
};
</script>
  
<style scoped>
.v-card-title {
    font-size: 1rem;
}

.text-h5 {
    font-size: 1.5rem !important;
}
</style>
  