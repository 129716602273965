import * as THREE from 'three';

export default {
  methods: {
    getAdjacentTeeth(toothNumber) {
      // Identifica a arcada com base no número do dente
      let arc;
      if (toothNumber >= '11' && toothNumber <= '28') {
          // Arcada superior (considerando apenas dentes permanentes)
          arc = ['18', '17', '16', '15', '14', '13', '12', '11', '21', '22', '23', '24', '25', '26', '27', '28'];
      } else if (toothNumber >= '31' && toothNumber <= '48') {
          // Arcada inferior (considerando apenas dentes permanentes)
          arc = ['38', '37', '36', '35', '34', '33', '32', '31', '41', '42', '43', '44', '45', '46', '47', '48'];
      }

      if (!arc) {
          console.warn('Número do dente inválido ou fora do intervalo para análise.');
          return [];
      }

      // Encontra a posição do dente na arcada
      const index = arc.indexOf(toothNumber);

      // Retorna os números dos dentes anterior e posterior, se existirem
      return [
          arc[index - 1], // Dente anterior
          arc[index + 1]  // Dente posterior
      ].filter(Boolean); // Remove valores undefined (caso o dente esteja no início ou fim da arcada)
  },
  applyTransformToGeometry(geometry, transformMatrix) {
      // Cria uma cópia da geometria para evitar mutação da geometria original
      const transformedGeometry = geometry.clone();

      // Aplica a transformação a toda a geometria
      transformedGeometry.applyMatrix4(transformMatrix);

      // Retorna a nova geometria transformada
      return transformedGeometry;
  },
  // Função para encontrar o mesh do dente baseado no número dentro de um grupo
  findToothMesh(toothNumber) {
      let toothMesh = null;

      // Buscar por todos os grupos na cena
      this.scene.traverse((child) => {
          if (child.isGroup) {
              // Buscar pelo mesh do dente dentro dos filhos do grupo
              const potentialToothMesh = child.children.find(childMesh =>
                  childMesh.userData.objectType === 'tooth' &&
                  childMesh.userData.toothNumber === toothNumber
              );

              // Se encontrou, definir como o mesh do dente
              if (potentialToothMesh) {
                  toothMesh = potentialToothMesh;
              }
          }
      });

      return toothMesh;
  },
    /**
     * Gera e inicia o download de uma imagem de mapa de altura a partir da geometria de um objeto ativo.
     */
    handleDownload() {
      const heightMap = this.createHeightMapFromBufferGeometry(
        this.activeObject.geometry, // Geometria do objeto ativo
        256, // Resolução do mapa de altura
        this.activeObject.userData.modelType === 'maxillary' // Condicional para determinar se o modelo é maxilar superior
      );
      const dataUrl = this.generateHeightMapImage(heightMap); // Gera a URL da imagem a partir do mapa de altura
      this.downloadImage(dataUrl); // Inicia o download da imagem
    },

    /**
     * Cria um mapa de altura a partir de uma BufferGeometry.
     * @param {THREE.BufferGeometry} geometry - A geometria do objeto.
     * @param {number} resolution - Resolução do mapa de altura.
     * @param {boolean} isUpper - Flag para indicar se o mapa é para a parte superior.
     * @returns {Array<Array<number>>} Matriz representando o mapa de altura.
     */
    createHeightMapFromBufferGeometry(geometry, resolution, isUpper) {
      const positions = geometry.attributes.position.array; // Acessa o array de posições da geometria.
      const points = [];

      // Converte as posições em um array de objetos THREE.Vector3.
      for (let i = 0; i < positions.length; i += 3) {
        points.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
      }

      // Retorna o mapa de altura gerado.
      return this.createHeightMap(points, resolution, isUpper);
    },

    /**
     * Cria um mapa de altura a partir de uma lista de pontos.
     * @param {Array<THREE.Vector3>} points - Pontos do modelo 3D.
     * @param {number} resolution - Resolução do mapa de altura.
     * @param {boolean} isUpper - Se o mapa de altura é para a parte superior do modelo.
     * @returns {Array<Array<number>>} Matriz representando o mapa de altura.
     */
    createHeightMap(points, resolution, isUpper) {
      let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity, minZ = Infinity, maxZ = -Infinity;

      // Calcula os valores mínimos e máximos para os pontos.
      points.forEach(point => {
        minX = Math.min(minX, point.x);
        maxX = Math.max(maxX, point.x);
        minY = Math.min(minY, point.y);
        maxY = Math.max(maxY, point.y);
        minZ = Math.min(minZ, point.z);
        maxZ = Math.max(maxZ, point.z);
      });

      // Define o tamanho de cada célula do grid.
      const gridWidth = (maxX - minX) / resolution;
      const gridHeight = (maxZ - minZ) / resolution;

      // Cria uma matriz para armazenar os valores de altura.
      const heightMap = Array.from({ length: resolution }, () =>
        new Array(resolution).fill(0)
      );

      // Preenche a matriz de altura.
      points.forEach(point => {
        const xIndex = Math.floor((point.x - minX) / gridWidth);
        const zIndex = Math.floor((point.z - minZ) / gridHeight);
        let yIndex = isUpper
          ? 1 - (point.y - minY) / (maxY - minY)
          : (point.y - minY) / (maxY - minY);

        if (xIndex >= 0 && xIndex < resolution && zIndex >= 0 && zIndex < resolution) {
          heightMap[zIndex][xIndex] += yIndex;
        }
      });

      return heightMap;
    },

    /**
     * Gera uma imagem de mapa de altura em base64.
     * @param {Array<Array<number>>} heightMap - O mapa de altura.
     * @returns {string} Data URL da imagem gerada.
     */
    generateHeightMapImage(heightMap) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const height = heightMap.length;
      const width = heightMap[0].length;

      canvas.width = width;
      canvas.height = height;

      const imageData = ctx.createImageData(width, height);
      const data = imageData.data;

      // Encontra o valor máximo para normalização dos valores de altura.
      const max = Math.max(...heightMap.map(row => Math.max(...row)));

      // Preenche os dados da imagem.
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const value = heightMap[y][x];
          const normalize = value / max;
          const color = Math.floor(normalize * 512);

          const index = (y * width + x) * 4;
          data[index + 0] = color; // R
          data[index + 1] = color; // G
          data[index + 2] = color; // B
          data[index + 3] = 255; // A
        }
      }

      ctx.putImageData(imageData, 0, 0);
      return canvas.toDataURL('image/png');
    },

    /**
     * Inicia o download de uma imagem a partir de uma URL de dados.
     * @param {string} dataUrl - URL dos dados da imagem.
     * @param {string} filename - Nome do arquivo para o download.
     */
    downloadImage(dataUrl, filename = 'heightmap.png') {
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = filename; // Define o nome do arquivo para download.
      document.body.appendChild(link); // Adiciona o link ao corpo do documento para habilitar o download.
      link.click(); // Inicia o download clicando no link.
      document.body.removeChild(link); // Remove o link após o download.
    },
  },
};
