import * as THREE from "three";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import Delaunator from "delaunator";
//import Ammo from '@/class/ammo.js';

export default {
  methods: {
    cleanAndFilterPoints(points, threshold = 1e-14) {
      const filteredPoints = [];
      const uniquePoints = new Set();

      points.forEach((point) => {
        const key = `${point.x.toFixed(15)}_${point.y.toFixed(
          15
        )}_${point.z.toFixed(15)}`;
        // if (!uniquePoints.has(key)) {
        uniquePoints.add(key);
        filteredPoints.push(point);
        //}
      });

      // Remover pontos muito próximos
      const cleanedPoints = [];
      filteredPoints.forEach((point) => {
        const isFarEnough = cleanedPoints.every(
          (cleanedPoint) => point.distanceTo(cleanedPoint) > threshold
        );
        if (isFarEnough) {
          cleanedPoints.push(point);
        }
      });

      return cleanedPoints;
    },
    convertToDelaunay(vertices) {
      const points = [];
      vertices.forEach((v) => {
        const x = parseFloat(v.x);
        const y = parseFloat(v.y); // Supondo que você queira usar x e y para a triangulação
        if (!isNaN(x) && !isNaN(y)) {
          points.push(x, y);
        }
      });
      return points;
    },
    convertToIndexedGeometry(bufferGeometry) {
        // Step 1: Merge vertices to eliminate duplicates
        const mergedGeometry = BufferGeometryUtils.mergeVertices(bufferGeometry);
  
        // Step 2: Compute the index if it doesn't exist
        if (!mergedGeometry.index) {
          const indices = [];
          const position = mergedGeometry.attributes.position.array;
  
          for (let i = 0; i < position.length / 3; i++) {
            indices.push(i);
          }
  
          mergedGeometry.setIndex(indices);
        }
  
        return mergedGeometry;
      },
    processGeometry( bufGeometry ) {

        // Obtain a Geometry
        var geometry = bufGeometry;

        // Merge the vertices so the triangle soup is converted to indexed triangles
        // eslint-disable-next-line no-unused-vars
        BufferGeometryUtils.mergeVertices(geometry);

        // Convert again to BufferGeometry, indexed
        console.warn('convertToIndexedGeometry')
        var indexedBufferGeom = this.convertToIndexedGeometry(geometry)
        console.warn('convertToIndexedGeometry - end')

        console.table({bufGeometry, indexedBufferGeom })

        // Create index arrays mapping the indexed vertices to bufGeometry vertices
        console.warn('mapIndices')
        this.mapIndices( bufGeometry, indexedBufferGeom );
        console.warn('mapIndices - end')

    },
    // mapIndices( bufGeometry, indexedBufferGeom ) {

    //     // Creates ammoVertices, ammoIndices and ammoIndexAssociation in bufGeometry

    //     var vertices = bufGeometry.attributes.position.array;
    //     var idxVertices = indexedBufferGeom.attributes.position.array;
    //     var indices = indexedBufferGeom.index.array;

    //     var numIdxVertices = idxVertices.length / 3;
    //     var numVertices = vertices.length / 3;

    //     bufGeometry.ammoVertices = idxVertices;
    //     bufGeometry.ammoIndices = indices;
    //     bufGeometry.ammoIndexAssociation = [];

    //     for ( var i = 0; i < numIdxVertices; i++ ) {

    //         var association = [];
    //         bufGeometry.ammoIndexAssociation.push( association );

    //         var i3 = i * 3;

    //         for ( var j = 0; j < numVertices; j++ ) {
    //             var j3 = j * 3;
    //             if ( this.isEqual( idxVertices[ i3 ], idxVertices[ i3 + 1 ],  idxVertices[ i3 + 2 ],
    //                           vertices[ j3 ], vertices[ j3 + 1 ], vertices[ j3 + 2 ] ) ) {
    //                 association.push( j3 );
    //             }
    //         }

    //     }

    // },
    isEqual( x1, y1, z1, x2, y2, z2 ) {
        var delta = 0.000001;
        return Math.abs( x2 - x1 ) < delta &&
                Math.abs( y2 - y1 ) < delta &&
                Math.abs( z2 - z1 ) < delta;
    },
    createIndexedBufferGeometryFromGeometry(geometry) {
        const bufferGeom = new THREE.BufferGeometry();
    
        // Adiciona vértices
        const vertices = new Float32Array(geometry.vertices.length * 3);
        for (let i = 0; i < geometry.vertices.length; i++) {
            vertices[i * 3] = geometry.vertices[i].x;
            vertices[i * 3 + 1] = geometry.vertices[i].y;
            vertices[i * 3 + 2] = geometry.vertices[i].z;
        }
    
        // Adiciona índices de faces
        const indices = new Uint32Array(geometry.faces.length * 3);
        for (let i = 0; i < geometry.faces.length; i++) {
            indices[i * 3] = geometry.faces[i].a;
            indices[i * 3 + 1] = geometry.faces[i].b;
            indices[i * 3 + 2] = geometry.faces[i].c;
        }
    
        bufferGeom.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        bufferGeom.setIndex(new THREE.BufferAttribute(indices, 1));
    
        // Opcional: Compute normal if required
        bufferGeom.computeVertexNormals();
    
        return bufferGeom;
    },
    // createSoftVolume( bufferGeom, mass, pressure ) {

    //     this.processGeometry( bufferGeom );

    //     var volume = new THREE.Mesh( bufferGeom, new THREE.MeshPhongMaterial( { color: 0x400000 } ) );
    //     volume.castShadow = true;
    //     volume.receiveShadow = true;
    //     volume.frustumCulled = false;
    //     this.scene.add( volume );

    //     // Volume physic object

    //     var volumeSoftBody = this.softBodyHelpers.CreateFromTriMesh(
    //         this.physicsWorld.getWorldInfo(),
    //         bufferGeom.ammoVertices,
    //         bufferGeom.ammoIndices,
    //         bufferGeom.ammoIndices.length / 3,
    //         true );

    //     var sbConfig = volumeSoftBody.get_m_cfg();
    //     sbConfig.set_viterations( 40 );
    //     sbConfig.set_piterations( 40 );

    //     // Soft-soft and soft-rigid collisions
    //     sbConfig.set_collisions( 0x11 );

    //     // Friction
    //     sbConfig.set_kDF( 0.1 );
    //     // Damping
    //     sbConfig.set_kDP( 0.01 );
    //     // Pressure
    //     sbConfig.set_kPR( pressure );
    //     // Stiffness
    //     volumeSoftBody.get_m_materials().at( 0 ).set_m_kLST( 0.9 );
    //     volumeSoftBody.get_m_materials().at( 0 ).set_m_kAST( 0.9 );

    //     volumeSoftBody.setTotalMass( mass, false )
    //     Ammo.castObject( volumeSoftBody, Ammo.btCollisionObject ).getCollisionShape().setMargin( this.margin );
    //     this.physicsWorld.addSoftBody( volumeSoftBody, 1, -1 );
    //     volume.userData.physicsBody = volumeSoftBody;
    //     // Disable deactivation
    //     volumeSoftBody.setActivationState( 4 );

    //     this.softBodies.push( volume );

    // },
    closeGapsBetweenTeethAndGum(toothSelected = null) {
      // Se 'borderTooth' já existe, esvazie-o antes de adicionar novas curvas
      if (this.borderTooth) {
        while (this.borderTooth.children.length > 0) {
          this.borderTooth.remove(this.borderTooth.children[0]);
        }
      } else {
        // Se 'borderTooth' não existe, crie-o
        this.borderTooth = new THREE.Group();
        this.scene.add(this.borderTooth);
      }
    
      // Encontre todos os meshes de dentes na cena
      const toothMeshes = this.findTeethInScene();
    
      // Encontre os meshes da gengiva (superior e inferior)
      const gumMeshes = this.findGumsInScene();
    
      if (gumMeshes.length === 0) {
        console.error("Nenhum mesh da gengiva encontrado.");
        return;
      }
    
      // Separar gengivas superior e inferior
      const gumPoints = {
        superior: [],
        inferior: [],
      };
    
      gumMeshes.forEach((gumMesh) => {
        const points = [];
        const positions = gumMesh.geometry.attributes.position.array;
        for (let i = 0; i < positions.length; i += 3) {
          points.push(
            new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
          );
        }
        if (gumMesh.userData.modelType === "maxillary") {
          gumPoints.superior = points;
        } else if (gumMesh.userData.modelType === "mandibular") {
          gumPoints.inferior = points;
        }
      });
    
      // Defina o raio de busca ao redor de cada dente
      const searchRadius =1; // Ajuste conforme necessário
    
      // Função para verificar se um ponto está dentro da bounding sphere
      const isPointInBoundingSphere = (point, sphere) => {
        return point.distanceTo(sphere.center) <= sphere.radius;
      };
    
      // Função para calcular o perímetro de um triângulo
      const calculatePerimeter = (a, b, c) => {
        const ab = a.distanceTo(b);
        const bc = b.distanceTo(c);
        const ca = c.distanceTo(a);
        return ab + bc + ca;
      };
    
      // Filtrar pontos ao redor de cada dente e aplicar Delaunay
      toothMeshes.forEach((tooth) => {
        if (!toothSelected || toothSelected === tooth.userData.toothNumber) {
          tooth.geometry.computeBoundingSphere();
          const boundingSphere = tooth.geometry.boundingSphere.clone();
          boundingSphere.center = tooth.position.clone();
          boundingSphere.radius += searchRadius;
    
          // Determine a qual gengiva o dente pertence (superior ou inferior)
          const gumSide =
            parseInt(tooth.userData.toothNumber) <= 29 ? "superior" : "inferior";
          const pointsInRegion = gumPoints[gumSide].filter((point) =>
            isPointInBoundingSphere(point, boundingSphere)
          );
    
          if (pointsInRegion.length < 3) {
            console.warn(
              `Não há pontos suficientes na região ao redor do dente ${tooth.userData.toothNumber} para aplicar a triangulação de Delaunay.`
            );
            return;
          }
    
          // Converter para o formato esperado pelo Delaunator
          const delaunaypoints = this.convertToDelaunay(pointsInRegion);
    
          // Aplicar triangulação de Delaunay nos pontos filtrados
          const delaunay = new Delaunator(delaunaypoints);
    
          // Gerar faces a partir da triangulação
          const geometry = new THREE.BufferGeometry();
          const vertices = [];
          const indices = [];
    
          for (let i = 0; i < delaunay.triangles.length; i += 3) {
            const aIndex = delaunay.triangles[i];
            const bIndex = delaunay.triangles[i + 1];
            const cIndex = delaunay.triangles[i + 2];
    
            const a = pointsInRegion[aIndex];
            const b = pointsInRegion[bIndex];
            const c = pointsInRegion[cIndex];
    
            // Calcular o perímetro do triângulo
            const perimeter = calculatePerimeter(a, b, c);
    
            // Verificar se o perímetro é menor ou igual a 10 unidades
            if (perimeter <= 10) {
              vertices.push(
                a.x, a.y, a.z,
                b.x, b.y, b.z,
                c.x, c.y, c.z
              );
    
              indices.push(i, i + 1, i + 2);
            }
          }
    
          geometry.setAttribute(
            "position",
            new THREE.Float32BufferAttribute(vertices, 3)
          );
          geometry.setIndex(indices);
    
          const material = new THREE.MeshPhongMaterial({
            color: 0xffc0cb,
            specular: 0x333366,
            shininess: 100,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 1,
          });
    
          geometry.computeVertexNormals();
          this.calculateVertexNormals(geometry);
    
          const mesh = new THREE.Mesh(geometry, material);
    
          this.borderTooth.add(mesh);
        }
      });
    },
    cleanGumFragments() {
      const toothMeshes = [];
      const gumMeshFragments = [];
    
      // Percorre todos os objetos do grupo principal
      this.activeObject.traverse((child) => {
        if (child.userData.objectType === 'tooth') {
          toothMeshes.push(child); // Adiciona os dentes à lista
        } else if (child.userData.objectType === 'gums') {
          gumMeshFragments.push(child); // Adiciona fragmentos de gengiva à lista
        }
      });
    
      // Função para calcular a distância de um ponto ao dente mais próximo
      const findClosestTooth = (vertex) => {
        let closestTooth = null;
        let minDistance = Infinity;
    
        toothMeshes.forEach((tooth) => {
          const distance = vertex.distanceTo(tooth.position);
          if (distance < minDistance) {
            minDistance = distance;
            closestTooth = tooth;
          }
        });
    
        return closestTooth;
      };
    
      // Percorre os fragmentos de gengiva e tenta movê-los para o dente mais próximo
      gumMeshFragments.forEach((gumMesh) => {
        const positions = gumMesh.geometry.attributes.position;
        const newPositions = positions.array.slice(); // Cria uma cópia dos vértices da gengiva
    
        for (let i = 0; i < positions.count; i++) {
          const vertex = new THREE.Vector3(
            positions.getX(i),
            positions.getY(i),
            positions.getZ(i)
          );
    
          const closestTooth = findClosestTooth(vertex);
    
          // Se o fragmento estiver muito próximo do dente, mover o vértice para o dente
          if (closestTooth && vertex.distanceTo(closestTooth.position) < 0.5) {
            // Opcional: Mover o vértice da gengiva para o dente (ou ajustar a posição)
            vertex.lerp(closestTooth.position, 0.5); // Mover 50% em direção ao dente
    
            // Atualiza a posição na geometria da gengiva
            newPositions[i * 3] = vertex.x;
            newPositions[i * 3 + 1] = vertex.y;
            newPositions[i * 3 + 2] = vertex.z;
          }
        }
    
        // Atualiza a geometria com as novas posições
        gumMesh.geometry.attributes.position.copyArray(newPositions);
        gumMesh.geometry.attributes.position.needsUpdate = true;
      });
    
      console.log("Fragmentos de gengiva realocados para os dentes mais próximos.");
    },    
    closeGapsBetweenTeethAndGum_(toothSelected = null) {
      // Se 'borderTooth' já existe, esvazie-o antes de adicionar novas curvas
      if (this.borderTooth) {
        while (this.borderTooth.children.length > 0) {
          this.borderTooth.remove(this.borderTooth.children[0]);
        }
      } else {
        // Se 'borderTooth' não existe, crie-o
        this.borderTooth = new THREE.Group();
        this.scene.add(this.borderTooth);
      }

      // Encontre todos os meshes de dentes na cena
      const toothMeshes = this.findTeethInScene();

      // Encontre os meshes da gengiva (superior e inferior)
      const gumMeshes = this.findGumsInScene();

      if (gumMeshes.length === 0) {
        console.error("Nenhum mesh da gengiva encontrado.");
        return;
      }

      // Separar gengivas superior e inferior
      const gumPoints = {
        superior: [],
        inferior: [],
      };

      gumMeshes.forEach((gumMesh) => {
        const points = [];
        const positions = gumMesh.geometry.attributes.position.array;
        for (let i = 0; i < positions.length; i += 3) {
          points.push(
            new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
          );
        }
        if (gumMesh.userData.modelType === "maxillary") {
          gumPoints.superior = points;
        } else if (gumMesh.userData.modelType === "mandibular") {
          gumPoints.inferior = points;
        }
      });

      // Defina o raio de busca ao redor de cada dente
      const searchRadius = 0.5; // Ajuste conforme necessário

      // Função para verificar se um ponto está dentro da bounding sphere
      const isPointInBoundingSphere = (point, sphere) => {
        return point.distanceTo(sphere.center) <= sphere.radius;
      };

      // Filtrar pontos ao redor de cada dente e aplicar Delaunay
      toothMeshes.forEach((tooth) => {
        if (!toothSelected || toothSelected === tooth.userData.toothNumber) {
          tooth.geometry.computeBoundingSphere();
          const boundingSphere = tooth.geometry.boundingSphere.clone();
          boundingSphere.center = tooth.position.clone();
          boundingSphere.radius += searchRadius;

          // Determine a qual gengiva o dente pertence (superior ou inferior)
          const gumSide =
            parseInt(tooth.userData.toothNumber) <= 29
              ? "superior"
              : "inferior";
          const pointsInRegion = gumPoints[gumSide].filter((point) =>
            isPointInBoundingSphere(point, boundingSphere)
          );

          if (pointsInRegion.length < 3) {
            console.warn(
              `Não há pontos suficientes na região ao redor do dente ${tooth.userData.toothNumber} para aplicar a triangulação de Delaunay.`
            );
            return;
          }

          // Converter para o formato esperado pelo Delaunator
          const delaunaypoints = this.convertToDelaunay(pointsInRegion);

          // Aplicar triangulação de Delaunay nos pontos filtrados
          const delaunay = new Delaunator(delaunaypoints);

          // Gerar faces a partir da triangulação
          const geometry = new THREE.BufferGeometry();
          const vertices = [];
          const indices = [];

          for (let i = 0; i < delaunay.triangles.length; i += 3) {
            const a = delaunay.triangles[i];
            const b = delaunay.triangles[i + 1];
            const c = delaunay.triangles[i + 2];

            vertices.push(
              pointsInRegion[a].x,
              pointsInRegion[a].y,
              pointsInRegion[a].z,
              pointsInRegion[b].x,
              pointsInRegion[b].y,
              pointsInRegion[b].z,
              pointsInRegion[c].x,
              pointsInRegion[c].y,
              pointsInRegion[c].z
            );

            indices.push(i, i + 1, i + 2);
          }

          geometry.setAttribute(
            "position",
            new THREE.Float32BufferAttribute(vertices, 3)
          );
          geometry.setIndex(indices);

          const material = new THREE.MeshPhongMaterial({
            color: 0xffc0cb,
            specular: 0x333366,
            shininess: 100,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 1,
          });

          geometry.computeVertexNormals();
          this.calculateVertexNormals(geometry);

          const mesh = new THREE.Mesh(geometry, material);

          this.borderTooth.add(mesh);
        }
      });
    },
    // Função para identificar as bordas abertas de um objeto
    getOpenEdges(geometry) {
      const edges = new THREE.EdgesGeometry(geometry, 90);
      const positions = edges.attributes.position.array;
      let openEdges = [];

      for (let i = 0; i < positions.length; i += 6) {
        let v1 = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        let v2 = new THREE.Vector3(
          positions[i + 3],
          positions[i + 4],
          positions[i + 5]
        );
        openEdges.push([v1, v2]);
      }
      return openEdges;
    },

    // Função para criar tampas para os buracos dos dentes
    createCapsForTeeth(teeth) {
      let capGeometries = [];

      teeth.forEach((tooth) => {
        let openEdges = this.getOpenEdges(tooth.geometry);
        let capGeometry = new THREE.BufferGeometry();
        let vertices = [];

        // Triangulação simples para fechar buracos
        openEdges.forEach((edge) => {
          let [v1, v2] = edge;
          let midpoint = new THREE.Vector3()
            .addVectors(v1, v2)
            .multiplyScalar(0.5);

          // Transformar os vértices para a posição correta
          v1.applyMatrix4(tooth.matrixWorld);
          v2.applyMatrix4(tooth.matrixWorld);
          midpoint.applyMatrix4(tooth.matrixWorld);

          vertices.push(v1, v2, midpoint);
        });

        let positions = new Float32Array(vertices.length * 3);
        for (let i = 0; i < vertices.length; i++) {
          positions[i * 3] = vertices[i].x;
          positions[i * 3 + 1] = vertices[i].y;
          positions[i * 3 + 2] = vertices[i].z;
        }

        capGeometry.setAttribute(
          "position",
          new THREE.BufferAttribute(positions, 3)
        );
        capGeometry.computeVertexNormals();
        capGeometries.push(capGeometry);
      });

      return capGeometries;
    },
    // Função para criar uma geometria de gengiva fechada sem buracos
    createClosedGumGeometry(gums) {
      let openEdges = this.getOpenEdges(gums); // Encontrar as bordas abertas da gengiva

      let vertices = [];

      // Triangulação simples para fechar os buracos da gengiva
      openEdges.forEach((edge) => {
        let [v1, v2] = edge;
        let midpoint = new THREE.Vector3()
          .addVectors(v1, v2)
          .multiplyScalar(0.5);

        // Adicionar os vértices para a nova geometria da gengiva
        vertices.push(v1.clone(), v2.clone(), midpoint.clone());
      });

      return vertices;
    },

    // Função para mesclar geometrias de tampas com a gengiva
    mergeCapsWithGum(gum, capGeometries) {
      let geometries = [gum.geometry, capGeometries];
      const mergedGeometry = BufferGeometryUtils.mergeGeometries(
        geometries,
        false
      );
      gum.geometry.dispose();
      gum.geometry = mergedGeometry;
      gum.geometry.computeVertexNormals();
    },

    newMakeDynamicGum() {
      const gums = this.findGumInScene();
      //const teeth = this.findTeethInScene();

      const dentalGroup = this.scene.children.find(
        (child) =>
          child.isGroup &&
          child.children.some((obj) => obj.userData.objectType === "gums")
      );

      if (!dentalGroup) {
        console.error(
          "Nenhum grupo adequado encontrado que contenha as gengivas."
        );
        return;
      }

      let dynamicGum = dentalGroup.getObjectByName("dynamicGum");
      if (!dynamicGum) {
        dynamicGum = new THREE.Mesh(
          gums.geometry.clone(),
          new THREE.MeshPhongMaterial({
            color: 0xffc0cb,
            specular: 0x333366,
            shininess: 100,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 1,
          })
        );
        dynamicGum.userData.name = "dynamicGum";
        dynamicGum.userData.objectType = "dynamic";
        dynamicGum.name = "dynamicGum";
        dentalGroup.add(dynamicGum);
      } else {
        dynamicGum.geometry.dispose();
        dynamicGum.geometry = gums.geometry.clone();
      }

      // Criar tampas para os buracos dos dentes
      const gumEdgesVertices = this.createClosedGumGeometry(gums.geometry);
      const newGeometry = this.delaunayTriangulation(gumEdgesVertices);

      //   const material = new THREE.MeshPhongMaterial({
      //     color: 0xffc0cb,
      //     specular: 0x333366,
      //     shininess: 100,
      //     side: THREE.DoubleSide,
      //     transparent: true,
      //     opacity: 1,
      // });

      const material = new THREE.MeshBasicMaterial({
        color: 0x00ff00, // Verde para destacar
        wireframe: true,
      });

      newGeometry.computeVertexNormals();
      this.calculateVertexNormals(newGeometry);

      const capMesh = new THREE.Mesh(newGeometry, material);
      capMesh.name = `gumEdgesVertices`;
      this.scene.add(capMesh);
    },

    // Função para adicionar as tampas à cena para visualização
    addCapsToScene(capGeometries) {
      capGeometries.forEach((geometry, index) => {
        const material = new THREE.MeshBasicMaterial({
          color: 0x00ff00, // Verde para destacar
          wireframe: true,
        });
        const capMesh = new THREE.Mesh(geometry, material);
        capMesh.name = `cap_${index}`;
        this.scene.add(capMesh);
      });
    },
    createToothBorders(dentalMap) {
      // Se 'borderTooth' já existe, esvazie-o antes de adicionar novas curvas
      if (this.borderTooth) {
        while (this.borderTooth.children.length > 0) {
          this.borderTooth.remove(this.borderTooth.children[0]);
        }
      } else {
        // Se 'borderTooth' não existe, crie-o
        this.borderTooth = new THREE.Group();
        this.scene.add(this.borderTooth);
      }

      // Função interna para adicionar curvas Catmull-Rom
      const addLinesBetweenPoints = (
        points,
        parent,
        isClosed = true,
        radius = 0.4
      ) => {
        if (points.length > 1) {
          const curve = new THREE.CatmullRomCurve3(
            points,
            isClosed,
            "catmullrom",
            0.5
          );
          const tubeGeometry = new THREE.TubeGeometry(
            curve,
            24 * points.length,
            radius,
            8,
            isClosed
          );
          const tubeMaterial = new THREE.MeshPhongMaterial({
            color: 0xffc0cb,
            specular: 0x333366,
            shininess: 100,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 1,
          });

          tubeGeometry.computeVertexNormals();
          this.calculateVertexNormals(tubeGeometry);

          const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
          parent.add(tubeMesh);
        }
      };

      // Percorrer 'dentalMap' e adicionar curvas
      for (const tooth in dentalMap) {
        if (dentalMap[tooth].orderedVertices && dentalMap[tooth].centerOfMass) {
          const centerOfMass = new THREE.Vector3(
            dentalMap[tooth].centerOfMass.x,
            dentalMap[tooth].centerOfMass.y,
            dentalMap[tooth].centerOfMass.z
          );

          const teeth = this.findToothMesh(tooth);

          const position = new THREE.Vector3(
            teeth.position.x,
            teeth.position.y,
            teeth.position.z
          );

          const points = dentalMap[tooth].orderedVertices.map((vertex) =>
            new THREE.Vector3(vertex.x, vertex.y, vertex.z)
              .sub(centerOfMass)
              .add(position)
          );
          addLinesBetweenPoints(points, this.borderTooth);
        }
      }
    },
  },
};
