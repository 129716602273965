export const messages_en = {
  login: {
    title: "Login",
    username: "Username",
    usernamePlaceholder: "Enter your username",
    password: "Password",
    passwordPlaceholder: "Enter your password",
    submitButton: "Enter",
    unauthorizedAccess: "You need to be logged in to access this page.",
    wrongPassword: "Username or password incorrect, please check.",
    usernameRequired: "Username is required.",
    passwordRequired: "Password is required.",
  },
  teethNames: {
    centralIncisor: "Central Incisor",
    lateralIncisor: "Lateral Incisor",
    canine: "Canine",
    firstPremolar: "First Premolar",
    secondPremolar: "Second Premolar",
    firstMolar: "First Molar",
    secondMolar: "Second Molar",
    thirdMolarOrWisdomTooth: "Third Molar",
  },
  toothDetails: {
      "tooth": "Tooth",
      "status": "Status",
      "absent": "Absent",
      "present": "Present",
      "initialPosition": "Initial Position",
      "currentPosition": "Current Position",
      "initialRotation": "Initial Rotation",
      "currentRotation": "Current Rotation",
      "changeToothNumber": "Change Tooth Number",
      "deleteToothData": "Delete Tooth Data",
      "updateData": "Update Data",
      "confirmDelete": "Confirm Deletion",
      "deleteConfirmation": "Are you sure you want to delete the tooth data?",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "updating": "Updating Data",
      "baseModel" : "Base Model",
      "segmented" : "Segmented",
      "showAll" : "Show All"
    },
  bothArchs : "Both Archs",
  workArea: "Work Area",
  dashboard: "Dashboard",
  newPatient: "New Patient",
  logout: "Logout",
  savePatient: "Save Patient",
  exportModel: "Export Model",
  adjustmentRequests: "Adjustment Requests",
  addNewPatients: "Add new patients to the system.",
  export3DModels: "Export 3D models for printing.",
  export: "Export",
  patientData: "Patient Data",
  modelData: "Model Data",
  loadModel: "Load Models",
  close: "Close",
  patientId: "Patient ID",
  patientExternalId: "Patient External ID",
  ssn: "SSN",
  firstName: "First Name",
  lastName: "Last Name",
  address: "Address",
  city: "City",
  country: "Country",
  clinic: "Clinic",
  attachModel: "Attach Model",
  modelSetId: "Model Set ID",
  modelSetExternalId: "Model Set External ID",
  comment: "Comment",
  operator: "Operator",
  modelSetDate: "Model Set Date",
  scanDate: "Scan Date",
  scanTime: "Scan Time",
  maxillaryModel: "Maxillary Model",
  maxillaryModelFilename: "Maxillary Model Filename",
  mandibularModel: "Mandibular Model",
  mandibularModelFilename: "Mandibular Model Filename",
  modelInformation: "Model Information",
  polygons: "Polygons",
  vertices: "Vertices",
  statistics: "Statistics",
  appointmentStats: "Appointment Statistics",
  appointments: "Appointments",
  latestPatients: "Latest Patients",
  patientsToday: "Patients Today",
  totalPatients: "Total Patients",
  requests: "Requests",
  adjustments: "Adjustments",
  managePatients: "Manage Patients",
  patientHistory: "Patient History",
  addPartners: "Add Partners",
  managePartners: "Manage Partners",
  partnerDetails: "Partner Details",
  settings: "Settings",
  editor: "3D Editor",
  userProfile: "User Profile",
  ambientLight: "Ambient Light",
  directionalLight: "Directional Light",
  boxHelper: "Box Helper",
  objects: "Objects",
  creatingOcclusionPlan: "Creating Occlusion Plan",
  functions: "Functions",
  occlusionPlan: "Occlusion Plan",
  cutModel: "Cut Model",
  orientateTooth: "Orientate Tooth",
  extrusion: "Extrusion",
  measureDistance: "Measure Distance",
  dentalArch: "Dental Arch",
  instructions: "Instructions",
  planeInstructions:
    "Click on three points on the model to define the occlusion plane.",
  maxillary: "Maxilla",
  mandibular: "Mandible",
  transformControl: "Transform Control",
  cuttingPlane: "Cutting Plane",
  markingContour: "Marking Contour",
  smoothBorder: "Smooth Border",
  smoothingEdges: "Smoothing Edges",
  saveActiveObject: "Save Object",
  extractToothBorder: "Tooth Border",
  loadSegmentation: "Import Teeth",
  saveSegmentation: "Export Teeth",
  createDentalMesh: "Create Model",
  toggleVisibility: "Toggle Visibility of Object",
  assignMotionController: "Assign motion controller to selected object",
  showHideUpperJaw: "Show or Hide Upper Jaw",
  showHideLowerJaw: "Show or Hide Lower Jaw",
  activateTranslation: "Activate Translation Transformation",
  activateRotation: "Activate Rotation Transformation",
  turnOffAmbientLight: "Turn Off Ambient Light",
  turnOffDirectionalLight1: "Turn Off Directional Light 1",
  turnOffDirectionalLight2: "Turn Off Directional Light 2",
  applyCutAbovePlane: "Apply cut above cut plane",
  applyCutBelowPlane: "Apply cut below cut plane",
  activateContourMarking: "Activate Contour Marking",
  clearContours: "Clear Contours",
  frontView: "Front View",
  leftView: "Left View",
  topView: "Top View",
  toggleGumsVisibility: "Toggle Gums Visibility",
  toggleTeethVisibility: "Toggle Teeth Visibility",
  clearTasks: "Remove All Tasks From Buffer",
  dynamicGum: "Dynamic Gums",
  idealDentalArch: "Ideal Arch",
  creatingDentalArch : "Create Dental Arch",
  alignPlan: "Alignment Plan",
  definingMedialDistalPoints : "Setting Distal and Medial",
  applyChanges: "Apply Model Changes",
  noArchSelected: "No Arch Selected",
  archMoveType: "Arch Move",
  fullArch: "Full Arch",
  ends: "Arch Ends",
  midPoints : "Arch Mid Points",
  centerPoint: "Arch Central Point",
  endsLeft: "Arch Ends Left",
  midPointsLeft : "Arch Mid Points Left",
  endsRight: "Arch Ends Right",
  midPointsRight : "Arch Mid Points Right",

};
