<template>
  <v-bottom-navigation :elevation="0" class="bottom-nav">
    <v-btn class="btn-nav" :elevation="2"
      v-for="item in items"
      :key="item.value"
      @click="handleClick(item.action)"
      size="small">
      <v-tooltip
        activator="parent"
        location="top"
      >
        <template v-slot:activator>
          <v-icon>{{ item.icon }}</v-icon>
        </template>
        {{ item.text }}
      </v-tooltip>
    </v-btn>
  </v-bottom-navigation>
</template>

  
  <script>
  export default {
    name: 'ortho-3d-bottom-bar',
    props: {
      items: Array,
      permanent: Boolean,
      bottom: Boolean,
    },
    data: () => ({
      drawer: true,
    }),
    methods: {
      handleClick(action) {
        this.$emit('action', action);
      },
    }
  };
  </script>

<style scoped>
.bottom-nav {
  position: fixed;
  width: 100%;
  height: 32px!important;
  opacity: 1;
  margin-bottom: 2px!important;
}

.btn-nav {
  min-width: 24px!important;
}

</style>
  