export const messages_it = {
  login: {
    title: "Accesso",
    username: "Nome utente",
    usernamePlaceholder: "Inserisci il tuo nome utente",
    password: "Password",
    passwordPlaceholder: "Inserisci la tua password",
    submitButton: "Accedere",
    unauthorizedAccess: "È necessario accedere per visitare questa pagina.",
    wrongPassword: "Nome utente o password errati, per favore controlla.",
    usernameRequired: "Il nome utente è obbligatorio.",
    passwordRequired: "La password è obbligatoria.",
  },
  teethNames: {
    centralIncisor: "Incisivo Centrale",
    lateralIncisor: "Incisivo Laterale",
    canine: "Canino",
    firstPremolar: "Primo Premolare",
    secondPremolar: "Secondo Premolare",
    firstMolar: "Primo Molare",
    secondMolar: "Secondo Molare",
    thirdMolarOrWisdomTooth: "Terzo Molare",
  },
  toothDetails: {
      "tooth": "Dente",
      "status": "Stato",
      "absent": "Assente",
      "present": "Presente",
      "initialPosition": "Posizione Iniziale",
      "currentPosition": "Posizione Attuale",
      "initialRotation": "Rotazione Iniziale",
      "currentRotation": "Rotazione Attuale",
      "changeToothNumber": "Cambia Numero",
      "deleteToothData": "Elimina Dati",
      "updateData": "Aggiorna Dati",
      "confirmDelete": "Conferma Eliminazione",
      "deleteConfirmation": "Sei sicuro di voler eliminare i dati del dente?",
      "confirm": "Conferma",
      "cancel": "Annulla",
      "updating": "Aggiornamento Dati",
      "baseModel" : "Modello Base",
      "segmented" : "Segmentato",
      "showAll" : "Mostra Tutto"
    },
  bothArchs : "Entrambe le Arcate",
  workArea: "Area di Lavoro",
  dashboard: "Cruscotto",
  newPatient: "Nuovo Paziente",
  logout: "Disconnettersi",
  savePatient: "Salva Paziente",
  exportModel: "Esporta Modello",
  adjustmentRequests: "Richieste di Regolazione",
  addNewPatients: "Aggiungi nuovi pazienti al sistema.",
  export3DModels: "Esporta modelli 3D per la stampa.",
  export: "Esportare",
  patientData: "Dati del Paziente",
  modelData: "Dati dei Modelli",
  loadModel: "Carica Modelli",
  close: "Chiudi",
  patientId: "ID del Paziente",
  patientExternalId: "ID Esterno del Paziente",
  ssn: "Codice Fiscale",
  firstName: "Nome",
  lastName: "Cognome",
  address: "Indirizzo",
  city: "Città",
  country: "Paese",
  clinic: "Clinica",
  attachModel: "Allega Modello",
  modelSetId: "ID del Set di Modelli",
  modelSetExternalId: "ID Esterno del Set di Modelli",
  comment: "Commento",
  operator: "Operatore",
  modelSetDate: "Data del Set di Modelli",
  scanDate: "Data della Scansione",
  scanTime: "Ora della Scansione",
  maxillaryModel: "Modello Mascellare",
  maxillaryModelFilename: "Nome File del Modello Mascellare",
  mandibularModel: "Modello Mandibolare",
  mandibularModelFilename: "Nome File del Modello Mandibolare",
  modelInformation: "Informazione di Modelli",
  polygons: "Poligoni",
  vertices: "Vertici",
  statistics: "Statistiche",
  appointmentStats: "Statistiche degli Appuntamenti",
  appointments: "Appuntamenti",
  latestPatients: "Ultimi Pazienti",
  patientsToday: "Pazienti Oggi",
  totalPatients: "Totale Pazienti",
  requests: "Richieste",
  adjustments: "Aggiustamenti",
  managePatients: "Gestire Pazienti",
  patientHistory: "Storico dei Pazienti",
  addPartners: "Aggiungere Partner",
  managePartners: "Gestire Partner",
  partnerDetails: "Dettagli dei Partner",
  settings: "Impostazioni",
  editor: "Editor 3D",
  userProfile: "Profilo Utente",
  ambientLight: "Luce Ambientale",
  directionalLight: "Luce Direzionale",
  boxHelper: "Scatola Ausiliaria",
  objects: "Objetos",
  creatingOcclusionPlan: "Crear Piano di Occlusione",
  functions: "Funzioni",
  occlusionPlan: "Piano di Occlusione",
  cutModel: "Tagliare Modello",
  orientateTooth: "Orientare Dente",
  extrusion: "Estrusione",
  measureDistance: "Misura Distanza",
  dentalArch: "Arco Dentale",
  instructions: "Istruzioni",
  planeInstructions:
    "Fare clic su tre punti sul modello per definire il piano di occlusione.",
  maxillary: "Mascella",
  mandibular: "Mandibola",
  transformControl: "Controllo di Trasformazione",
  cuttingPlane: "Piano di Taglio",
  markingContour: "Segnando il contorno",
  smoothBorder: "Leviga Bordo",
  smoothingEdges: "Levigando i Bordi",
  saveActiveObject: "Salva Oggetto",
  extractToothBorder: "Bordo Dente",
  loadSegmentation: "Importa Denti",
  saveSegmentation: "Esporta Denti",
  createDentalMesh: "Crear Modello",
  toggleVisibility: "Cambia visibilità dell'oggetto selezionato",
  assignMotionController:
    "Assegna un controller di movimento all'oggetto selezionato",
  showHideUpperJaw: "Mostra o Nascondi Mascella Superiore",
  showHideLowerJaw: "Mostra o Nascondi Mascella Inferiore",
  activateTranslation: "Attiva Trasformazione di Traslazione",
  activateRotation: "Attiva Trasformazione di Rotazione",
  turnOffAmbientLight: "Spegni Luce Ambientale",
  turnOffDirectionalLight1: "Spegni Luce Direzionale 1",
  turnOffDirectionalLight2: "Spegni Luce Direzionale 2",
  applyCutAbovePlane: "Applica taglio sopra il piano di taglio",
  applyCutBelowPlane: "Applica taglio sotto il piano di taglio",
  activateContourMarking: "Attiva Marcatura di Contorno",
  clearContours: "Cancella Contorni",
  frontView: "Vista Frontale",
  leftView: "Vista Sinistra",
  topView: "Vista dall'Alto",
  toggleGumsVisibility: "Alterna Visibilità delle Gengive",
  toggleTeethVisibility: "Alterna Visibilità dei Denti",
  clearTasks: "Rimuovi Tutti i Compiti dal Buffer",
  dynamicGum : "Gengiva Dinamica",
  idealDentalArch: "Arco Ideale",
  creatingDentalArch : "Creare Arco Dentale",
  alignPlan: "Piano di Allineamento",
  definingMedialDistalPoints : "Impostazione mediale e distale",
  applyChanges : "Applicare Modifiche al Modello",
  noArchSelected: "Nessuna Arcata Selezionata",
  archMoveType: "Movimento Arco",
  fullArch: "Arco Completo",
  ends: "Estremità dell'Arco",
  midPoints: "Punti Medi dell'Arco",
  centerPoint: "Punto Centrale dell'Arco",
  endsLeft: "Estremità dell'Arco Sinistra",
  midPointsLeft : "Punti Medi dell'Arco Sinistra",
  endsRight: "Estremità dell'Arco Destra",
  midPointsRight : "Punti Medi dell'Arco Destra",
};
