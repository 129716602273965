import * as THREE from "three";

export default {
  name: "PreAlignmentMethods",
  methods: {
    drawCurvePoints(points, color) {
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.PointsMaterial({ color, size: 1 });
      const pointsMesh = new THREE.Points(geometry, material);
      this.scene.add(pointsMesh);
    },
    // Método principal para o pré-alinhamento
    preAlignDentalMap(dentalMap) {
      const { upperCurve, lowerCurve } = this.createCurves(dentalMap);

      const upperPoints = upperCurve ? upperCurve.getPoints(1024) : [];
      const lowerPoints = lowerCurve ? lowerCurve.getPoints(1024) : [];

       // Desenhar pontos na cena para visualização
       //this.drawCurvePoints(upperPoints, 0xff0000); // Cor vermelha para pontos da arcada superior
       //this.drawCurvePoints(lowerPoints, 0x0000ff); // Cor azul para pontos da arcada inferior

      Object.keys(dentalMap).forEach((toothNumber) => {
        const toothData = dentalMap[toothNumber];
        const centerOfMass = toothData.centerOfMass;
        if (centerOfMass) {
          // Determinar a curva correta (superior ou inferior)
          const isUpper = parseInt(toothNumber) < 31;
          const curvePoints = isUpper ? upperPoints : lowerPoints;

          // Encontrar o ponto mais próximo na curva com tolerância
        const { closestPoint, index } = this.findClosestPointOnCurve(centerOfMass, curvePoints, 0.01);

          console.table({toothNumber, closestPoint, centerOfMass})

          this.adjustToothPosition(
            toothNumber,
            closestPoint.x,
            closestPoint.z
          );

          // Ajustar a posição do centro de massa
          centerOfMass.copy(closestPoint);

          // Calcular a direção a partir dos pontos medial e distal
          const medial = toothData.medial;
          const distal = toothData.distal;
          const currentDirection = new THREE.Vector3()
            .subVectors(distal, medial)
            .normalize();

          // Obter a tangente da curva no ponto mais próximo
        const tangent = this.getTangentAtIndex(index, curvePoints).normalize();

          // Alinhar o dente angularmente
          this.alignToothAngularly(currentDirection, tangent, toothNumber);
        }
      });
    },

    // Método para criar curvas Catmull-Rom para as arcadas superior e inferior
    createCurves(dentalMap) {
      const upperTeeth = [];
      const lowerTeeth = [];

      Object.keys(dentalMap).forEach((toothNumber) => {
        const toothInt = parseInt(toothNumber);
        const point = dentalMap[toothNumber].centerOfMass;
        if (point) {
          if (toothInt >= 11 && toothInt <= 28) {
            upperTeeth.push({ toothNumber: toothInt, point });
          } else if (toothInt >= 31 && toothInt <= 48) {
            lowerTeeth.push({ toothNumber: toothInt, point });
          }
        }
      });

      upperTeeth.sort((a, b) => a.toothNumber - b.toothNumber);
      lowerTeeth.sort((a, b) => a.toothNumber - b.toothNumber);

      const upperLeft = upperTeeth
        .filter((tooth) => tooth.toothNumber <= 18)
        .reverse();
      const upperRight = upperTeeth.filter((tooth) => tooth.toothNumber >= 21 && tooth.toothNumber <= 28);
      const upperArch = [...upperLeft, ...upperRight].map(
        (tooth) => tooth.point
      );

      const lowerLeft = lowerTeeth
        .filter((tooth) => tooth.toothNumber >= 38)
        .reverse();
      const lowerRight = lowerTeeth.filter((tooth) => tooth.toothNumber >= 31 && tooth.toothNumber <= 38);
      const lowerArch = [...lowerLeft, ...lowerRight].map(
        (tooth) => tooth.point
      );

      const upperCurve = upperArch.length
        ? new THREE.CatmullRomCurve3(upperArch,false, 'catmullrom', 0.2)
        : null;
      const lowerCurve = lowerArch.length
        ? new THREE.CatmullRomCurve3(lowerArch, false, 'catmullrom', 0.2)
        : null;

      return { upperCurve, lowerCurve };
    },

    // Método para encontrar o ponto mais próximo na curva com tolerância
    findClosestPointOnCurve(point, curvePoints, tolerance) {
      let closestPoint = null;
      let minDistance = Infinity;
      let closestIndex = -1;

      curvePoints.forEach((curvePoint, index) => {
        const distance = point.distanceTo(curvePoint);
        if (distance < minDistance) {
          minDistance = distance;
          closestPoint = curvePoint;
          closestIndex = index;
        }
      });

      // Verificar se a distância mínima está dentro da tolerância
      if (minDistance <= tolerance) {
        return { closestPoint: point, index: closestIndex }; // Dentro da tolerância, retornar o ponto original e um índice inválido
      }

      return { closestPoint, index: closestIndex }; // Fora da tolerância, retornar o ponto mais próximo e seu índice
    },
    
    // Método para obter a tangente na curva usando o índice
    getTangentAtIndex(index, curvePoints) {
      const curve = new THREE.CatmullRomCurve3(curvePoints);
      const u = index / (curvePoints.length - 1);
      return curve.getTangent(u);
    },
  
    // Método para alinhar o dente angularmente
    alignToothAngularly(currentDirection, tangent, toothNumber) {
      // Cálculo da rotação angular do dente
      const cross = new THREE.Vector3().crossVectors(currentDirection, tangent);
      var angle = currentDirection.angleTo(tangent);
      // if (angle > Math.PI / 2) {
      //   angle = Math.PI - angle;
      // }
      const direction = Math.sign(cross.y);

      const quaternion = new THREE.Quaternion();
      quaternion.setFromAxisAngle(
        new THREE.Vector3(0, 1, 0),
        direction * angle
      );

      const toothMesh = this.findToothMesh(toothNumber);
      const toothData = this.dentalMap[toothNumber];

      toothMesh.quaternion.multiplyQuaternions(
        quaternion,
        toothMesh.quaternion
      );

      this.applyRotationAndLog(toothMesh.quaternion);

      toothMesh.updateMatrixWorld();
      this.logTransformations(toothMesh, toothData, quaternion, "Y");

    },
  },
};
