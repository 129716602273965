<template>
    <ortho-nav-bar :action="currentAction" :iaActive="iaActive" :message="statusMessage"></ortho-nav-bar>
    <div ref="threejsContainer" class="threejs-canvas-container">
    </div>

    <!-- Bottom Bar -->
    <ortho-3d-bottom-bar :items="bottomBarItems" @action="performAction" bottom />

    <!-- Sidebar -->
    <ortho-3d-side-bar :items="sideBarItems" @action="performAction" @file-selected="handleFileSelected"
        @object-clicked-in-list="handleObjectClickFromList" @tooth-clicked-in-list="handleToothClicked"
        @colorChanged="handleColorChange" @mode="updateMode" :mode="currentMode" :dental-map="dentalMap" @filter-meshes="filterMeshes"/>

    <v-dialog v-model="showDialog" persistent max-width="500px">
        <v-card>
            <v-card-title>{{ $t('instructions') }}</v-card-title>
            <v-card-text>
                {{ $t('planeInstructions') }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="showDialog = false">{{ $t('close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <ortho-progress-bar ref="progressDialog"><v-btn @click="startProcess">Iniciar Processo</v-btn></ortho-progress-bar>

</template>

<script>

// Three.js base and utilities
import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';

// Three.js loaders and exporters
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';

// Three.js controls
import { ArcballControls } from "three/addons/controls/ArcballControls.js";
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

// Three.js postprocessing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";

import { SMAAPass } from 'three/addons/postprocessing/SMAAPass.js';

// External libraries
import Delaunator from 'delaunator';
import { PCA } from 'ml-pca';


// Custom components and methods
import OrthoNavBar from '@/components/OrthoNavBar.vue';
import Ortho3dBottomBar from '@/components/Ortho3dBottomBar.vue';
import Ortho3dSideBar from '@/components/Ortho3dSideBar.vue';
import OrthoProgressBar from '@/components/OrthoProgressBar.vue';

import segmentationMethods from "@/components/methods/segmentationMethods";
import alignmentMethods from "@/components/methods/alignmentMethods";
import locationMethods from "@/components/methods/locationMethods";
import PreAlignmentMethods from "@/components/methods/PreAlignmentMethods";
import interfaceMethods from "@/components/methods/interfaceMethods";
import gumsMethods from "@/components/methods/gumsMethods";

//import conquerMethods from "@/components/methods/conquerMethods";
//import heatMapMethods from "@/components/methods/heatMapMethods";

//import borderMethods from './methods/borderMethods';
//import sphereHunter from './methods/sphereHunter';

import { Box, Octree, computeBoundingBox } from '@/class/Octree';
import { VoxelGrid } from '@/class/voxelGrid';

//import Ammo from '@/class/ammo.js';
//import { LoopSubdivision } from 'three-subdivide'

export default {
    name: 'ortho-3d-editor',
    scene: null,
    camera: null,
    controls: null,
    renderer: null,
    transformControl: null,
    composer: null,
    renderPass: null,
    fxaaPass: null,
    smoothingMeshEdges: null,
    currentMarker: null,
    borderTooth: null,
    physicsWorld: null,
    softBodies: [],
    transformAux1: null,
    softBodyHelpers: null,
    margin: 0.05,
    sphereMesh: null,
    components: {
        OrthoNavBar,
        Ortho3dBottomBar,
        Ortho3dSideBar,
        OrthoProgressBar
    },
    data() {
        return {
            //...sphereHunter.data(),
            dataMatrix: [],
            clock: new THREE.Clock(),
            currentIndex: { row: 0, col: 0 },
            frameId: null, // Usado para animação,
            sceneUpdate: null,
            selectedTooth: null,
            showDialog: false,
            activeObject: null,
            isDragging: false,
            selectedSphere: null,
            highestOrLowestPoint: null,
            brushMesh: null,
            isMouseDown: false,
            mouse: new THREE.Vector2(),
            smoothGeometryVertices: [],
            intersectionPoints: [],
            idealArchPoints: { maxillary: [], mandibular: [] },
            idealArchStartPoints: { maxillary: [], mandibular: [] },
            dentalMap: {},
            backgroundColor: "#7393B3",
            intervalId: null,
            toothIndexCounter: 0,
            currentMode: "",
            statusMessage: "",
            iaActive: "gray",
            mouseDownPosition: null,
            lastMousePosition: null,
            activeDentalArch: null,
            octree: null,
            voxelGrid: null,
            voxelSize: { x: 2, y: 1, z: 2 },
            activeArchMoveType: 'fullArch',
            miniGun: { active: false, neighbor: 5, variation: 30, object: null, point: null, angleBetween: -1, curvature: 2 },
            bottomBarItems: [
                { text: this.$t('toggleVisibility'), icon: 'mdi-eye-outline', action: 'toggleObjectVisibility' },
                { text: this.$t('assignMotionController'), icon: 'mdi-cube-outline', action: 'toggleBoxHelper' },
                { text: this.$t('showHideUpperJaw'), icon: 'mdi-chevron-up-circle', action: 'toggleUpperJaw' },
                { text: this.$t('showHideLowerJaw'), icon: 'mdi-chevron-down-circle', action: 'toggleLowerJaw' },
                { text: this.$t('activateTranslation'), icon: 'mdi-axis-arrow', action: 'controlTranslate' },
                { text: this.$t('activateRotation'), icon: 'mdi-rotate-3d', action: 'controlRotate' },
                { text: this.$t('turnOffAmbientLight'), icon: 'mdi-white-balance-sunny', action: 'toggleLight' },
                { text: this.$t('turnOffDirectionalLight1'), icon: 'mdi-lightbulb', action: 'toggleLightDir1' },
                { text: this.$t('turnOffDirectionalLight2'), icon: 'mdi-lightbulb', action: 'toggleLightDir2' },
                { text: this.$t('applyCutAbovePlane'), icon: 'mdi-arrow-up-bold-box-outline', action: 'applyCutAbove' },
                { text: this.$t('applyCutBelowPlane'), icon: 'mdi-arrow-down-bold-box-outline', action: 'applyCutBelow' },
                { text: this.$t('activateContourMarking'), icon: 'mdi-vector-polygon', action: 'toggleContourMarking' },
                { text: this.$t('clearContours'), icon: 'mdi-delete', action: 'clearContourPoints' },
                { text: this.$t('frontView'), icon: 'mdi-axis-z-arrow', action: 'viewFront' },
                { text: this.$t('leftView'), icon: 'mdi-axis-x-arrow', action: 'viewLeft' },
                { text: this.$t('topView'), icon: 'mdi-axis-y-arrow', action: 'viewBottom' },
                { text: this.$t('toggleGumsVisibility'), icon: 'mdi-tooth', action: 'toggleGums' },
                { text: this.$t('toggleTeethVisibility'), icon: 'mdi-tooth-outline', action: 'toggleTeeth' },
                { text: this.$t('clearTasks'), icon: 'mdi-broom', action: 'clearTasks' },
                { text: this.$t('applyChanges'), icon: 'mdi-update', action: 'applyChanges' }
            ]
            ,
            sideBarItems: [
                { text: 'occlusionPlan', icon: 'mdi-circle-outline', action: 'creatingOcclusionPlan', loading: false, isFile: false },
                { text: 'cuttingPlane', icon: 'mdi-scissors-cutting', action: 'cutModel', loading: false, isFile: false },
                { text: 'idealDentalArch', icon: 'mdi-vector-curve', action: 'creatingDentalArch', loading: false, isFile: false },
                { text: 'archMoveType', icon: 'mdi-vector-curve', action: 'toggleArchMoveType', loading: false, isFile: false },
                { text: 'dynamicGum', icon: 'mdi-tooth', action: 'dynamicGum', loading: false, isFile: false },
                { text: 'dentalArch', icon: 'mdi-vector-curve', action: 'showDentalArch', loading: false, isFile: false },
                { text: 'smoothBorder', icon: 'mdi-blur', action: 'startSES', loading: false, isFile: false },
                { text: 'saveActiveObject', icon: 'mdi-download', action: 'saveActiveObject', loading: false, isFile: false },
                { text: 'extractToothBorder', icon: 'mdi-pencil', action: 'extractToothBorder', loading: false, isFile: false },
                { text: 'loadSegmentation', icon: 'mdi-folder-open', action: 'loadSegmentation', loading: false, isFile: true },
                { text: 'saveSegmentation', icon: 'mdi-content-save', action: 'saveSegmentation', loading: false, isFile: false },
                { text: 'createDentalMesh', icon: 'mdi-creation', action: 'createDentalMesh', loading: false, isFile: false }
            ],
        };
    },
    methods: {
        ...segmentationMethods.methods,
        ...alignmentMethods.methods,
        ...locationMethods.methods,
        //...conquerMethods.methods,
        ...PreAlignmentMethods.methods,
        //...heatMapMethods.methods,
        ...gumsMethods.methods,
        ...interfaceMethods.methods,
        //...borderMethods.methods,
        //...sphereHunter.methods,

        flattenMesh() {
            if (!this.activeObject || !this.activeObject.geometry) {
                console.error('Não há objeto ativo ou geometria disponível.');
                return;
            }

            // Clone a geometria para não alterar o mesh original
            const originalGeometry = this.activeObject.geometry.clone();
            originalGeometry.computeBoundingBox(); // Garante que a bounding box está calculada

            const flattenedGeometry = new THREE.BufferGeometry();

            // Suponha que estamos trabalhando com BufferGeometry
            const positions = originalGeometry.attributes.position.array;
            const flattenedPositions = [];

            // Passar por cada vértice, ignorar a coordenada Y
            for (let j = 0; j < 0.1; j += 0.1)
                for (let i = 0; i < positions.length; i += 3) {
                    flattenedPositions.push(positions[i], j, positions[i + 2]); // X, 0, Z
                }

            // Atribuir as novas posições achatadas à geometria
            flattenedGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(flattenedPositions), 3));

            // Crie uma malha com a nova geometria achatada
            const material = this.activeObject.material ? this.activeObject.material.clone() :
                new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide, transparent: true, opacity: 0.5 });
            const flattenedMesh = new THREE.Mesh(flattenedGeometry, material);

            flattenedMesh.geometry.computeVertexNormals();

            // Obter o plano de corte
            const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane === true);
            if (!cuttingPlane) {
                console.error('Plano de corte não encontrado.');
                return;
            }

            // Ajustar a rotação para corresponder à do plano de corte
            flattenedMesh.rotation.copy(cuttingPlane.rotation);

            // Rotacionar 90 graus em X para alinhar com o plano de corte
            flattenedMesh.rotateX(Math.PI / 2);

            const planeDirection = cuttingPlane.getWorldDirection(new THREE.Vector3());
            const angleY = Math.atan2(planeDirection.x, planeDirection.z);
            flattenedMesh.rotateY(-angleY);

            // Alinhar a malha achatada com a altura do plano de corte
            // A posição Y da malha deve ser ajustada para corresponder à do plano
            flattenedMesh.position.copy(cuttingPlane.position);

            // Adicione a tampa à cena (se ainda não foi adicionada)
            this.scene.add(flattenedMesh);

            // Opcional: Retornar a malha achatada caso seja necessário
            return flattenedMesh;
        },
        setLoadingStatusForAction(action, loadingStatus) {
            const itemIndex = this.sideBarItems.findIndex(item => item.action === action);
            if (itemIndex !== -1) {
                // Vue 3 é reativo com atribuições diretas graças ao sistema Proxy
                this.sideBarItems[itemIndex].loading = loadingStatus;
            }
        },
        async executeActionWithLoading(action, actionFunction, delay = 500) {
            // Ativa o estado de loading para a ação especificada
            this.setLoadingStatusForAction(action, true);

            // Aguarda um breve delay antes de executar a função
            // O uso de setTimeout dentro de uma Promise permite usar await para aguardar o término do delay
            await new Promise(resolve => setTimeout(resolve, delay));

            try {
                // Executa a função passada como argumento
                // Se a função for assíncrona, o await garante que esperemos sua conclusão
                await actionFunction();
            } catch (error) {
                console.error("Erro durante a execução da ação:", error);
                // Aqui você pode adicionar qualquer manipulação de erro específica, como mostrar uma mensagem para o usuário
            } finally {
                // Desativa o estado de loading independentemente do resultado da função
                this.setLoadingStatusForAction(action, false);
            }
        },
        startSESAction() {
            // Dispara a ação startEdgeSmoothing do Vuex
            if (this.$store.state.editor3D.currentAction !== "smoothingEdges") {
                const points = this.initializeSES();
                this.identifyBorderVerticesDelaunay(points);
                //this.identifyBorderVertices();
                //this.flattenMesh();
            } else {
                this.$store.dispatch('editor3D/stopEdgeSmoothing');
                if (this.brushMesh) {
                    this.brushMesh.visible = false;
                    this.smoothingMeshEdges.dispose();
                    this.smoothingMeshEdges = null;
                }
            }
        },
        createSmoothShapeFromContour(contourPoints3D) {
            // Obtenha os pontos de contorno do Vuex store
            //const contourPoints3D = this.$store.getters['editor3D/contourPoints'];

            // Verifique se há pontos suficientes para criar um contorno
            if (!contourPoints3D || contourPoints3D.length < 3) {
                console.error('Não há pontos de contorno suficientes para criar um shape suavizado.');
                return;
            }

            // Cria uma curva Catmull-Rom que passa pelos pontos do contorno
            const curve = new THREE.CatmullRomCurve3(contourPoints3D, false, 'catmullrom', 0.5);

            // Obter os pontos da curva suavizada
            const smoothPoints = curve.getPoints(25);  // A quantidade de pontos pode ser ajustada para maior suavidade

            // Crie um novo shape
            const shape = new THREE.Shape();

            // Adicione o primeiro ponto como ponto de movimento (MoveTo)
            shape.moveTo(smoothPoints[0].x, smoothPoints[0].z);

            // Adicione os pontos subsequentes como linhas curvas (SplineThru)
            // Nota: THREE.CurvePath foi descontinuado, então usamos SplineThru diretamente no Shape
            shape.splineThru(smoothPoints.slice(1).map(p => new THREE.Vector2(p.x, p.z)));

            return shape;
        },
        createExtrudedModel() {

            if (!this.activeObject || !this.activeObject.geometry) {
                console.error('Não há objeto ativo ou geometria para extrudar.');
                return;
            }

            // Certifique-se de que a geometria do objeto ativo é uma BufferGeometry com normais computadas
            if (!this.activeObject.geometry.isBufferGeometry) {
                console.error('A geometria do objeto ativo não é uma BufferGeometry ou não possui normais.');
                return;
            }

            const activeGeometry = this.activeObject.geometry;

            // Create edge geometry from the active object's geometry
            const edges = new THREE.EdgesGeometry(activeGeometry, 90);
            const positionsArray = edges.attributes.position.array;

            // Convert the flat array into an array of Vector3
            let vertices = [];
            for (let i = 0; i < positionsArray.length; i += 3) {
                vertices.push(new THREE.Vector3(positionsArray[i], positionsArray[i + 1], positionsArray[i + 2]));
            }

            // Obtain unique vertices
            const uniqueVertices = this.uniqueVerticesFunction(vertices);

            // Map vertices to edges
            const vertexToEdgeMap = this.createVertexToEdgeMap(edges);

            // Order vertices to form a connected edge path
            let orderedVertices = this.createOrderedEdgePath(uniqueVertices, vertexToEdgeMap);

            const shape = this.createSmoothShapeFromContour(orderedVertices);

            // Definir configurações de extrusão
            const extrudeSettings = {
                steps: 2,
                depth: 1,
                bevelEnabled: true,
                bevelThickness: 0.5,
                bevelSize: 0.5,
                bevelOffset: 1,
                bevelSegments: 3
            };

            // Criar a geometria extrudada
            const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
            const material = new THREE.MeshPhongMaterial({ color: 0xdddddd, specular: 0x666666, shininess: 400, side: THREE.DoubleSide });

            const extrudedMesh = new THREE.Mesh(geometry, material);

            geometry.computeVertexNormals();
            this.calculateVertexNormals(geometry);

            // Adicionar a malha extrudada à cena
            this.scene.add(extrudedMesh);

            // Opcional: Se você deseja manter uma referência para esta malha para uso futuro
            this.extrudedShape = extrudedMesh;
        },
        removeObjectFromScene(objectToRemove) {
            if (!objectToRemove || !this.scene) return;

            // Encontra o objeto na cena pelo uuid
            const object = this.scene.children.find(child => child.uuid === objectToRemove.uuid);

            if (object) {
                // Remove o objeto da cena
                this.scene.remove(object);

                // Opcional: Limpa o objeto da memória se não for mais necessário
                if (object.geometry) object.geometry.dispose();
                if (object.material) {
                    // Se for um material composto, iterar sobre todos os materiais
                    if (Array.isArray(object.material)) {
                        object.material.forEach(material => material.dispose());
                    } else {
                        object.material.dispose();
                    }
                }
                if (object.texture) object.texture.dispose();
            }
        },
        calculateVertexNormals(geometry) {
            const vertices = geometry.attributes.position.array;
            const normals = geometry.attributes.normal.array;
            let vertexNormals = [];
            let vertexMap = new Map();

            // Função de ajuda para formatar a chave com segurança
            const formatKey = (x, y, z) => {
                // Tentar converter strings para números
                const numX = parseFloat(x);
                const numY = parseFloat(y);
                const numZ = parseFloat(z);

                // Verificar se algum valor convertido é NaN
                if (isNaN(numX) || isNaN(numY) || isNaN(numZ)) {
                    // Retornar um valor que indica um erro na conversão
                    return 'invalid,invalid,invalid';
                }
                // Usar números convertidos e formatá-los
                return `${numX.toFixed(8)},${numY.toFixed(8)},${numZ.toFixed(8)}`;
            };

            // Agrupar vértices que compartilham a mesma posição
            for (let i = 0; i < vertices.length; i += 9) {
                for (let j = 0; j < 3; j++) {
                    // Criar uma chave única para a posição do vértice
                    let key = formatKey(
                        vertices[i + j * 3],
                        vertices[i + j * 3 + 1],
                        vertices[i + j * 3 + 2]
                    );
                    if (!vertexMap.has(key)) {
                        vertexMap.set(key, []);
                    }
                    vertexMap.get(key).push(i + j * 3); // Armazenar o índice do vértice
                }
            }

            // Calcular as normais dos vértices
            for (let i = 0; i < vertices.length; i += 9) {
                // Calcular a normal do triângulo
                let normal = this.calculateNormalFromVertices(vertices, i);

                // Distribuir a normal do triângulo para os vértices compartilhados
                for (let j = 0; j < 3; j++) {
                    let vertexIndex = i + j * 3;
                    let key = formatKey(vertices[vertexIndex], vertices[vertexIndex + 1], vertices[vertexIndex + 2])
                    for (let sharedVertexIndex of vertexMap.get(key)) {
                        if (!vertexNormals[sharedVertexIndex]) {
                            vertexNormals[sharedVertexIndex] = new THREE.Vector3();
                        }
                        vertexNormals[sharedVertexIndex].add(normal);
                    }
                }
            }

            // Normalizar as normais dos vértices
            for (let i = 0; i < vertexNormals.length; i += 3) {
                if (vertexNormals[i]) {
                    let normal = vertexNormals[i];
                    normal.normalize();
                    normals[i] = normal.x;
                    normals[i + 1] = normal.y;
                    normals[i + 2] = normal.z;
                }
            }

            // Atualizar o atributo de normal da geometria
            geometry.attributes.normal.needsUpdate = true;
        },
        gumCutter(distanceThreshold = 9) {

            // Encontrar todos os meshes de dentes dentro do activeObject
            const toothMeshes = this.activeObject.children.filter(child => child.userData.objectType === "tooth");
            if (toothMeshes.length === 0) {
                console.error('No tooth meshes found in the active object.');
                return;
            }

            // Armazenar os centros das boundingBoxes dos dentes em um array de vetores
            const toothCenters = toothMeshes.map(toothMesh => {
                const boundingBox = new THREE.Box3().setFromObject(toothMesh);
                const center = new THREE.Vector3();
                boundingBox.getCenter(center);
                return center;
            });

            // Função para determinar se um ponto está dentro do limite de distância em relação a qualquer centro de dente
            const isWithinToothLimit = (vertex) => {
                return toothCenters.some(center => vertex.distanceTo(center) < distanceThreshold);
            };

            // Função para cortar um mesh com base na distância em relação aos dentes
            const cutMesh = (mesh) => {
                if (!mesh.geometry) {
                    console.error('No geometry found in the mesh.');
                    return;
                }

                // Clonar a geometria do mesh para não modificar a original
                const clonedGeometry = mesh.geometry.clone();
                const positionAttribute = clonedGeometry.getAttribute('position');

                const newVertices = [];

                // Iterar sobre cada triângulo da geometria (cada conjunto de 3 vértices)
                for (let i = 0; i < positionAttribute.count; i += 3) {
                    const v1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                    const v2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
                    const v3 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

                    // Adicionar o triângulo se estiver dentro do limite de distância em relação aos dentes
                    if (isWithinToothLimit(v1) || isWithinToothLimit(v2) || isWithinToothLimit(v3)) {
                        newVertices.push(v1, v2, v3);
                    }
                }

                // Se nenhum vértice foi adicionado, retorne
                if (newVertices.length === 0) {
                    console.error('No vertices found within the tooth distance limit.');
                    return;
                }

                // Crie uma nova BufferGeometry a partir dos vértices filtrados
                const newGeometry = new THREE.BufferGeometry().setFromPoints(newVertices);
                newGeometry.computeVertexNormals(); // Recalcular as normais dos vértices
                this.calculateVertexNormals(newGeometry);

                // Substitua a geometria do mesh pela nova geometria cortada
                mesh.geometry.dispose(); // Libere a geometria antiga
                mesh.geometry = newGeometry;
                mesh.geometry.needsUpdate = true;
            };

            // Encontrar o mesh com userData.objectType igual a "gums" dentro do activeObject
            const gumsMesh = this.activeObject.children.find(child => child.userData.objectType === "gums");
            if (!gumsMesh) {
                console.error('No gums mesh found in the active object.');
                return;
            }

            // Cortar o mesh ativo de gengiva com base na proximidade aos dentes
            cutMesh(gumsMesh);

            // Encontrar outros meshes com o mesmo userData.modelType na cena, mas não dentro do activeObject
            const modelType = this.activeObject.userData.modelType;
            const otherMeshes = this.scene.children.filter(child =>
                child !== this.activeObject && // Certifique-se de não incluir o próprio activeObject
                child.userData.modelType === modelType && // Mesmo modelType
                child.geometry // Verifique se o mesh tem geometria
            );

            // Cortar todos os outros meshes encontrados
            otherMeshes.forEach(mesh => cutMesh(mesh));
        },
        cutActiveObject(removeAbove, distanceThreshold = 9) {
            const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane);
            if (!cuttingPlane) {
                console.error('No cutting plane found in the scene.');
                return;
            }

            // Obter a normal do plano no espaço do mundo e a posição do plano
            const planeNormal = new THREE.Vector3(0, 0, 1); // Supõe-se que a normal local do plano é Z+
            planeNormal.applyQuaternion(cuttingPlane.quaternion).normalize();

            const planePosition = cuttingPlane.position.clone();

            // Função para determinar se um ponto está acima do plano de corte
            const isAbovePlane = (vertex) => {
                const directionFromPlane = vertex.clone().sub(planePosition);
                const distance = directionFromPlane.dot(planeNormal);
                return distance > 0;
            };

            // Encontrar todos os meshes de dentes dentro do activeObject
            const toothMeshes = this.activeObject.children.filter(child => child.userData.objectType === "tooth");
            if (toothMeshes.length === 0) {
                console.error('No tooth meshes found in the active object.');
                return;
            }

            // Armazenar os centros das boundingBoxes dos dentes em um array de vetores
            const toothCenters = toothMeshes.map(toothMesh => {
                const boundingBox = new THREE.Box3().setFromObject(toothMesh);
                const center = new THREE.Vector3();
                boundingBox.getCenter(center);
                return center;
            });

            // Função para determinar se um ponto está dentro do limite de distância em relação a qualquer centro de dente
            const isWithinToothLimit = (vertex) => {
                return toothCenters.some(center => vertex.distanceTo(center) < distanceThreshold);
            };

            // Função para cortar um mesh com base na distância em relação aos dentes
            const cutMesh = (mesh) => {
                if (!mesh.geometry) {
                    console.error('No geometry found in the mesh.');
                    return;
                }

                // Clonar a geometria do mesh para não modificar a original
                const clonedGeometry = mesh.geometry.clone();
                const positionAttribute = clonedGeometry.getAttribute('position');

                const newVertices = [];

                // Iterar sobre cada triângulo da geometria (cada conjunto de 3 vértices)
                for (let i = 0; i < positionAttribute.count; i += 3) {
                    const v1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                    const v2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
                    const v3 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

                    // Adicionar o triângulo se estiver dentro do limite de distância em relação aos dentes
                    if (isWithinToothLimit(v1) || isWithinToothLimit(v2) || isWithinToothLimit(v3)) {
                        if ((removeAbove && !(isAbovePlane(v1) || isAbovePlane(v2) || isAbovePlane(v3))) ||
                            (!removeAbove && isAbovePlane(v1) && isAbovePlane(v2) && isAbovePlane(v3))) {
                            newVertices.push(v1, v2, v3);
                        }
                    }
                }

                // Se nenhum vértice foi adicionado, retorne
                if (newVertices.length === 0) {
                    console.error('No vertices found within the tooth distance limit.');
                    return;
                }

                // Crie uma nova BufferGeometry a partir dos vértices filtrados
                const newGeometry = new THREE.BufferGeometry().setFromPoints(newVertices);
                newGeometry.computeVertexNormals(); // Recalcular as normais dos vértices
                this.calculateVertexNormals(newGeometry);

                // Substitua a geometria do mesh pela nova geometria cortada
                mesh.geometry.dispose(); // Libere a geometria antiga
                mesh.geometry = newGeometry;
                mesh.geometry.needsUpdate = true;
            };

            // Encontrar o mesh com userData.objectType igual a "gums" dentro do activeObject
            const gumsMesh = this.activeObject.children.find(child => child.userData.objectType === "gums");
            if (!gumsMesh) {
                console.error('No gums mesh found in the active object.');
                return;
            }

            // Cortar o mesh ativo de gengiva com base na proximidade aos dentes
            cutMesh(gumsMesh);

            // Encontrar outros meshes com o mesmo userData.modelType na cena, mas não dentro do activeObject
            const modelType = this.activeObject.userData.modelType;
            const otherMeshes = this.scene.children.filter(child =>
                child !== this.activeObject && // Certifique-se de não incluir o próprio activeObject
                child.userData.modelType === modelType && // Mesmo modelType
                child.geometry // Verifique se o mesh tem geometria
            );

            // Cortar todos os outros meshes encontrados
            otherMeshes.forEach(mesh => cutMesh(mesh));
        },
        cutActiveObject___(removeAbove) {
            // Encontrar o cuttingPlane na cena baseado na propriedade isCuttingPlane
            const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane);
            if (!cuttingPlane) {
                console.error('No cutting plane found in the scene.');
                return;
            }

            // Obter a normal do plano no espaço do mundo e a posição do plano
            const planeNormal = new THREE.Vector3(0, 0, 1); // Supõe-se que a normal local do plano é Z+
            planeNormal.applyQuaternion(cuttingPlane.quaternion).normalize();

            const planePosition = cuttingPlane.position.clone();

            // Função para determinar se um ponto está acima do plano de corte
            const isAbovePlane = (vertex) => {
                const directionFromPlane = vertex.clone().sub(planePosition);
                const distance = directionFromPlane.dot(planeNormal);
                return distance > 0;
            };

            // Função para cortar um mesh com base no plano de corte
            const cutMesh = (mesh) => {
                if (!mesh.geometry) {
                    console.error('No geometry found in the mesh.');
                    return;
                }

                // Clonar a geometria do mesh para não modificar a original
                const clonedGeometry = mesh.geometry.clone();
                const positionAttribute = clonedGeometry.getAttribute('position');

                const newVertices = [];

                // Iterar sobre cada triângulo da geometria (cada conjunto de 3 vértices)
                for (let i = 0; i < positionAttribute.count; i += 3) {
                    const v1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                    const v2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
                    const v3 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

                    // Adicionar triângulo se todos ou nenhum dos pontos estiverem acima do plano,
                    // dependendo de 'removeAbove'
                    if ((removeAbove && !(isAbovePlane(v1) || isAbovePlane(v2) || isAbovePlane(v3))) ||
                        (!removeAbove && isAbovePlane(v1) && isAbovePlane(v2) && isAbovePlane(v3))) {
                        newVertices.push(v1, v2, v3);
                    }
                }

                // Se nenhum vértice foi adicionado, retorne
                if (newVertices.length === 0) {
                    console.error('No vertices found above or below the cutting plane.');
                    return;
                }

                // Crie uma nova BufferGeometry a partir dos vértices cortados
                const newGeometry = new THREE.BufferGeometry().setFromPoints(newVertices);
                newGeometry.computeVertexNormals(); // Recalcular as normais dos vértices
                this.calculateVertexNormals(newGeometry);

                // Substitua a geometria do mesh pela nova geometria cortada
                mesh.geometry.dispose(); // Libere a geometria antiga
                mesh.geometry = newGeometry;
                mesh.geometry.needsUpdate = true;
            };

            // Encontrar o mesh com userData.objectType igual a "gums" dentro do activeObject
            const gumsMesh = this.activeObject.children.find(child => child.userData.objectType === "gums");
            if (!gumsMesh) {
                console.error('No gums mesh found in the active object.');
                return;
            }

            // Cortar o mesh ativo
            cutMesh(gumsMesh);

            // Encontrar outro mesh com o mesmo userData.modelType na cena, mas não dentro do activeObject
            const modelType = this.activeObject.userData.modelType;
            const otherMeshes = this.scene.children.filter(child =>
                child !== this.activeObject && // Certifique-se de não incluir o próprio activeObject
                child.userData.modelType === modelType && // Mesmo modelType
                child.geometry // Verifique se o mesh tem geometria
            );


            // Cortar todos os outros meshes encontrados
            otherMeshes.forEach(mesh => cutMesh(mesh));
        },
        cutActiveObject__(removeAbove) {
            // Encontrar o cuttingPlane na cena baseado na propriedade isCuttingPlane
            const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane);
            if (!cuttingPlane) {
                console.error('No cutting plane found in the scene.');
                return;
            }

            // Obter a normal do plano no espaço do mundo e a posição do plano
            const planeNormal = new THREE.Vector3(0, 0, 1); // Supõe-se que a normal local do plano é Z+
            planeNormal.applyQuaternion(cuttingPlane.quaternion).normalize();

            const planePosition = cuttingPlane.position.clone();

            // Função para determinar se um ponto está acima do plano de corte
            const isAbovePlane = (vertex) => {
                const directionFromPlane = vertex.clone().sub(planePosition);
                const distance = directionFromPlane.dot(planeNormal);
                return distance > 0;
            };

            // Encontrar o mesh com userData.objectType igual a "gums" dentro do activeObject
            const gumsMesh = this.activeObject.children.find(child => child.userData.objectType === "gums");
            if (!gumsMesh) {
                console.error('No gums mesh found in the active object.');
                return;
            }

            // Se o gumsMesh não tiver geometria, retorne
            if (!gumsMesh.geometry) {
                console.error('No geometry found in the gums mesh.');
                return;
            }

            // Clonar a geometria do gumsMesh para não modificar a original
            const clonedGeometry = gumsMesh.geometry.clone();
            const positionAttribute = clonedGeometry.getAttribute('position');

            const newVertices = [];

            // Iterar sobre cada triângulo da geometria (cada conjunto de 3 vértices)
            for (let i = 0; i < positionAttribute.count; i += 3) {
                const v1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                const v2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
                const v3 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

                // Adicionar triângulo se todos ou nenhum dos pontos estiverem acima do plano,
                // dependendo de 'removeAbove'
                if ((removeAbove && !(isAbovePlane(v1) || isAbovePlane(v2) || isAbovePlane(v3))) ||
                    (!removeAbove && isAbovePlane(v1) && isAbovePlane(v2) && isAbovePlane(v3))) {
                    newVertices.push(v1, v2, v3);
                }
            }

            // Se nenhum vértice foi adicionado, retorne
            if (newVertices.length === 0) {
                console.error('No vertices found above or below the cutting plane.');
                return;
            }

            // Crie uma nova BufferGeometry a partir dos vértices cortados
            const newGeometry = new THREE.BufferGeometry().setFromPoints(newVertices);
            newGeometry.computeVertexNormals(); // Recalcular as normais dos vértices
            this.calculateVertexNormals(newGeometry);

            // Substitua a geometria do gumsMesh pela nova geometria cortada
            gumsMesh.geometry.dispose(); // Libere a geometria antiga
            gumsMesh.geometry = newGeometry;
            gumsMesh.geometry.needsUpdate = true;
        },
        cutActiveObject_(removeAbove) {
            // Encontrar o cuttingPlane na cena baseado na propriedade isCuttingPlane
            const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane);
            if (!cuttingPlane) {
                console.error('No cutting plane found in the scene.');
                return;
            }

            // Obter a normal do plano no espaço do mundo e a posição do plano
            const planeNormal = new THREE.Vector3(0, 0, 1); // Supõe-se que a normal local do plano é Z+
            planeNormal.applyQuaternion(cuttingPlane.quaternion).normalize();

            const planePosition = cuttingPlane.position.clone();


            // Função para determinar se um ponto está acima do plano de corte
            const isAbovePlane = (vertex) => {
                const directionFromPlane = vertex.clone().sub(planePosition);
                const distance = directionFromPlane.dot(planeNormal);
                return distance > 0;
            };

            // Se o objeto ativo não for definido ou não tiver geometria, retorne
            if (!this.activeObject || !this.activeObject.geometry) {
                console.error('No active object with geometry to cut.');
                return;
            }

            // Clonar a geometria do objeto ativo para não modificar a original
            const clonedGeometry = this.activeObject.geometry.clone();
            const positionAttribute = clonedGeometry.getAttribute('position');

            const newVertices = [];

            // Iterar sobre cada triângulo da geometria (cada conjunto de 3 vértices)
            for (let i = 0; i < positionAttribute.count; i += 3) {
                const v1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                const v2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
                const v3 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

                // Adicionar triângulo se todos ou nenhum dos pontos estiverem acima do plano,
                // dependendo de 'removeAbove'
                if ((removeAbove && !(isAbovePlane(v1) || isAbovePlane(v2) || isAbovePlane(v3))) ||
                    (!removeAbove && isAbovePlane(v1) && isAbovePlane(v2) && isAbovePlane(v3))) {
                    newVertices.push(v1, v2, v3);
                }
            }

            // Se nenhum vértice foi adicionado, retorne
            if (newVertices.length === 0) {
                console.error('No vertices found above or below the cutting plane.');
                return;
            }

            // Crie uma nova BufferGeometry a partir dos vértices cortados
            const newGeometry = new THREE.BufferGeometry().setFromPoints(newVertices);
            newGeometry.computeVertexNormals(); // Recalcular as normais dos vértices
            this.calculateVertexNormals(newGeometry);


            // Substitua a geometria do objeto ativo pela nova geometria cortada
            this.activeObject.geometry.dispose(); // Libere a geometria antiga
            this.activeObject.geometry = newGeometry;
            this.activeObject.geometry.needsUpdate = true;

        },
        create3DSeamDelaunay(cv1, cv2) {
            // Unindo os dois arrays de vetores
            const combinedVertices = [...cv1, ...cv2];

            // Chamando a função genérica de triangulação de Delaunay com o array combinado
            const newGeometry = this.delaunayTriangulation(combinedVertices);

            // Verificando se a nova geometria foi criada com sucesso
            if (newGeometry) {
                return newGeometry;
            } else {
                console.error('Falha ao criar a costura 3D');
                return null;
            }
        },
        create3DSeam(cv1, cv2) {
            var geometry = new THREE.BufferGeometry();

            // Concatenando os conjuntos de vértices em um único array
            var vertices = cv1.concat(cv2);

            // Convertendo os vértices para um array unidimensional
            var verticesArray = new Float32Array(vertices.length * 3);
            vertices.forEach((vertex, index) => {
                verticesArray[index * 3] = vertex.x;
                verticesArray[index * 3 + 1] = vertex.y;
                verticesArray[index * 3 + 2] = vertex.z;
            });

            geometry.setAttribute('position', new THREE.BufferAttribute(verticesArray, 3));

            // Criando as faces da costura
            var indices = [];
            for (var i = 0; i < cv1.length - 1; i++) {
                indices.push(i, i + cv1.length, i + 1, i + 1, i + cv1.length, i + cv1.length + 1);
            }

            // Correção na adição das faces para fechar a estrutura
            indices.push(cv1.length - 1, 2 * cv1.length - 1, 0);
            indices.push(0, cv1.length, cv1.length - 1);

            var indicesArray = new Uint16Array(indices);
            geometry.setIndex(new THREE.BufferAttribute(indicesArray, 1));

            geometry.computeVertexNormals();

            return geometry;
        },
        removeAllHelperObjects() {
            const helperObjects = this.scene.children.filter(child => child.userData.helper);

            helperObjects.forEach(obj => {
                if (obj.geometry) obj.geometry.dispose();
                if (obj.material) obj.material.dispose();
                this.scene.remove(obj);
            });
        },
        handleObjectClickFromList(object) {
            // Lógica para lidar com o objeto clicado
            this.activeObject = object;
            this.selectedTooth = null;
            if (object.userData.modelType) {
                this.activeDentalArch = object.userData.modelType;
            } else {
                this.activeDentalArch = null;
            }
        },
        initiateOcclusionPlan() {
            this.$store.dispatch('editor3D/startOcclusionPlanCreation');
            this.showDialog = (this.currentAction !== null); // Controla a visibilidade de um diálogo de instruções
        },
        async initiateDentalArch() {

            if (this.activeDentalArch) {

                this.statusMessage = '';
                // Verifica se já está no processo de criação do arco dentário
                if (this.$store.state.editor3D.currentAction === 'creatingDentalArch') {
                    // Se já existem 5 pontos, tenta criar o arco dentário
                    if (this.$store.state.editor3D.contourPoints.length === 5) {
                        await this.$store.dispatch('editor3D/createDentalArch');
                    }
                    // Encerra a criação do arco dentário
                    await this.$store.dispatch('editor3D/endDentalArchCreation');
                } else {
                    // Inicia a criação do arco dentário
                    await this.$store.dispatch('editor3D/startDentalArchCreation', this.activeDentalArch);
                    // Verifica se já existem 4 pontos de contorno salvos
                    if (this.idealArchStartPoints[this.activeDentalArch].length === 5) {
                        // Se sim, assume que queremos ajustar o arco existente
                        // Atualiza os pontos de contorno no Vuex, se necessário
                        await this.$store.dispatch('editor3D/setContourPoints', this.idealArchStartPoints[this.activeDentalArch]);
                        // Chama a função para atualizar/visualizar o contorno diretamente
                        this.updateContour();
                    }
                }
            } else {
                this.statusMessage = this.$t('noArchSelected');
            }
        },
        async adjustArchScale(scaleType, scaleFactor) {
            if (!this.activeDentalArch || !this.idealArchStartPoints[this.activeDentalArch]) {
                return; // Verifica se a arcada está definida e se há pontos para trabalhar
            }

            const points = this.idealArchStartPoints[this.activeDentalArch];
            let newPoints;

            switch (scaleType) {
                case 'fullArch':
                    newPoints = this.scaleArchPoints(points, scaleFactor, scaleFactor);
                    break;
                case 'ends':
                    newPoints = this.scaleSpecificPoints(points, scaleFactor, [0, 4]); // Considerando 0-indexed e pontos 1 e 5
                    break;
                case 'midPoints':
                    newPoints = this.scaleSpecificPoints(points, scaleFactor, [1, 3]); // Considerando 0-indexed e pontos 2 e 4
                    break;
                case 'centerPoint':
                    newPoints = this.scaleSpecificPoints(points, scaleFactor, [2]); // Considerando 0-indexed e ponto 3
                    break;
                case 'endsLeft':
                    newPoints = this.scaleSpecificPoints(points, scaleFactor, [0]); // Considerando 0-indexed e pontos 1 e 5
                    break;
                case 'midPointsLeft':
                    newPoints = this.scaleSpecificPoints(points, scaleFactor, [1]); // Considerando 0-indexed e pontos 2 e 4
                    break;
                case 'endsRight':
                    newPoints = this.scaleSpecificPoints(points, scaleFactor, [4]); // Considerando 0-indexed e pontos 1 e 5
                    break;
                case 'midPointsRight':
                    newPoints = this.scaleSpecificPoints(points, scaleFactor, [3]); // Considerando 0-indexed e pontos 2 e 4
                    break;
                default:
                    return;
            }

            this.idealArchStartPoints[this.activeDentalArch] = newPoints;
            const curve = new THREE.CatmullRomCurve3(newPoints, false, 'catmullrom', 0.5);
            const curvePoints = curve.getPoints(256);
            this.idealArchPoints[this.activeDentalArch] = curvePoints;

            await this.$store.dispatch('editor3D/setContourPoints', newPoints);
            if (this.currentAction === 'creatingDentalArch')
                this.updateContour(); // Atualiza visualmente o arco
        },
        scaleArchPoints(points, scaleX, scaleZ) {
            return points.map(point => new THREE.Vector3(
                point.x * scaleX,
                point.y,
                point.z * scaleZ
            ));
        },
        scaleSpecificPoints(points, scale, indices) {
            return points.map((point, index) => {
                if (indices.includes(index)) {
                    return new THREE.Vector3(
                        point.x * scale,
                        point.y,
                        point.z * scale
                    );
                }
                return point;
            });
        },
        exportSTL() {
            if (!this.activeObject) {
                console.error('Não há objeto ativo selecionado para exportar.');
                return;
            }

            // Cria uma instância do STLExporter
            const exporter = new STLExporter();

            const options = { binary: true }

            // Exporta o activeObject como STL
            const stlString = exporter.parse(this.activeObject, options);

            // Cria um blob a partir da string STL
            const blob = new Blob([stlString], { type: 'application/octet-stream' });

            // Cria um link e dispara o download
            const link = document.createElement('a');
            link.style.display = 'none';
            document.body.appendChild(link); // Necessário para o Firefox

            // Cria um URL para o blob
            link.href = URL.createObjectURL(blob);

            // Define o nome do arquivo para download
            link.download = (this.activeObject.userData.name) ? `${this.activeObject.userData.name}.stl` : 'ortho-model.stl';

            // Dispara o download
            link.click();

            // Limpeza
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        },
        loadDentalMap(file) {
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    try {
                        const newDentalMap = JSON.parse(content);
                        // Usa o spread operator para combinar o conteúdo existente com o novo
                        try {
                            this.addEigenvectorsToDentalMap(newDentalMap);
                        } finally {
                            this.dentalMap = { ...this.dentalMap, ...newDentalMap };
                        }
                        this.executeActionWithLoading('createDentalMesh',
                            () => {
                                this.createDentalMeshGroup(newDentalMap);
                                this.createBoundingBoxMeshForTeeth(false)
                            }, 500);

                    } catch (error) {
                        console.error('Erro ao ler o arquivo JSON:', error);
                    }
                };
                reader.readAsText(file); // Lê o arquivo como texto
            }
        },
        addEigenvectorsToDentalMap(dentalMap) {
            Object.keys(dentalMap).forEach(toothNumber => {
                const toothData = dentalMap[toothNumber];
                const { vertices, centroid } = this.convertIndicesToVertices(this.activeObject, toothData.segmentedTriangleIndices);
                if (vertices.length > 0) {
                    // Executa a PCA nos vértices para obter os eigenvectors
                    const pca = new PCA(vertices);
                    const eigenvectors = pca.getEigenvectors();

                    // Calcula as dimensões da OBB para cada eigenvector
                    let dimensions = eigenvectors.data.map(eigenvector => this.calculateDimension(vertices, eigenvector));

                    // Atualiza o dentalMap com os eigenvectors, o centroide e as dimensões
                    toothData.eigenvectors = eigenvectors;
                    toothData.centroid = centroid; // Salva o centroide no dentalMap para uso futuro
                    toothData.dimensions = dimensions; // Salva as dimensões da OBB
                }
            });
        },
        calculateDimension(vertices, eigenvector) {
            let minProjection = Infinity;
            let maxProjection = -Infinity;

            // Converte o eigenvector para um objeto THREE.Vector3 se ainda não for um
            const v = new THREE.Vector3(eigenvector[0], eigenvector[1], eigenvector[2]).normalize();

            vertices.forEach(vertexArray => {
                // Converte cada vértice de array para THREE.Vector3
                const vertex = new THREE.Vector3(vertexArray[0], vertexArray[1], vertexArray[2]);

                // Calcula a projeção escalar do vértice no eigenvector
                const projection = vertex.dot(v);

                // Atualiza os valores mínimo e máximo
                minProjection = Math.min(minProjection, projection);
                maxProjection = Math.max(maxProjection, projection);
            });

            // Retorna a dimensão como a diferença entre o máximo e o mínimo
            return maxProjection - minProjection;
        },
        convertIndicesToVertices(activeObject, triangleIndices) {
            const positions = activeObject.geometry.attributes.position.array;
            let vertices = [];
            let sumX = 0, sumY = 0, sumZ = 0;

            // Extrair vértices baseando-se nos índices dos triângulos fornecidos
            triangleIndices.forEach(triangleIdx => {
                const baseIdx = triangleIdx * 9; // Cada triângulo tem 3 vértices, cada vértice tem 3 coordenadas (x, y, z)

                // Iterar sobre os 3 vértices de cada triângulo
                for (let i = 0; i < 3; i++) {
                    const vertexIdx = baseIdx + i * 3; // Calcula o índice do início de cada vértice no array de posições
                    // Extrai as coordenadas x, y, z de cada vértice e as adiciona ao array de vértices
                    const x = positions[vertexIdx];
                    const y = positions[vertexIdx + 1];
                    const z = positions[vertexIdx + 2];
                    vertices.push([x, y, z]);

                    // Acumula as somas para o cálculo do centroide
                    sumX += x;
                    sumY += y;
                    sumZ += z;
                }
            });

            // Calcula o centroide
            const numVertices = vertices.length;
            const centroid = [sumX / numVertices, sumY / numVertices, sumZ / numVertices];

            // Retorna tanto os vértices quanto o centroide
            return { vertices, centroid };
        },
        handleFileSelected(payload) {
            const { file, action } = payload;
            console.log('Arquivo selecionado:', file.name, 'Ação:', action);
            switch (action) {
                case 'loadSegmentation':
                    this.loadDentalMap(file);
                    break;
            }
        },
        findBorderMesh() {
            let targetMesh = null;
            this.scene.traverse((child) => {
                if (child.userData && child.userData.name === 'borderData') {
                    targetMesh = child;
                }
            });
            return targetMesh;
        },
        removeBorderMesh() {
            const meshToRemove = this.findBorderMesh(this.scene);

            this.removeObjectFromScene(meshToRemove)

            //console.table(meshToRemove)
            // if (meshToRemove) {
            //     // Remova o mesh da cena
            //     this.scene.remove(meshToRemove);

            //     // Libere a geometria e o material do mesh, se não for compartilhado
            //     if (meshToRemove.geometry) {
            //         meshToRemove.geometry.dispose();
            //     }
            //     if (meshToRemove.material) {
            //         if (Array.isArray(meshToRemove.material)) {
            //             meshToRemove.material.forEach(material => material.dispose());
            //         } else {
            //             meshToRemove.material.dispose();
            //         }
            //     }

            //     // Opcional: limpe outras propriedades, como texturas, se aplicável
            // }
        },
        toggleTeethOpacity() {
            const teeth = this.findTeethInScene();

            teeth.forEach(tooth => {

                if (tooth.visible && tooth.material.opacity === 1) {
                    tooth.material.opacity = 0.5;
                }

                else if (tooth.visible && tooth.material.opacity === 0.5) {
                    tooth.visible = false;
                }

                else if (!tooth.visible) {
                    tooth.visible = true;
                    tooth.material.opacity = 1;
                }

                tooth.material.transparent = true;
                tooth.material.needsUpdate = true;
            });

        },
        adjustTeethOpacity() {
            const teeth = this.findTeethInScene();

            teeth.forEach(tooth => {
                // Verifica se o dente é o dente selecionado
                if (this.selectedTooth && tooth.userData.toothNumber === this.selectedTooth) {
                    tooth.material.opacity = 0.80; // Mantém a opacidade total para o dente selecionado
                    //this.activeObject = tooth;
                } else {
                    tooth.material.opacity = 0.15; // Torna os outros dentes semi-transparentes
                }
                tooth.material.transparent = true; // Garante que a transparência seja ativada
                tooth.material.needsUpdate = true; // Informa ao Three.js para atualizar o material do dente
            });

            // Se nenhum dente estiver selecionado, restaura a opacidade de todos os dentes
            if (!this.selectedTooth) {
                teeth.forEach(tooth => {
                    tooth.material.opacity = 1; // Restaura a opacidade total
                    tooth.material.needsUpdate = true;
                });
            }
        },
        findTeethInScene(arch = '') {
            const teeth = [];
            this.scene.traverse(child => {
                if (child.userData.objectType === 'tooth') {
                    const toothNumber = child.userData.toothNumber;
                    if ((arch === '') ||
                        (arch === 'upper' && toothNumber < 31) ||
                        (arch === 'lower' && toothNumber >= 31))
                        teeth.push(child);
                }
            });
            return teeth;
        },
        createOrderedEdgePath(vertices, vertexToEdgesMap) {
            let orderedVertices = [];
            let currentVertex = vertices[0];
            orderedVertices.push(currentVertex);

            let visitedVertices = new Set();
            visitedVertices.add(`${currentVertex.x},${currentVertex.y},${currentVertex.z}`);

            while (orderedVertices.length < vertices.length) {
                let vertexKey = `${currentVertex.x},${currentVertex.y},${currentVertex.z}`;
                let edges = vertexToEdgesMap.get(vertexKey);

                if (!edges) {
                    console.error('No edges found for vertex:', vertexKey);
                    break;
                }

                let nextVertexKey = edges.filter(v => !visitedVertices.has(v))
                    .sort((a, b) => {
                        let aCoords = a.split(',').map(Number);
                        let bCoords = b.split(',').map(Number);
                        let aVertex = new THREE.Vector3(...aCoords);
                        let bVertex = new THREE.Vector3(...bCoords);
                        return currentVertex.distanceToSquared(aVertex) - currentVertex.distanceToSquared(bVertex);
                    })[0];

                if (!nextVertexKey) break; // Se não houver próximo vértice, interrompe o loop

                let nextVertexCoords = nextVertexKey.split(',').map(Number);
                let nextVertex = new THREE.Vector3(...nextVertexCoords);

                orderedVertices.push(nextVertex);
                visitedVertices.add(nextVertexKey);
                currentVertex = nextVertex;
            }

            return orderedVertices;
        },
        createVertexToEdgeMap(edgesGeometry) {
            const vertexToEdgeMap = new Map();
            const positionAttribute = edgesGeometry.attributes.position;
            const vertices = positionAttribute.array;

            // Como EdgesGeometry não possui índices, assumimos que cada par de pontos forma uma aresta
            for (let i = 0; i < vertices.length; i += 6) {
                const vertex1Key = `${vertices[i]},${vertices[i + 1]},${vertices[i + 2]}`;
                const vertex2Key = `${vertices[i + 3]},${vertices[i + 4]},${vertices[i + 5]}`;

                // Adiciona a aresta para o vértice 1
                if (!vertexToEdgeMap.has(vertex1Key)) {
                    vertexToEdgeMap.set(vertex1Key, new Set());
                }
                vertexToEdgeMap.get(vertex1Key).add(vertex2Key);

                // Adiciona a aresta para o vértice 2
                if (!vertexToEdgeMap.has(vertex2Key)) {
                    vertexToEdgeMap.set(vertex2Key, new Set());
                }
                vertexToEdgeMap.get(vertex2Key).add(vertex1Key);
            }

            // Convertendo os sets para arrays
            for (let [vertexKey, edgeSet] of vertexToEdgeMap) {
                vertexToEdgeMap.set(vertexKey, Array.from(edgeSet));
            }

            return vertexToEdgeMap;
        },
        drawImageInDiv(image2D, divId, clear = true) {
            // Obter o tamanho da imagem
            const width = image2D[0].length;
            const height = image2D.length;

            // Criar um elemento canvas
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            // Obter o contexto 2D do canvas
            const ctx = canvas.getContext('2d');

            // Criar uma ImageData para o canvas
            const imageData = ctx.createImageData(width, height);
            const data = imageData.data;

            // Preencher o ImageData com os dados da matriz image2D
            for (let y = 0; y < height; y++) {
                for (let x = 0; x < width; x++) {
                    const index = (y * width + x) * 4; // Índice no array data
                    const intensity = image2D[y][x];  // Intensidade do pixel

                    if (intensity !== 255) {
                        // Mapear a intensidade para uma cor (de azul a vermelho)
                        let red = Math.floor(intensity);        // Mais intensidade -> mais vermelho
                        let green = Math.floor(intensity) * 0.25;                          // Sem contribuição de verde
                        let blue = Math.floor(255 - intensity); // Menos intensidade -> mais azul

                        if (intensity < 200) {
                            red = Math.floor(intensity);
                            green = Math.floor(intensity);
                            blue = Math.floor(intensity);
                        }

                        // Definir o pixel com a cor calculada
                        data[index] = red;       // Red
                        data[index + 1] = green; // Green
                        data[index + 2] = blue;  // Blue
                        data[index + 3] = 255;   // Alpha (opacidade)
                    } else {
                        // Definir o pixel com a cor calculada
                        data[index] = 255;       // Red
                        data[index + 1] = 255; // Green
                        data[index + 2] = 255;  // Blue
                        data[index + 3] = 255;   // Alpha (opacidade)
                    }
                }
            }

            // Desenhar o ImageData no canvas
            ctx.putImageData(imageData, 0, 0);

            // Inserir o canvas na div com o ID especificado
            const div = document.getElementById(divId);
            if (clear) div.innerHTML = ''; // Limpar conteúdo anterior (se houver)
            div.appendChild(canvas);
        }
        ,
        generate2DProjection(meshes, isUpper = false) {
            // Configurações iniciais
            const width = 240;   // Largura da imagem 2D
            const height = 240;  // Altura da imagem 2D

            // Criar uma matriz para representar a imagem 2D (preenchida inicialmente com 255)
            let image2D = new Array(height).fill(255).map(() => new Array(width).fill(255));

            // Função para converter coordenadas do mundo 3D (X, Z) para coordenadas de pixels (u, v)
            function worldToImageCoords(x, z, minX, maxX, minZ, maxZ) {
                const u = Math.floor(((x - minX) / (maxX - minX)) * (width - 1));
                let v = Math.floor(((z - minZ) / (maxZ - minZ)) * (height - 1));
                if (isUpper) {
                    // Inverter a coordenada Z se a arcada for superior
                    v = height - 1 - v;
                }
                return { u, v };
            }

            let minX = Infinity, maxX = -Infinity;
            let minZ = Infinity, maxZ = -Infinity;
            let minY = Infinity, maxY = -Infinity;

            // Primeiro, encontrar os limites dos meshes (bounding box)
            meshes.forEach(mesh => {
                const position = mesh.geometry.attributes.position.array;
                for (let i = 0; i < position.length; i += 3) {
                    const x = position[i] + mesh.position.x;
                    const y = position[i + 1] + mesh.position.y;
                    const z = position[i + 2] + mesh.position.z;
                    if (x < minX) minX = x;
                    if (x > maxX) maxX = x;
                    if (y < minY) minY = y;
                    if (y > maxY) maxY = y;
                    if (z < minZ) minZ = z;
                    if (z > maxZ) maxZ = z;
                }
            });

            // Agora, projetar cada vértice na matriz 2D
            meshes.forEach(mesh => {
                const position = mesh.geometry.attributes.position.array;
                for (let i = 0; i < position.length; i += 3) {
                    const x = position[i] + mesh.position.x;
                    const y = position[i + 1] + mesh.position.y;  // Altura
                    let z = position[i + 2] + mesh.position.z;

                    // Converter coordenadas 3D para 2D (X, Z -> u, v)
                    const { u, v } = worldToImageCoords(x, z, minX, maxX, minZ, maxZ);

                    // Usar a altura (Y) para determinar a cor/intensidade
                    const intensity = (isUpper)
                        ? Math.floor((maxY - y) / (maxY - minY) * 255)  // Inverter a intensidade para a arcada superior
                        : Math.floor((y - minY) / (maxY - minY) * 255);  // Manter a lógica normal para a arcada inferior


                    // Atualizar o pixel na imagem 2D
                    image2D[v][u] = Math.min(image2D[v][u], intensity);  // Evitar sobrescrever com valores menores
                }
            });

            return image2D;
        },
        tooth3DReconstruction__(activeGeometry, toothData) {
    // Cria a geometria de borda a partir da geometria ativa
    const edges = new THREE.EdgesGeometry(activeGeometry, 90);
    const positionsArray = edges.attributes.position.array;

    // Converte o array plano em um array de vetores Vector3
    let vertices = [];
    for (let i = 0; i < positionsArray.length; i += 3) {
        vertices.push(new THREE.Vector3(positionsArray[i], positionsArray[i + 1], positionsArray[i + 2]));
    }

    // Obtém vértices únicos
    const uniqueVertices = this.uniqueVerticesFunction(vertices);

    // Calcula o centro de massa dos vértices únicos
    const centerOfMass = new THREE.Vector3();
    uniqueVertices.forEach(vertex => centerOfMass.add(vertex));
    centerOfMass.divideScalar(uniqueVertices.length);

    const orderedVertices = this.connectEdgePoints(uniqueVertices);

    // Cria a esfera para visualizar o centro de massa
    const centerSphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
    const centerSphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const centerSphere = new THREE.Mesh(centerSphereGeometry, centerSphereMaterial);
    centerSphere.position.copy(centerOfMass);

    let arcType = (this.activeObject.userData.modelType === 'mandibular') ? 'lower' : 'upper';
    let distance = this.findLargestDistanceWithFixedHeight(vertices, arcType);

    toothData.centerOfMass = centerOfMass.clone();
    toothData.orderedVertices = orderedVertices;
    toothData.distance = distance.largestDistance;
    if (!toothData.medial) toothData.medial = distance.point1;
    if (!toothData.distal) toothData.distal = distance.point2;

    // Verifica se a geometria ativa tem um atributo normal
    if (activeGeometry.attributes.normal) {
        const normals = activeGeometry.attributes.normal.array;
        let averageNormal = new THREE.Vector3(0, 0, 0);

        // Soma todas as normais para encontrar a média
        for (let i = 0; i < normals.length; i += 3) {
            averageNormal.add(new THREE.Vector3(normals[i], normals[i + 1], normals[i + 2]));
        }

        // Normaliza a média
        averageNormal.divideScalar(normals.length / 3).normalize();

        toothData.averageNormal = averageNormal.clone();

        // Calcula a direção oposta
        const oppositeDirection = averageNormal.negate();

        // Define a distância de deslocamento
        const displacementDistance = 4;

        // Calcula o centro de massa deslocado
        const displacedCenterOfMass = new THREE.Vector3(
            centerSphere.position.x + oppositeDirection.x * displacementDistance,
            centerSphere.position.y + oppositeDirection.y * displacementDistance,
            centerSphere.position.z + oppositeDirection.z * displacementDistance
        );

        // Visualiza o centro de massa deslocado com uma esfera amarela
        const displacedSphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
        const displacedSphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        const displacedSphere = new THREE.Mesh(displacedSphereGeometry, displacedSphereMaterial);
        displacedSphere.position.copy(displacedCenterOfMass);

        // Agora, passamos os dados para a triangulação Delaunay
        // Você precisará implementar ou chamar a função de triangulação delaunay aqui
        const delaunayGeometry = this.createDelaunayGeometry(orderedVertices, displacedCenterOfMass);

        // Retorna a geometria criada pelo Delaunay
        return delaunayGeometry;
    }
}

        ,
        // eslint-disable-next-line no-unused-vars
        tooth3DReconstruction(activeGeometry, toothData, toothNumber) {

            // Create edge geometry from the active object's geometry
            const edges = new THREE.EdgesGeometry(activeGeometry, 90);
            const positionsArray = edges.attributes.position.array;

            // Convert the flat array into an array of Vector3
            let vertices = [];
            for (let i = 0; i < positionsArray.length; i += 3) {
                vertices.push(new THREE.Vector3(positionsArray[i], positionsArray[i + 1], positionsArray[i + 2]));
            }

            // Obtain unique vertices
            const uniqueVertices = this.uniqueVerticesFunction(vertices);

            // Calculate the center of mass of the unique vertices
            const centerOfMass = new THREE.Vector3();
            uniqueVertices.forEach(vertex => centerOfMass.add(vertex));
            centerOfMass.divideScalar(uniqueVertices.length);

            const orderedVertices = this.connectEdgePoints(uniqueVertices);

            // Visualize the center of mass with a yellow sphere
            const centerSphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
            const centerSphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            const centerSphere = new THREE.Mesh(centerSphereGeometry, centerSphereMaterial);
            centerSphere.position.copy(centerOfMass);

            let arcType = (this.activeObject.userData.modelType === 'mandibular') ? 'lower' : 'upper';
            let distance = this.findLargestDistanceWithFixedHeight(vertices, arcType);

            toothData.centerOfMass = centerOfMass.clone();
            toothData.orderedVertices = orderedVertices;
            toothData.distance = distance.largestDistance;
            if (!toothData.medial) toothData.medial = distance.point1;
            if (!toothData.distal) toothData.distal = distance.point2;

            // Ensure the active object's geometry has a normal attribute
            if (activeGeometry.attributes.normal) {
                const normals = activeGeometry.attributes.normal.array;
                let averageNormal = new THREE.Vector3(0, 0, 0);

                // Sum all normals to find the average
                for (let i = 0; i < normals.length; i += 3) {
                    averageNormal.add(new THREE.Vector3(normals[i], normals[i + 1], normals[i + 2]));
                }

                // Normalize to get the average direction
                averageNormal.divideScalar(normals.length / 3).normalize();

                toothData.averageNormal = averageNormal.clone();

                // Calculate the opposite direction
                const oppositeDirection = averageNormal.negate();

                // Set the displacement distance
                const displacementDistance = 4;

                // Calculate the displaced center of mass
                const displacedCenterOfMass = new THREE.Vector3(
                    centerSphere.position.x + oppositeDirection.x * displacementDistance,
                    centerSphere.position.y + oppositeDirection.y * displacementDistance,
                    centerSphere.position.z + oppositeDirection.z * displacementDistance
                );

                // Visualize the displaced center of mass with a yellow sphere
                const displacedSphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
                const displacedSphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
                const displacedSphere = new THREE.Mesh(displacedSphereGeometry, displacedSphereMaterial);
                displacedSphere.position.copy(displacedCenterOfMass);

                const seam3D = this.create3DSeamDelaunay(orderedVertices, orderedVertices.map(() => displacedCenterOfMass.clone()));
                seam3D.computeVertexNormals();

                const seams3D = this.mergeGeometries([activeGeometry, seam3D]);
                return seams3D
            }
        },
        tooth3DReconstruction_(activeGeometry, toothData, toothNumber) {
            // Obter os vértices da activeGeometry
            const activePositionsArray = activeGeometry.attributes.position.array;
            let activeVertices = [];
            for (let i = 0; i < activePositionsArray.length; i += 3) {
                activeVertices.push(new THREE.Vector3(activePositionsArray[i], activePositionsArray[i + 1], activePositionsArray[i + 2]));
            }

            // Create edge geometry from the active object's geometry
            const edges = new THREE.EdgesGeometry(activeGeometry, 90);
            const edgePositionsArray = edges.attributes.position.array;

            // Convert the flat array into an array of Vector3
            let edgeVertices = [];
            for (let i = 0; i < edgePositionsArray.length; i += 3) {
                edgeVertices.push(new THREE.Vector3(edgePositionsArray[i], edgePositionsArray[i + 1], edgePositionsArray[i + 2]));
            }

            // Obtain unique vertices from edges
            const uniqueEdgeVertices = this.uniqueVerticesFunction(edgeVertices);

            // Calculate the center of mass of the unique edge vertices
            const centerOfMass = new THREE.Vector3();
            uniqueEdgeVertices.forEach(vertex => centerOfMass.add(vertex));
            centerOfMass.divideScalar(uniqueEdgeVertices.length);

            // Order edge vertices to form a connected edge path
            const orderedEdgeVertices = this.connectEdgePoints(uniqueEdgeVertices);

            // Visualize the center of mass with a yellow sphere
            const centerSphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
            const centerSphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            const centerSphere = new THREE.Mesh(centerSphereGeometry, centerSphereMaterial);
            centerSphere.position.copy(centerOfMass);

            let arcType = (this.activeObject.userData.modelType === 'mandibular') ? 'lower' : 'upper';
            let distance = this.findLargestDistanceWithFixedHeight(edgeVertices, arcType);

            this.dentalMap[toothNumber].centerOfMass = centerOfMass.clone();
            this.dentalMap[toothNumber].orderedVertices = orderedEdgeVertices;
            this.dentalMap[toothNumber].distance = distance.largestDistance;
            if (!this.dentalMap[toothNumber].medial) this.dentalMap[toothNumber].medial = distance.point1;
            if (!this.dentalMap[toothNumber].distal) this.dentalMap[toothNumber].distal = distance.point2;

            // Ensure the active object's geometry has a normal attribute
            if (activeGeometry.attributes.normal) {
                const normals = activeGeometry.attributes.normal.array;
                let averageNormal = new THREE.Vector3(0, 0, 0);

                // Sum all normals to find the average
                for (let i = 0; i < normals.length; i += 3) {
                    averageNormal.add(new THREE.Vector3(normals[i], normals[i + 1], normals[i + 2]));
                }

                // Normalize to get the average direction
                averageNormal.divideScalar(normals.length / 3).normalize();

                this.dentalMap[toothNumber].averageNormal = averageNormal.clone();

                // Calculate the opposite direction
                const oppositeDirection = averageNormal.negate();

                // Set the displacement distance
                const displacementDistance = 4;

                // Calculate the displaced center of mass
                const displacedCenterOfMass = new THREE.Vector3(
                    centerSphere.position.x + oppositeDirection.x * displacementDistance,
                    centerSphere.position.y + oppositeDirection.y * displacementDistance,
                    centerSphere.position.z + oppositeDirection.z * displacementDistance
                );

                // Visualize the displaced center of mass with a yellow sphere
                const displacedSphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
                const displacedSphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
                const displacedSphere = new THREE.Mesh(displacedSphereGeometry, displacedSphereMaterial);
                displacedSphere.position.copy(displacedCenterOfMass);

                // Combine original vertices, edge vertices, and the displaced center of mass for Delaunay triangulation
                const delaunayVertices = activeVertices.concat(orderedEdgeVertices).concat([displacedCenterOfMass]);
                const seam3D = this.createDelaunayGeometry(delaunayVertices);
                seam3D.computeVertexNormals();

                return seam3D;
            }
        },
        createDelaunayGeometry(vertices) {
            // Convert Vector3 vertices to an array of points for Delaunay
            const points = vertices.map(vertex => [vertex.x, vertex.y, vertex.z]);

            // Perform Delaunay triangulation
            const delaunay = Delaunator.from(points);
            const geometry = new THREE.BufferGeometry();

            // Create vertices array for BufferGeometry
            const flatVertices = [];
            points.forEach(point => {
                flatVertices.push(point[0], point[1], point[2]);
            });

            // Create faces (triangles) from Delaunay triangulation
            const indices = [];
            for (let i = 0; i < delaunay.triangles.length; i += 3) {
                indices.push(delaunay.triangles[i], delaunay.triangles[i + 1], delaunay.triangles[i + 2]);
            }

            // Set vertices and indices to geometry
            geometry.setIndex(indices);
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(flatVertices, 3));

            return geometry;
        },
        findClosestVertexGums(toothVertex, gums) {
            let closestGumVertex = null;
            let shortestDistance = Infinity;

            // Supondo que gums seja uma coleção ou um único objeto de malha
            // Se gums for uma coleção, você precisaria iterar sobre ela

            // Aqui, assumimos gums como um único objeto Mesh para simplificar
            const vertices = gums.geometry.attributes.position.array;
            const gumWorldMatrix = gums.matrixWorld; // A matriz de transformação mundial da gengiva

            for (let i = 0; i < vertices.length; i += 3) {
                // Cria um Vector3 para o vértice atual na gengiva
                let gumVertex = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);

                // Converte o vértice da gengiva para o espaço mundial
                gumVertex.applyMatrix4(gumWorldMatrix);

                // Calcula a distância entre o vértice do dente (já no espaço mundial) e o vértice atual da gengiva
                let distance = toothVertex.distanceTo(gumVertex);

                // Se for a distância mais curta até agora, armazena este vértice e distância
                if (distance < shortestDistance) {
                    shortestDistance = distance;
                    closestGumVertex = gumVertex; // Aqui, gumVertex já está no espaço mundial
                }
            }

            return closestGumVertex;
        },
        makeTeethGumsLines() {
            const gums = this.findGumInScene(); // Supõe que já tenha o objeto da gengiva
            const teeth = this.findTeethInScene(); // Supõe que retorna um array de objetos dentes

            // Criar um BufferGeometry para armazenar as linhas
            let linesGeometry = new THREE.BufferGeometry();
            let positions = [];

            // Processar cada dente
            teeth.forEach(tooth => {

                // Criar edgeGeometry para cada dente
                const edgeGeometry = new THREE.EdgesGeometry(tooth.geometry, 90);
                const positionsArray = edgeGeometry.attributes.position.array;

                // Converter o array plano em um array de Vector3
                let vertices = [];
                for (let i = 0; i < positionsArray.length; i += 3) {
                    vertices.push(new THREE.Vector3(positionsArray[i], positionsArray[i + 1], positionsArray[i + 2]));
                }

                const uniqueVertices = this.uniqueVerticesFunction(vertices); // Supõe que filtra vértices duplicados

                // Para cada vértice único no dente, encontrar o vértice mais próximo na gengiva
                uniqueVertices.forEach(toothVertex => {
                    toothVertex.applyMatrix4(tooth.matrixWorld);
                    let closestGumVertex = this.findClosestVertexGums(toothVertex, gums);

                    // Adicionar os pontos à lista de posições (linhas)
                    positions.push(toothVertex.x, toothVertex.y, toothVertex.z);
                    positions.push(closestGumVertex.x, closestGumVertex.y, closestGumVertex.z);
                });
            });

            // Cria atributo de posição para a geometria das linhas
            linesGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

            // Criar material e linha para visualização
            let lineMaterial = new THREE.LineBasicMaterial({ color: 0xffff00 });
            let lineMesh = new THREE.LineSegments(linesGeometry, lineMaterial);
            lineMesh.userData.name = 'teethGumsLines';

            // Remover mesh anterior se ele existir
            let existingLineMesh = this.scene.getObjectByName('teethGumsLines');
            if (existingLineMesh) {
                this.scene.remove(existingLineMesh);
            }

            // Adicionar linhas à cena
            this.scene.add(lineMesh);
        },
        getTeethGumsCloudPoints() {
            const gums = this.findGumInScene(); // Supõe que já tenha o objeto da gengiva
            const teeth = this.findTeethInScene(); // Supõe que retorna um array de objetos dentes

            let positions = [];

            // Processar cada dente
            teeth.forEach(tooth => {

                // Criar edgeGeometry para cada dente
                const edgeGeometry = new THREE.EdgesGeometry(tooth.geometry, 90);
                const positionsArray = edgeGeometry.attributes.position.array;

                // Converter o array plano em um array de Vector3
                let vertices = [];
                for (let i = 0; i < positionsArray.length; i += 3) {
                    vertices.push(new THREE.Vector3(positionsArray[i], positionsArray[i + 1], positionsArray[i + 2]));
                }

                const uniqueVertices = this.uniqueVerticesFunction(vertices); // Supõe que filtra vértices duplicados

                // Para cada vértice único no dente, encontrar o vértice mais próximo na gengiva
                uniqueVertices.forEach(toothVertex => {
                    toothVertex.applyMatrix4(tooth.matrixWorld);
                    let closestGumVertex = this.findClosestVertexGums(toothVertex, gums);

                    // Adicionar os pontos à lista de posições (linhas)
                    positions.push(toothVertex.x, toothVertex.y, toothVertex.z);
                    positions.push(closestGumVertex.x, closestGumVertex.y, closestGumVertex.z);
                });
            });

            return positions;

        },
        findClosestVerticesGums(toothVertex, gums, numVertices = 3) {
            let distances = [];

            const vertices = gums.attributes.position.array;
            //const gumWorldMatrix = gums.matrixWorld;

            // Calcula as distâncias de todos os vértices da gengiva ao vértice do dente
            for (let i = 0; i < vertices.length; i += 3) {
                let gumVertex = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
                //gumVertex.applyMatrix4(gumWorldMatrix);
                let distance = toothVertex.distanceTo(gumVertex);

                distances.push({ distance, gumVertex });
            }

            // Ordena pelo menor distância e pega os numVertices mais próximos
            distances.sort((a, b) => a.distance - b.distance);

            // Retorna apenas os vértices, sem as distâncias, dos numVertices mais próximos
            return distances.slice(0, numVertices).map(d => d.gumVertex);
        },
        makeDynamicGum() {
            const gums = this.findGumInScene(); // Supõe que já tenha o objeto da gengiva
            const teeth = this.findTeethInScene(); // Supõe que retorna um array de objetos dentes
            let dynamicGumGeometry = new THREE.BufferGeometry(); // Geometria acumuladora

            const gumsEdges = new THREE.EdgesGeometry(gums.geometry, 25);

            // Encontrar o grupo que contém as gengivas
            const dentalGroup = this.scene.children.find(child =>
                child.isGroup && child.children.some(obj => obj.userData.objectType === 'gums'));

            if (!dentalGroup) {
                console.error('Nenhum grupo adequado encontrado que contenha as gengivas.');
                return; // Ou criar um novo grupo, dependendo da necessidade.
            }

            // Verifica se dynamicGum já existe dentro do grupo
            let dynamicGum = dentalGroup.getObjectByName('dynamicGum');
            if (!dynamicGum) {
                dynamicGum = new THREE.Mesh(dynamicGumGeometry, new THREE.MeshPhongMaterial({
                    color: 0xffc0cb,
                    specular: 0x333366,
                    shininess: 100,
                    side: THREE.DoubleSide,
                    transparent: true,
                    opacity: 1,
                }));
                dynamicGum.userData.name = 'dynamicGum';
                dynamicGum.userData.objectType = 'dynamic';
                dynamicGum.name = 'dynamicGum';
                dentalGroup.add(dynamicGum); // Adiciona ao grupo em vez de à cena
            } else {
                // Se existir, limpa a geometria existente para reuso
                dynamicGum.geometry.dispose();
                dynamicGum.geometry = dynamicGumGeometry;
            }

            let allGeometries = [];

            teeth.forEach(tooth => {
                let positions = []; // Reinicia para cada dente

                // Criar edgeGeometry para cada dente
                const edgeGeometry = new THREE.EdgesGeometry(tooth.geometry, 90);
                const positionsArray = edgeGeometry.attributes.position.array;

                // Converter o array plano em um array de Vector3
                let vertices = [];
                for (let i = 0; i < positionsArray.length; i += 3) {
                    vertices.push(new THREE.Vector3(positionsArray[i], positionsArray[i + 1], positionsArray[i + 2]));
                }

                const uniqueVertices = this.uniqueVerticesFunction(vertices); // Supõe que filtra vértices duplicados

                // Para cada vértice único no dente, encontrar o vértice mais próximo na gengiva
                uniqueVertices.forEach(toothVertex => {
                    toothVertex.applyMatrix4(tooth.matrixWorld);
                    let closestGumVertex = this.findClosestVerticesGums(toothVertex, gumsEdges, 30);

                    // Processar cada vértice próximo retornado
                    closestGumVertex.forEach(closestGumVertex => {
                        // Adiciona o vértice do dente e o vértice da gengiva ao array positions
                        positions.push(toothVertex.x, toothVertex.y, toothVertex.z);
                        positions.push(closestGumVertex.x, closestGumVertex.y, closestGumVertex.z);
                    });
                });

                // Após coletar 'positions', converte para um array de THREE.Vector3
                let vectorArray = [];
                for (let i = 0; i < positions.length; i += 6) { // Incremento de 6 porque você adiciona dois vértices por vez (dente e gengiva)
                    vectorArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
                    vectorArray.push(new THREE.Vector3(positions[i + 3], positions[i + 4], positions[i + 5]));
                }


                const geometry = this.delaunayTriangulation(vectorArray); // Supondo que esta função retorne uma BufferGeometry
                if (geometry) {
                    allGeometries.push(geometry);
                }
            });

            // Após processar todos os dentes, mescla todas as geometrias
            if (allGeometries.length > 0) {
                const mergedGeometry = BufferGeometryUtils.mergeGeometries(allGeometries, false);
                dynamicGum.geometry = mergedGeometry;
                dynamicGum.geometry.computeVertexNormals();
            }

        },
        findClosestVertexTeeth(gumVertex, teeth) {
            let closestVertex = null;
            let shortestDistance = Infinity;

            teeth.forEach(tooth => {
                const vertices = tooth.geometry.attributes.position.array;
                for (let i = 0; i < vertices.length; i += 3) {
                    // Create a Vector3 for the current vertex on the tooth
                    let toothVertex = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);

                    // Convert the tooth vertex to world space if necessary
                    toothVertex.applyMatrix4(tooth.matrixWorld);

                    // Calculate the distance between the gum vertex and the current tooth vertex
                    let distance = gumVertex.distanceTo(toothVertex);

                    // If it's the shortest distance so far, store this vertex and distance
                    if (distance < shortestDistance) {
                        shortestDistance = distance;
                        closestVertex = toothVertex;
                    }
                }
            });

            return { closestVertex: closestVertex, shortestDistance: shortestDistance };
        },
        makeGumsTeethLines() {
            const gums = this.findGumInScene();
            const teeth = this.findTeethInScene();

            const edgeGeometry = new THREE.EdgesGeometry(gums.geometry, 90);
            const positionsArray = edgeGeometry.attributes.position.array;

            // Convert the flat array into an array of Vector3
            let vertices = [];
            for (let i = 0; i < positionsArray.length; i += 3) {
                vertices.push(new THREE.Vector3(positionsArray[i], positionsArray[i + 1], positionsArray[i + 2]));
            }

            const uniqueVertices = this.uniqueVerticesFunction(vertices);

            // Criar um BufferGeometry para armazenar as linhas
            let linesGeometry = new THREE.BufferGeometry();
            let positions = [];

            // Para cada vértice único na gengiva, encontrar o vértice mais próximo em todos os dentes
            uniqueVertices.forEach(gumVertex => {
                const nearVertex = this.findClosestVertexTeeth(gumVertex, teeth);
                let closestToothVertex = nearVertex.closestVertex;

                if (nearVertex.shortestDistance < 3) {
                    // Adicionar os pontos à lista de posições (linhas)
                    positions.push(gumVertex.x, gumVertex.y, gumVertex.z);
                    positions.push(closestToothVertex.x, closestToothVertex.y, closestToothVertex.z);
                }

            });

            // Cria atributo de posição para a geometria das linhas
            linesGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

            // Criar material e linha para visualização
            let lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
            let lineMesh = new THREE.LineSegments(linesGeometry, lineMaterial);
            lineMesh.userData.name = 'gumsTeethLines';

            // Remover mesh anterior se ele existir
            let existingLineMesh = this.scene.getObjectByName('gumsTeethLines');
            if (existingLineMesh) {
                this.scene.remove(existingLineMesh);
            }

            // Adicionar linhas à cena
            this.scene.add(lineMesh);

        },
        applyDelaunayTriangulation() {
            const gums = this.findGumInScene(); // Encontra a gengiva na cena

            // Extrai os vértices da geometria atual da gengiva
            //const edgeGeometry = new THREE.EdgesGeometry(gums.geometry,1);
            const verticesArray = gums.geometry.attributes.position.array;
            let vectorArray = [];
            for (let i = 0; i < verticesArray.length; i += 3) {
                vectorArray.push(new THREE.Vector3(verticesArray[i], verticesArray[i + 1], verticesArray[i + 2]));
            }

            // Remove vértices duplicados
            let uniqueVertices = this.uniqueVerticesFunction(vectorArray);

            // Converte os vértices únicos de volta para o formato [x, z], assegurando que são apenas números
            const points = uniqueVertices.reduce((acc, v) => {
                const x = parseFloat(v.x);
                const z = parseFloat(v.z);
                if (!isNaN(x) && !isNaN(z)) {
                    acc.push(x, z);
                }
                return acc;
            }, []);

            // Certifica-se de que todos os pontos são numéricos
            if (points.length > 0 && points.every(val => typeof val === 'number')) {
                try {
                    // Realiza a triangulação de Delaunay
                    const delaunay = new Delaunator(points);
                    const indices = delaunay.triangles; // Já é um Uint32Array

                    // Prepara um array de Float32Array para a nova geometria
                    const newVertices = [];
                    uniqueVertices.forEach(v => {
                        if (!isNaN(v.x) && !isNaN(v.y) && !isNaN(v.z)) {
                            newVertices.push(v.x, v.y, v.z);
                        }
                    });

                    // Cria uma nova geometria com os índices da triangulação
                    const newGeometry = new THREE.BufferGeometry();
                    newGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(newVertices), 3));
                    newGeometry.setIndex(Array.from(indices)); // Transforma o Uint32Array em um array regular, se necessário
                    newGeometry.computeVertexNormals(); // Recalcula as normais para iluminação correta

                    // Atualiza a geometria do objeto de gengiva com a nova geometria
                    gums.geometry.dispose(); // Libera a memória da geometria antiga
                    gums.geometry = newGeometry;
                } catch (error) {
                    console.error('Erro ao aplicar triangulação de Delaunay:', error);
                }
            } else {
                console.error('Array de pontos contém valores não numéricos ou está vazio.');
            }
        },
        convertToDelaunayInput(vertices) {
            const points = [];
            vertices.forEach(v => {
                const x = parseFloat(v.x);
                const z = parseFloat(v.z); // Supondo que você queira usar x e z para a triangulação
                if (!isNaN(x) && !isNaN(z)) {
                    points.push(x, z);
                }
            });
            return points;
        },
        removeLargeTriangles(geometry, maxPerimeter) {
            const vertices = geometry.attributes.position.array;
            const indices = geometry.index.array;
            const newIndices = [];

            for (let i = 0; i < indices.length; i += 3) {
                const index1 = indices[i] * 3;
                const index2 = indices[i + 1] * 3;
                const index3 = indices[i + 2] * 3;

                const v1 = new THREE.Vector3(vertices[index1], vertices[index1 + 1], vertices[index1 + 2]);
                const v2 = new THREE.Vector3(vertices[index2], vertices[index2 + 1], vertices[index2 + 2]);
                const v3 = new THREE.Vector3(vertices[index3], vertices[index3 + 1], vertices[index3 + 2]);

                const perimeter = v1.distanceTo(v2) + v2.distanceTo(v3) + v3.distanceTo(v1);

                if (perimeter <= maxPerimeter) {
                    newIndices.push(indices[i], indices[i + 1], indices[i + 2]);
                }
            }

            geometry.setIndex(newIndices);
            geometry.computeVertexNormals(); // Recalculate normals if needed
        },
        removeLargeTrianglesRaycaster(geometry, maxPerimeter, dentalArchMesh) {
            const vertices = geometry.attributes.position.array;
            const indices = geometry.index.array;
            dentalArchMesh.geometry.computeBoundsTree();
            const newIndices = [];
            const raycaster = new THREE.Raycaster();
            raycaster.firstHitOnly = true;
            const directionDown = new THREE.Vector3(0, -1, 0);
            const directionUp = new THREE.Vector3(0, 1, 0);
            const centroid = new THREE.Vector3(); // Centróide reutilizável

            for (let i = 0; i < indices.length; i += 3) {
                const index1 = indices[i] * 3;
                const index2 = indices[i + 1] * 3;
                const index3 = indices[i + 2] * 3;

                const v1 = new THREE.Vector3(vertices[index1], vertices[index1 + 1], vertices[index1 + 2]);
                const v2 = new THREE.Vector3(vertices[index2], vertices[index2 + 1], vertices[index2 + 2]);
                const v3 = new THREE.Vector3(vertices[index3], vertices[index3 + 1], vertices[index3 + 2]);

                const perimeter = v1.distanceTo(v2) + v2.distanceTo(v3) + v3.distanceTo(v1);
                centroid.set((v1.x + v2.x + v3.x) / 3, (v1.y + v2.y + v3.y) / 3, (v1.z + v2.z + v3.z) / 3);

                if (perimeter > maxPerimeter) {
                    raycaster.set(centroid, directionDown);
                    let intersectsDown = raycaster.intersectObject(dentalArchMesh);
                    raycaster.set(centroid, directionUp);
                    let intersectsUp = raycaster.intersectObject(dentalArchMesh);

                    if (intersectsDown.length === 0 && intersectsUp.length === 0) {
                        continue; // Não adicionar ao novo índice
                    }
                }

                newIndices.push(indices[i], indices[i + 1], indices[i + 2]);
            }

            geometry.setIndex(newIndices);
            geometry.computeVertexNormals();
        },
        delaunayTriangulation(verticesArray) {
            const points = this.convertToDelaunayInput(verticesArray);

            if (points.length > 0 && points.every(val => typeof val === 'number')) {
                try {
                    const delaunay = new Delaunator(points);
                    const indices = delaunay.triangles;

                    const newVertices = [];
                    verticesArray.forEach(v => {
                        if (!isNaN(v.x) && !isNaN(v.y) && !isNaN(v.z)) {
                            newVertices.push(v.x, v.y, v.z);
                        }
                    });

                    const newGeometry = new THREE.BufferGeometry();
                    newGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(newVertices), 3));
                    newGeometry.setIndex(Array.from(indices));
                    //newGeometry.computeVertexNormals();

                    return newGeometry;
                } catch (error) {
                    console.error('Erro ao aplicar triangulação de Delaunay:', error);
                    return null;
                }
            } else {
                console.error('Array de pontos contém valores não numéricos ou está vazio.');
                return null;
            }
        },
        performAction(action) {
            console.table(action)
            switch (action) {
                case 'createDentalMesh':

                    this.executeActionWithLoading('createDentalMesh',
                        () => {
                            if (this.activeObject) {
                                const object = this.scene.children.find(child => child.uuid === this.activeObject.uuid);
                                object.visible = !object.visible;
                                this.$refs.progressDialog.showProgressDialog("Executando Segmentação. Aguarde...", 10)
                                setTimeout(() => { this.gumSearch(); }, 1000)
                                //this.createDentalMeshGroup(this.dentalMap);
                                //this.scanDentalMapAndStartWorker() 
                            }
                        }, 250);
                    break;
                case 'toggleObjectVisibility':
                    if (this.activeObject) {
                        const object = this.scene.children.find(child => child.uuid === this.activeObject.uuid);
                        object.visible = !object.visible;
                        if (this.activeObject.isTransformControls) this.transformControl.detach();

                    }
                    break;
                case 'toggleGums': {
                    const gumsList = this.findGumsInScene(); // Isso agora é uma lista
                    const dynamicGumsList = this.findDynamicGumInScene(); // Supondo que isso também possa ser uma lista

                    // Verifica se a lista de gengivas não está vazia
                    if (gumsList.length) {
                        gumsList.forEach(gums => {
                            // Se os gums estiverem visíveis e com opacidade total, torná-los semi-transparentes
                            if (gums.visible && gums.material.opacity === 1) {
                                gums.material.opacity = 0.5;
                            }
                            // Se os gums estiverem visíveis e semi-transparentes, torná-los invisíveis
                            else if (gums.visible && gums.material.opacity === 0.5) {
                                gums.visible = false;
                            }
                            // Se os gums estiverem invisíveis, torná-los visíveis com opacidade total
                            else if (!gums.visible) {
                                gums.visible = true;
                                gums.material.opacity = 1;
                            }
                            gums.material.needsUpdate = true; // Garante que o Three.js atualize o material
                        });

                        // Aplicar a mesma lógica para gengivas dinâmicas, se existirem
                        if (dynamicGumsList && dynamicGumsList.length) {
                            dynamicGumsList.forEach(dynamic => {
                                // A lógica aqui simplesmente sincroniza o estado das gengivas dinâmicas com o das gengivas normais
                                // Isso presume que a lista de gengivas dinâmicas corresponde à lista de gengivas normais
                                dynamic.visible = gumsList[0].visible; // Usa o estado de visibilidade do primeiro elemento como referência
                                dynamic.material.opacity = gumsList[0].material.opacity; // Usa a opacidade do primeiro elemento como referência
                                dynamic.material.needsUpdate = true;
                            });
                        }
                    }
                }
                    break;

                case 'toggleTeeth':
                    this.toggleTeethOpacity()
                    break;
                case 'applyChanges':
                    this.executeActionWithLoading('saveActiveObject',
                        () => this.commitTransform(), 250);
                    break;
                case 'clearTasks':
                    this.$store.dispatch('editor3D/clearTasks');
                    break;
                case 'saveSegmentation':
                    this.executeActionWithLoading('saveSegmentation',
                        () => this.saveDentalMap(), 1000);
                    break;
                case 'extractToothBorder':
                    this.iaActive = "orange";
                    this.executeActionWithLoading('extractToothBorder',
                        () => this.executeRaycasting(), 1000);
                    break;
                case 'saveActiveObject':
                    this.executeActionWithLoading('saveActiveObject',
                        () => this.exportSTL(), 1000);
                    break;
                case 'showDentalArch':
                    this.executeActionWithLoading('showDentalArch',
                        () => {
                            this.createBoundingBoxMeshForTeeth();
                            this.drawDentalArch();
                            this.preAlignDentalMap(this.dentalMap)
                            //this.identifyCusps(this.dentalMap);
                            //this.createToothHeightMap()
                            //this.createHeatMapBasedOnCentersOfMass()
                            //this.createCuspHeightMap()
                            //this.closeGapsBetweenTeethAndGum()
                        }, 1000);
                    break;
                case 'startSES':
                    this.executeActionWithLoading('startSES',
                        () => this.startSESAction(), 1000);
                    break;
                case 'dynamicGum': {
                    //this.makeGumsTeethLines();
                    //this.makeTeethGumsLines();
                    //this.applyDelaunayTriangulation();
                    //this.newMakeDynamicGum();
                    //this.createToothBorders(this.dentalMap);
                    this.closeGapsBetweenTeethAndGum();
                    break;
                }
                case 'toggleArchMoveType':
                    this.executeActionWithLoading('toggleArchMoveType',
                        () => this.toggleArchMoveType(), 100);
                    break;
                case 'clearContourPoints':
                    this.clearContourPoints();
                    break;
                case 'toggleContourMarking':
                    this.toggleContourMarking();
                    break;
                case 'applyCutBelow':
                    this.executeActionWithLoading('cutModel',
                        () => this.cutActiveObject(false), 1000);
                    break;
                case 'applyCutAbove':
                    this.executeActionWithLoading('cutModel',
                        () => this.cutActiveObject(true), 1000);
                    break;
                case 'cutModel':
                    this.executeActionWithLoading('cutModel',
                        () => this.cloneOcclusionPlanForCutting(), 1000);
                    break;
                case 'creatingOcclusionPlan':
                    this.executeActionWithLoading('creatingOcclusionPlan',
                        () => this.initiateOcclusionPlan(), 1000);
                    break;
                case 'creatingDentalArch':
                    this.executeActionWithLoading('creatingDentalArch',
                        () => this.initiateDentalArch(), 1000);
                    break;
                case 'toggleUpperJaw':
                    this.toggleJaw('maxillary');
                    break;
                case 'toggleLowerJaw':
                    this.toggleJaw('mandibular');
                    break;
                case 'viewFront':
                    this.alignCameraToAxis('Z');
                    break;
                case 'viewBack':
                    this.alignCameraToAxis('-Z');
                    break;
                case 'viewRight':
                    this.alignCameraToAxis('X');
                    break;
                case 'viewLeft':
                    this.alignCameraToAxis('-X');
                    break;
                case 'viewTop':
                    this.alignCameraToAxis('Y');
                    break;
                case 'viewBottom':
                    this.alignCameraToAxis('-Y');
                    break;
                case 'toggleLight':
                    this.toggleLight('ambient');
                    break;
                case 'toggleLightDir1':
                    this.toggleLight('directional1');
                    break;
                case 'toggleLightDir2':
                    this.toggleLight('directional2');
                    break;
                case 'toggleBoxHelper':
                    if (!this.activeObject.isTransformControls) {
                        if (this.activeObject !== this.transformControl.object)
                            this.transformControl.attach(this.activeObject)
                        else
                            this.transformControl.detach()
                    } else
                        this.transformControl.detach()

                    //this.toggleBoxHelper();
                    break;
                case 'controlTranslate':
                    this.transformControl.setMode('translate');
                    break;
                case 'controlRotate':
                    this.transformControl.setMode('rotate');
                    break;
            }
        },
        toggleArchMoveType() {
            const moveTypes = ['fullArch', 'ends', 'midPoints', 'centerPoint', 'endsLeft', 'midPointsLeft', 'endsRight', 'midPointsRight'];
            let currentIndex = moveTypes.indexOf(this.activeArchMoveType);
            currentIndex = (currentIndex + 1) % moveTypes.length; // Avança para o próximo tipo e volta ao primeiro após o último.
            this.activeArchMoveType = moveTypes[currentIndex];
            this.statusMessage = this.$t(this.activeArchMoveType);
        },
        clearContourPoints() {
            this.$store.dispatch('editor3D/clearContourPoints');
            // Adicionalmente, você pode querer atualizar a cena aqui se necessário
            this.updateContour();
        },
        toggleContourMarking() {
            const currentAction = this.$store.state.editor3D.currentAction;
            if (currentAction === 'markingContour') {
                // Se já estiver no estado de marcação, finalizar o processo
                this.$store.dispatch('editor3D/finishMarkingContour');
            } else {
                // Se não estiver, iniciar o processo de marcação
                this.$store.dispatch('editor3D/startMarkingContour');
            }
        },
        updateContour() {
            const contourPoints = this.$store.getters['editor3D/contourPoints'];
            const isContour = (this.currentAction !== 'creatingDentalArch');

            // Encontrar ou criar o objeto de contorno na cena
            let contourObject = this.scene.children.find(child => child.userData.isContour === true);
            if (!contourObject) {
                contourObject = new THREE.Group();
                contourObject.userData = { name: "Countour", isContour: true };
                this.scene.add(contourObject);
            } else {
                // Limpar os objetos de contorno existentes
                while (contourObject.children.length) {
                    const child = contourObject.children[0];
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                    contourObject.remove(child);
                }
            }

            // Criar esferas para os pontos de contorno
            contourPoints.forEach((point, index, array) => {
                const color = (isContour) ? (index === array.length - 1 ? 0xffa0a0 : 0xff2626) : 0x2626ff;
                this.addSphereAtPoint(point, contourObject, index, color, isContour);
            });

            // Criar linhas para conectar os pontos de contorno
            if (isContour)
                this.addLinesBetweenPoints(contourPoints, contourObject, isContour);
            else
                this.addLinesBetweenPoints(contourPoints, contourObject, isContour, 0.5, 0xff2626);
        },
        addPlaneSphereAtPoint(point) {
            const geometry = new THREE.SphereGeometry(0.75, 32, 32); // Tamanho da esfera ajustável conforme necessário
            const material = new THREE.MeshPhongMaterial({ color: 0xffA726, specular: 0x222222, shininess: 400, side: THREE.DoubleSide })
            const sphere = new THREE.Mesh(geometry, material);

            sphere.position.copy(point);
            sphere.userData = { helper: true, hide: true }; // Marca a esfera como auxiliar e oculta na lista da cena

            this.scene.add(sphere);
        },
        addSphereAtPoint(point, parent, pointIndex, color = 0xff2626, isContour = true) {
            const sphereSize = (isContour) ? 0.4 : 1;
            const sphereGeometry = new THREE.SphereGeometry(sphereSize, 12, 12);
            const sphereMaterial = new THREE.MeshPhongMaterial({ color: color, specular: 0x222222, shininess: 500, side: THREE.DoubleSide })
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

            // Define o userData para o Mesh da esfera
            sphere.userData = { isContourSphere: true, pointIndex: pointIndex };

            sphere.position.copy(point);
            parent.add(sphere);
        },
        onDocumentMouseDown(event) {
            // Verifica se o botão esquerdo do mouse (botão 0) foi pressionado
            if (event.button === 0 && this.$store.state.editor3D.currentAction === 'smoothingEdges') {
                this.isMouseDown = true;
            }
            if (event.button === 0) {
                const intersection = this.getPointOnModel(event);

                // Verifique se você clicou em uma esfera de contorno
                if (intersection && intersection.objectRef.userData.isContourSphere) {
                    this.isDragging = true;
                    this.selectedSphere = intersection.objectRef;
                    // Desativar os controles de câmera se você os tem habilitado
                    // this.controls.enabled = false;
                    event.preventDefault(); // Isso evita outros manipuladores que possam interferir
                }
            }

            if (event.button === 2) { // Botão direito do mouse
                event.preventDefault(); // Evitar o comportamento padrão (rotação)
                this.mouseDownPosition = { x: event.clientX, y: event.clientY };
                this.lastMousePosition = { x: event.clientX, y: event.clientY };
            }

            this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

            // Verifique se o mousedown ocorreu em um objeto controlado pelo transformControl
            let raycaster = new THREE.Raycaster();

            // Configura o raycaster a partir da câmera e da posição do mouse
            raycaster.setFromCamera(this.mouse, this.camera);

            const intersects = raycaster.intersectObject(this.transformControl, true);
            if (intersects.length > 0) {
                this.transformControl.children.forEach(child => {
                    if (child.type === 'TransformControlsGizmo') {
                        child.visible = true;
                    }
                });
            }
        },
        onDocumentMouseMove(event) {
            // Se o SES está ativo, atualiza a posição do cursor "Brush"
            if (this.$store.state.editor3D.currentAction === 'smoothingEdges') {
                const intersection = this.getPointOnModel(event, this.brushMesh);
                if (intersection) {
                    if (this.brushMesh) {
                        this.brushMesh.position.copy(intersection.point);
                        this.renderer.render(this.scene, this.camera);
                    }
                }
            } if (this.isDragging && this.selectedSphere) {
                const intersection = this.getPointOnModel(event, this.selectedSphere);
                if (intersection) {
                    // Atualize a posição da esfera selecionada
                    this.selectedSphere.position.copy(intersection.point);
                    const pointIndex = this.selectedSphere.userData.pointIndex;
                    this.$store.dispatch('editor3D/updateContourPoint', { index: pointIndex, newPosition: intersection.point });
                    this.renderer.render(this.scene, this.camera);
                    // Se necessário, atualize o contorno aqui
                    this.updateContour();
                    console.warn(this.currentMode)
                    if (this.currentMode === 'alignPlan') {
                        this.adjustArchScale('fullArch', 1.0)
                        this.alignDentalMapToArch();
                        this.alignDentalAngularToTangent();
                        this.closeGapsBetweenTeethAndGum();
                    }
                }
            }

            // if (this.mouseDownPosition) {
            //     var currentMousePosition = { x: event.clientX, y: event.clientY };
            //     var deltaX = currentMousePosition.x - this.lastMousePosition.x;
            //     var deltaY = currentMousePosition.y - this.lastMousePosition.y;

            //     var directionX = (deltaX > 0) ? 1 : -1;
            //     var directionY = (deltaY > 0) ? 1 : -1;

            //     // Determinar o eixo de rotação com base na direção do movimento
            //     var axis;
            //     if (Math.abs(deltaX) > Math.abs(deltaY)) {
            //         axis = new THREE.Vector3(0, directionX, 0); // Rotação em torno do eixo Y
            //     } else {
            //         axis = new THREE.Vector3(directionY, 0, 0); // Rotação em torno do eixo X
            //     }

            //     // Aplicar a rotação em torno do eixo determinado
            //     var angle = Math.sqrt(deltaX * deltaX + deltaY * deltaY) * Math.PI / 180; // Ângulo de rotação

            //     var sensivity = 0.25;
            //     // eslint-disable-next-line no-unused-vars
            //     var rotationMatrix = new THREE.Matrix4().makeRotationAxis(axis, sensivity * angle);
            //     this.scene.traverse(function (child) {
            //         // Verificar se child é uma instância válida de Object3D ou Mesh
            //         if (child instanceof THREE.Object3D && (child instanceof THREE.Mesh || child instanceof THREE.Group)) {
            //             // Aplicar a rotação para cada objeto Object3D ou Mesh na cena
            //             //child.applyMatrix4(rotationMatrix);
            //         }
            //     });


            //     this.lastMousePosition = currentMousePosition;
            // }

            this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

            // Verifique se o mousedown ocorreu em um objeto controlado pelo transformControl
            let raycaster = new THREE.Raycaster();

            // Configura o raycaster a partir da câmera e da posição do mouse
            raycaster.setFromCamera(this.mouse, this.camera);

            const intersects = raycaster.intersectObject(this.transformControl, true);
            if (intersects.length > 0) {
                this.transformControl.children.forEach(child => {
                    if (child.type === 'TransformControlsGizmo') {
                        child.visible = true;
                    }
                });
            }
        },
        onDocumentMouseUp(event) {
            this.isDragging = false;
            this.selectedSphere = null;
            this.controls.enabled = true; // Reativa os controles de câmera após o arrasto
            if (event.button === 0 && this.$store.state.editor3D.currentAction === 'smoothingEdges') {
                this.isMouseDown = false;
            }

            if (event.button === 1) {
                // Chama o método para atualizar o foco da câmera com base nas arcadas dentárias
                //this.updateCameraFocusBasedOnDentalArches();
                //this.controls.target.copy(this.camera.getWorldDirection(new THREE.Vector3()));
                //this.controls.update();
                //this.camera.lookAt(0, 0, 0);
                //console.table(this.camera.getWorldDirection(new THREE.Vector3()));
                //console.table(this.camera)
                //console.warn('Update focus')
                this.transformControl.mode = this.transformControl.mode === 'rotate' ? 'translate' : 'rotate';
            }

            this.mouseDownPosition = null;

            if (!this.transformControl.dragging) {
                this.transformControl.children.forEach(child => {
                    if (child.type === 'TransformControlsGizmo') {
                        child.visible = false;
                    }
                });
            }

        },
        addLinesBetweenPoints(points, parent, isClosed = true, radius = 0.1, color = 0x404040) {
            // Certifique-se de que há pelo menos dois pontos para formar uma linha
            if (points.length > 1) {
                const curve = new THREE.CatmullRomCurve3(points, isClosed, 'catmullrom', 0.5);

                const tubeGeometry = new THREE.TubeGeometry(curve, 24 * points.length, radius, 8, isClosed); // Ajuste os parâmetros conforme necessário
                const tubeMaterial = new THREE.MeshBasicMaterial({ color: color });
                const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
                parent.add(tubeMesh);
            }
        },
        drawDentalArch() {
            const teethCenters = this.scene.userData.teethCenters || {};
            const upperTeeth = [];
            const lowerTeeth = [];

            // Separa os pontos baseados no quadrante ao qual eles pertencem
            Object.keys(teethCenters).forEach(toothNumber => {
                const toothInt = parseInt(toothNumber);
                if (toothInt >= 11 && toothInt <= 28) {
                    upperTeeth.push({ toothNumber: toothInt, point: teethCenters[toothNumber] });
                } else if (toothInt >= 31 && toothInt <= 48) {
                    lowerTeeth.push({ toothNumber: toothInt, point: teethCenters[toothNumber] });
                }
            });

            // Ordena os dentes superiores e inferiores
            upperTeeth.sort((a, b) => a.toothNumber - b.toothNumber);
            lowerTeeth.sort((a, b) => a.toothNumber - b.toothNumber);

            // Inverte a ordem dos dentes do lado esquerdo (11-18)
            const upperLeft = upperTeeth.filter(tooth => tooth.toothNumber <= 18).reverse();
            // Mantém a ordem dos dentes do lado direito (21-28)
            const upperRight = upperTeeth.filter(tooth => tooth.toothNumber >= 21 && tooth.toothNumber <= 28);
            // Combina os arrays para formar o arco superior completo
            const upperArch = [...upperLeft, ...upperRight].map(tooth => tooth.point);

            // Semelhante para os dentes inferiores
            const lowerLeft = lowerTeeth.filter(tooth => tooth.toothNumber > 38).reverse();
            const lowerRight = lowerTeeth.filter(tooth => tooth.toothNumber >= 31 && tooth.toothNumber <= 38);
            const lowerArch = [...lowerLeft, ...lowerRight].map(tooth => tooth.point);

            // Encontra o grupo de bounding boxes na cena que tem a propriedade isTeethBoxes definida como true
            const teethBoxesGroup = this.scene.getObjectByName('Teeth Boxes');

            // Se o grupo de bounding boxes for encontrado e estiver marcado corretamente como isTeethBoxes
            if (teethBoxesGroup && teethBoxesGroup.userData.isTeethBoxes) {
                // Adiciona as linhas do arco superior e inferior usando o grupo como parent
                this.addLinesBetweenPoints(upperArch, teethBoxesGroup, false, 0.25); // Arco superior
                this.addLinesBetweenPoints(lowerArch, teethBoxesGroup, false, 0.25); // Arco inferior
            } else {
                console.warn('Grupo de bounding boxes para os dentes não encontrado ou não marcado como isTeethBoxes.');
            }
        },
        createCuspHeightMap(visible = true) {
            const existingHeightMapGroup = this.scene.getObjectByName('Cusps Height Map');
            if (existingHeightMapGroup) {
                this.scene.remove(existingHeightMapGroup);
                existingHeightMapGroup.children.forEach(child => {
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                });
            }

            const heightMapGroup = new THREE.Group();
            heightMapGroup.name = 'Cusps Height Map';
            heightMapGroup.visible = visible;
            heightMapGroup.userData.name = 'Cusps Height Map';

            const teeth = this.findTeethInScene();

            const existingCuspsPositions = []; // Array para armazenar as posições das cúspides

            const minDistance = 0.25; // Defina a distância mínima desejada entre cúspides

            teeth.forEach(tooth => {
                const toothNumber = tooth.userData.toothNumber;
                if (this.dentalMap[toothNumber] !== undefined && this.dentalMap[toothNumber].cusps) {
                    const cusps = this.dentalMap[toothNumber].cusps;

                    if (!cusps || cusps.length === 0) return;

                    // Encontre a altura mínima e máxima das cúspides
                    let minHeight = Infinity;
                    let maxHeight = -Infinity;

                    cusps.forEach(cusp => {
                        minHeight = Math.min(minHeight, cusp.y);
                        maxHeight = Math.max(maxHeight, cusp.y);
                    });

                    // Criar uma esfera para cada cúspide com base na altura, se estiver distante o suficiente das existentes
                    cusps.forEach(cusp => {
                        const normalizedHeight = (cusp.y - minHeight) / (maxHeight - minHeight);

                        if ((normalizedHeight < 0.25 && toothNumber < 31) ||
                            (normalizedHeight > 0.75 && toothNumber >= 31)) {
                            // Verificar a distância para todas as cúspides existentes
                            let isFarEnough = true;

                            existingCuspsPositions.forEach(existingPosition => {
                                const distance = existingPosition.distanceTo(cusp);
                                if (distance < minDistance) {
                                    isFarEnough = false;
                                }
                            });

                            if (isFarEnough) {
                                // Mapeie a altura normalizada para uma cor (azul = baixo, vermelho = alto)
                                let invertColorMap = 0;
                                if (toothNumber >= 31) invertColorMap = 1;
                                const color = new THREE.Color().setHSL(Math.abs(invertColorMap - normalizedHeight) * 0.7, 1.0, 0.5);

                                const sphereGeometry = new THREE.SphereGeometry(0.25, 4, 4);
                                const sphereMaterial = new THREE.MeshBasicMaterial({ color: color });
                                const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                                sphere.position.copy(cusp);
                                heightMapGroup.add(sphere);

                                // Armazena a posição da cúspide adicionada
                                existingCuspsPositions.push(sphere.position.clone());
                            }
                        }
                    });
                }
            });

            this.scene.add(heightMapGroup);
            return heightMapGroup;
        },
        createHeatMapBasedOnCentersOfMass(visible = true) {
            // Remover qualquer mapa de calor existente
            const existingHeatMapGroup = this.scene.getObjectByName('Heat Map');
            if (existingHeatMapGroup) {
                this.scene.remove(existingHeatMapGroup);
                existingHeatMapGroup.children.forEach(child => {
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                });
            }

            const heatMapGroup = new THREE.Group();
            heatMapGroup.name = 'Heat Map';
            heatMapGroup.visible = visible;
            heatMapGroup.userData.name = 'Heat Map';

            const teeth = this.findTeethInScene();

            // Função para calcular a influência de um centro de massa em um vértice
            const calculateInfluence = (vertex, centerOfMass) => {
                const distance = vertex.distanceTo(centerOfMass);
                if (distance > 4) return 0;
                return 1 / (distance * distance); // Quanto menor a distância, maior a influência
            };

            teeth.forEach(tooth => {
                const toothNumber = tooth.userData.toothNumber;
                const geometry = tooth.geometry.clone(); // Clonar a geometria original
                const positionAttribute = geometry.getAttribute('position');
                const vertexCount = positionAttribute.count;

                const colors = new Float32Array(vertexCount * 3);

                for (let i = 0; i < vertexCount; i++) {
                    const vertex = new THREE.Vector3(
                        positionAttribute.getX(i),
                        positionAttribute.getY(i),
                        positionAttribute.getZ(i)
                    );

                    let totalInfluence = 0;

                    // Iterar sobre todos os dentes e acumular influência, exceto o próprio dente
                    for (const key in this.dentalMap) {
                        if (parseInt(key) !== toothNumber) {
                            const centerOfMass = this.dentalMap[key].centerOfMass;
                            const influence = calculateInfluence(vertex, centerOfMass);
                            totalInfluence += influence;
                        }
                    }

                    // Normalizar a influência para um valor entre 0 e 1 (ou qualquer faixa desejada)
                    const normalizedInfluence = Math.min(totalInfluence, 1.0);

                    // Definir a cor com base na influência normalizada (azul para baixa influência, vermelho para alta)
                    const color = new THREE.Color().setHSL(0.0, normalizedInfluence, 0.5);

                    colors[i * 3] = color.r;
                    colors[i * 3 + 1] = color.g;
                    colors[i * 3 + 2] = color.b;
                }

                geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

                // Criar o material usando vertexColors
                const material = new THREE.MeshBasicMaterial({
                    vertexColors: true
                });

                const coloredTooth = new THREE.Mesh(geometry, material);
                coloredTooth.position.copy(tooth.position);
                coloredTooth.rotation.copy(tooth.rotation);
                coloredTooth.scale.copy(tooth.scale);

                heatMapGroup.add(coloredTooth);
            });

            this.scene.add(heatMapGroup);
            return heatMapGroup;
        }, createToothHeightMap(visible = true) {
            // Remover qualquer mapa de altura existente
            const existingHeightMapGroup = this.scene.getObjectByName('Cusps Height Map');
            if (existingHeightMapGroup) {
                this.scene.remove(existingHeightMapGroup);
                existingHeightMapGroup.children.forEach(child => {
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                });
            }

            const heightMapGroup = new THREE.Group();
            heightMapGroup.name = 'Cusps Height Map';
            heightMapGroup.visible = visible;
            heightMapGroup.userData.name = 'Cusps Height Map';

            const teeth = this.findTeethInScene();

            teeth.forEach(tooth => {
                const geometry = tooth.geometry.clone(); // Clonar a geometria original
                const positionAttribute = geometry.getAttribute('position');
                const vertexCount = positionAttribute.count;

                let minHeight = Infinity;
                let maxHeight = -Infinity;

                // Encontrar altura mínima e máxima
                for (let i = 0; i < vertexCount; i++) {
                    const y = positionAttribute.getY(i);
                    if (y < minHeight) minHeight = y;
                    if (y > maxHeight) maxHeight = y;
                }

                // Arrays para armazenar as posições e cores filtradas
                const redPositions = [];
                const redColors = [];

                // Criar o atributo de cor baseado na altura dos vértices
                for (let i = 0; i < vertexCount; i++) {
                    const y = positionAttribute.getY(i);
                    const normalizedHeight = (y - minHeight) / (maxHeight - minHeight);
                    let invertColorMap = 0;
                    if (tooth.userData.toothNumber >= 31) invertColorMap = 1;

                    // Calcular a cor
                    const color = new THREE.Color().setHSL(Math.abs(invertColorMap - normalizedHeight) * 0.5, 1.0, 0.5);

                    // Checar se a cor está próxima ao vermelho
                    if (color.r > 0.98) {
                        // Adicionar a posição e a cor ao array filtrado
                        redPositions.push(positionAttribute.getX(i), positionAttribute.getY(i), positionAttribute.getZ(i));
                        redColors.push(color.r, color.g, color.b);
                    }
                }

                // Se houver vértices filtrados, criar a nova geometria e adicionar ao grupo
                if (redPositions.length > 0) {
                    const redGeometry = new THREE.BufferGeometry();
                    redGeometry.setAttribute('position', new THREE.Float32BufferAttribute(redPositions, 3));
                    redGeometry.setAttribute('color', new THREE.Float32BufferAttribute(redColors, 3));

                    // Criar o material usando vertexColors
                    const material = new THREE.MeshBasicMaterial({
                        vertexColors: true
                    });

                    const coloredTooth = new THREE.Mesh(redGeometry, material);
                    coloredTooth.position.copy(tooth.position);
                    coloredTooth.rotation.copy(tooth.rotation);
                    coloredTooth.scale.copy(tooth.scale);

                    heightMapGroup.add(coloredTooth);
                }
            });

            this.scene.add(heightMapGroup);
            return heightMapGroup;
        },
        createToothHeightMap_(visible = true) {
            // Remover qualquer mapa de altura existente
            const existingHeightMapGroup = this.scene.getObjectByName('Cusps Height Map');
            if (existingHeightMapGroup) {
                this.scene.remove(existingHeightMapGroup);
                existingHeightMapGroup.children.forEach(child => {
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                });
            }

            const heightMapGroup = new THREE.Group();
            heightMapGroup.name = 'Cusps Height Map';
            heightMapGroup.visible = visible;
            heightMapGroup.userData.name = 'Cusps Height Map';

            const teeth = this.findTeethInScene();

            teeth.forEach(tooth => {
                const geometry = tooth.geometry.clone(); // Clonar a geometria original
                const positionAttribute = geometry.getAttribute('position');
                const vertexCount = positionAttribute.count;

                let minHeight = Infinity;
                let maxHeight = -Infinity;

                // Encontrar altura mínima e máxima
                for (let i = 0; i < vertexCount; i++) {
                    const y = positionAttribute.getY(i);
                    if (y < minHeight) minHeight = y;
                    if (y > maxHeight) maxHeight = y;
                }

                // Criar o atributo de cor baseado na altura dos vértices
                const colors = new Float32Array(vertexCount * 3);
                for (let i = 0; i < vertexCount; i++) {
                    const y = positionAttribute.getY(i);
                    const normalizedHeight = (y - minHeight) / (maxHeight - minHeight);
                    let invertColorMap = 0;
                    if (tooth.userData.toothNumber >= 31) invertColorMap = 1;
                    const color = new THREE.Color().setHSL(Math.abs(invertColorMap - normalizedHeight) * 0.5, 1.0, 0.5);

                    colors[i * 3] = color.r;
                    colors[i * 3 + 1] = color.g;
                    colors[i * 3 + 2] = color.b;
                }

                geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

                // Criar o material usando vertexColors
                const material = new THREE.MeshBasicMaterial({
                    vertexColors: true
                });

                const coloredTooth = new THREE.Mesh(geometry, material);
                coloredTooth.position.copy(tooth.position);
                coloredTooth.rotation.copy(tooth.rotation);
                coloredTooth.scale.copy(tooth.scale);

                heightMapGroup.add(coloredTooth);
            });

            this.scene.add(heightMapGroup);
            return heightMapGroup;
        }

        ,
        identifyCusps(dentalMap) {
            const teeth = this.findTeethInScene();

            teeth.forEach(tooth => {
                const toothNumber = tooth.userData.toothNumber;
                const toothData = dentalMap[toothNumber];
                const geometry = tooth.geometry.clone();
                geometry.applyMatrix4(tooth.matrixWorld);

                // Calcule as normais e determine a orientação
                geometry.computeVertexNormals();
                const normals = geometry.attributes.normal.array;
                let averageNormal = new THREE.Vector3(0, 0, 0);

                for (let i = 0; i < normals.length; i += 3) {
                    averageNormal.add(new THREE.Vector3(normals[i], normals[i + 1], normals[i + 2]));
                }
                averageNormal.divideScalar(normals.length / 3).normalize();

                // Identificar os vértices mais altos (cúspides)
                const vertices = geometry.attributes.position.array;
                const cusps = [];

                for (let i = 0; i < vertices.length; i += 3) {
                    const vertex = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
                    // Use a normal média para decidir qual eixo é o "topo"
                    const heightValue = vertex.dot(averageNormal);
                    cusps.push({ position: vertex, height: heightValue });
                }

                // Ordenar cúspides pela altura (pico mais alto)
                cusps.sort((a, b) => b.height - a.height);

                if (toothData !== undefined) {
                    // Salvar as cúspides mais altas no dentalMap
                    toothData.cusps = cusps.slice(0, toothData.expectedCuspsCount).map(c => c.position);

                    // Atualizar o dentalMap com as cúspides encontradas
                    dentalMap[toothNumber] = toothData;
                }
            });

            return dentalMap;
        },
        setMeshCategory(mesh, category) {
            mesh.userData.category = category;
            return
        },
        filterMeshes(category) {
            if (category==='') {
                this.showAllMeshes();
                return
            }
            console.warn(`Filter Category ${category}`);
            this.scene.traverse((object) => {
                if (object.isMesh || object.isGroup) {
                    // Verifica se o parâmetro é uma string ou um array de strings
                    if (typeof category === 'string') {
                        // Filtra apenas pela categoria passada como string
                        object.visible = object.userData.category === category;
                    } else if (Array.isArray(category)) {
                        // Filtra por múltiplas categorias
                        object.visible = category.includes(object.userData.category);
                    } else {
                        // Caso o parâmetro não seja válido, esconde o mesh
                        object.visible = false;
                    }
                }
            });
        }
        ,
        showAllMeshes() {
            this.scene.traverse((object) => {
                if (object.isMesh) {
                    object.visible = true;
                }
            });
        },
        createBoundingBoxMeshForTeeth(visible = true) {
            const existingBoundingBoxGroup = this.scene.getObjectByName('Teeth Boxes');
            if (existingBoundingBoxGroup) {
                this.scene.remove(existingBoundingBoxGroup);
                existingBoundingBoxGroup.children.forEach(child => {
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                });
            }

            const teeth = this.findTeethInScene();
            const boundingBoxGroup = new THREE.Group();
            const teethCenters = {}; // Objeto para armazenar os centros indexados pelo número do dente

            boundingBoxGroup.name = 'Teeth Boxes';
            boundingBoxGroup.visible = visible;
            boundingBoxGroup.userData.name = 'Teeth Boxes';
            boundingBoxGroup.userData.isTeethBoxes = true;

            teeth.forEach(tooth => {
                const toothNumber = tooth.userData.toothNumber;
                const toothData = this.dentalMap[toothNumber];
                const boundingBox = new THREE.Box3().setFromObject(tooth);
                const helper = new THREE.Box3Helper(boundingBox, 0xa0a0a0);
                boundingBoxGroup.add(helper);

                const center = new THREE.Vector3();
                boundingBox.getCenter(center);

                const sphereGeometry = new THREE.SphereGeometry(0.75, 32, 32);
                const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
                const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                sphere.position.copy(center);
                boundingBoxGroup.add(sphere);

                teethCenters[toothNumber] = center;

                if (toothData !== undefined) {

                    // Adiciona o centro ao objeto teethCenters usando toothNumber como chave
                    const medialVector = new THREE.Vector3(toothData.medial.x, toothData.medial.y, toothData.medial.z);
                    const distalVector = new THREE.Vector3(toothData.distal.x, toothData.distal.y, toothData.distal.z);

                    // Linha entre pontos medial e distal
                    const lineGeometry = new THREE.BufferGeometry().setFromPoints([medialVector, distalVector]);
                    const lineMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 });
                    const line = new THREE.Line(lineGeometry, lineMaterial);
                    boundingBoxGroup.add(line);

                    // Linha pivot
                    const midPoint = new THREE.Vector3().addVectors(medialVector, distalVector).multiplyScalar(0.5);
                    const pivotGeometry = new THREE.BufferGeometry().setFromPoints([midPoint, center]);
                    const pivotMaterial = new THREE.LineBasicMaterial({ color: 0xff00ff });
                    const pivotLine = new THREE.Line(pivotGeometry, pivotMaterial);
                    boundingBoxGroup.add(pivotLine);

                    this.dentalMap[toothNumber].radius = tooth.geometry.boundingSphere.radius; // Atualiza o raio no dentalMap

                    // Aqui continua a lógica de cálculo e atualização dos ângulos no dentalMap, conforme o original
                    const transformedGeometry = this.applyTransformToGeometry(tooth.geometry, tooth.matrixWorld);
                    const normals = transformedGeometry.attributes.normal.array;
                    let averageNormal = new THREE.Vector3(0, 0, 0);

                    for (let i = 0; i < normals.length; i += 3) {
                        averageNormal.add(new THREE.Vector3(normals[i], normals[i + 1], normals[i + 2]));
                    }
                    averageNormal.divideScalar(normals.length / 3).normalize();

                    // Aqui, você calcularia os ângulos com base na lógica específica do seu projeto
                    // Exemplo genérico abaixo
                    let angleXRad = Math.atan2(averageNormal.y, averageNormal.z);
                    let angleYRad = Math.atan2(averageNormal.x, averageNormal.z);
                    let angleZRad = Math.atan2(averageNormal.y, averageNormal.x);

                    let angleXDeg = angleXRad * (180 / Math.PI);
                    let angleYDeg = angleYRad * (180 / Math.PI);
                    let angleZDeg = angleZRad * (180 / Math.PI);

                    // Atualiza os ângulos no dentalMap
                    this.dentalMap[toothNumber].angles = { x: angleXDeg, y: angleYDeg, z: angleZDeg };
                    // Supondo que actualAngles sejam calculados de maneira semelhante
                    this.dentalMap[toothNumber].actualAngles = { x: angleXDeg, y: angleYDeg, z: angleZDeg };
                }
            });

            // Armazena os centros no userData da cena para uso posterior
            this.scene.userData.teethCenters = teethCenters;

            this.scene.add(boundingBoxGroup);
            return boundingBoxGroup;
        },
        visualizeDentalEigenvectors(toothNumber, newCenter, parent) {
            const eigenvectors = this.dentalMap[toothNumber].eigenvectors;
            const centroid = this.dentalMap[toothNumber].centroid;
            const dimensions = this.dentalMap[toothNumber].dimensions; // Assumindo que dimensões estejam armazenadas

            if (!eigenvectors || !centroid || !dimensions) {
                console.warn("Dados necessários para o dente", toothNumber, "não encontrados.");
                return;
            }

            // Criando e orientando a OBB baseada nos eigenvectors e dimensões
            const obb = this.createAndOrientOBB(eigenvectors, dimensions, centroid);
            // Transladar a OBB para o newCenter, se necessário
            obb.position.copy(newCenter);

            // Adiciona a OBB ao grupo especificado
            parent.add(obb);

            // Converte o centroide para um objeto THREE.Vector3 para fácil manipulação
            const centroidVec = new THREE.Vector3(centroid.x, centroid.y, centroid.z);

            eigenvectors.data.forEach((vec, index) => {
                const direction = new THREE.Vector3(vec["0"], vec["1"], vec["2"]).normalize();
                const length = 8; // Comprimento dos ArrowHelpers
                const color = [0xff0000, 0x00ff00, 0x0000ff][index % 3]; // Cores variadas para os ArrowHelpers

                // Cria um ArrowHelper no centroide do objeto
                const arrowHelper = new THREE.ArrowHelper(direction, centroidVec, length, color);

                // Calcula a diferença entre o centro atual da bounding box e o centroide
                const centerDiff = new THREE.Vector3(newCenter.x, newCenter.y, newCenter.z).sub(centroidVec);

                // Translada o ArrowHelper para o novo centro
                arrowHelper.position.add(centerDiff);

                // Adiciona o ArrowHelper ao grupo especificado
                parent.add(arrowHelper);
            });
        },
        createAndOrientOBB(eigenvectors, dimensions, centroid) {
            let obbGeometry = new THREE.BoxGeometry(dimensions[0], dimensions[1], dimensions[2]);
            let obbMesh = new THREE.Mesh(obbGeometry, new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));

            // Suponha que eigenvectors.data[0] é o eixo X, eigenvectors.data[1] é o eixo Y, e eigenvectors.data[2] é o eixo Z.
            let xAxis = new THREE.Vector3().fromArray(eigenvectors.data[0]).normalize();
            let yAxis = new THREE.Vector3().fromArray(eigenvectors.data[1]).normalize();
            let zAxis = new THREE.Vector3().fromArray(eigenvectors.data[2]).normalize();

            // Preparar a matriz de rotação dos eigenvectors
            let rotationMatrix = new THREE.Matrix4().makeBasis(xAxis, yAxis, zAxis);

            obbMesh.setRotationFromMatrix(rotationMatrix);
            obbMesh.position.set(centroid.x, centroid.y, centroid.z); // Posiciona inicialmente no centroide

            return obbMesh;
        },
        toggleMethodsExecution() {
            if (this.intervalId) {
                // Se intervalId não é nulo, interrompe a execução
                clearInterval(this.intervalId);
                this.intervalId = null;
            } else {
                // Inicia a execução
                this.intervalId = setInterval(() => {
                    //this.createBoundingBoxMeshForTeeth(true);
                    //this.drawDentalArch();
                    this.moveDentalToIdealPosition();
                    //this.generateGumCurve(this.dentalMap)
                }, 500); // 500 milissegundos = meio segundo
            }
        },
        initVoxelGrid() {
            const teeth = this.findTeethInScene(); // Assume que findTeethInScene já está definido no seu sistema
            this.voxelGrid = new VoxelGrid(teeth, this.voxelSize);
        },
        queryVoxelGrid(position) {
            if (this.voxelGrid) {
                const mixedVoxels = this.voxelGrid.query(position);
                console.log(mixedVoxels);
                // Faça algo com os voxels mistos encontrados
            }
        },
        findSecondLowestPoints(mesh, radius = 2, baito, n) {
            const vertices = mesh.geometry.attributes.position.array;
            const points = [];

            // Extrair os vértices e ordená-los por Y (altura)
            for (let i = 0; i < vertices.length; i += 3) {
                points.push(new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]));
            }
            points.sort((a, b) => a.y - b.y);

            if (baito.length >= n) {
                return baito;
            }

            let secondBestPoint = null;
            let maxNeighbors = 0;

            // Iterar pelos pontos ordenados
            for (const point of points) {
                let validPoint = true;

                // Verificar se o ponto está contido em baito
                for (const baitoPoint of baito) {
                    if (point.equals(baitoPoint)) {
                        validPoint = false;
                        break;
                    }
                }

                if (!validPoint) continue;

                let neighborCount = 0;

                // Contar vizinhos dentro do raio especificado
                for (const otherPoint of points) {
                    if (!baito.includes(otherPoint) && point.distanceTo(otherPoint) < radius) {
                        neighborCount++;
                    }
                }

                // Verificar se é melhor que o ponto atualmente selecionado
                if (neighborCount > maxNeighbors) {
                    // Verificar se o ponto está suficientemente distante dos pontos em baito
                    let farEnough = true;
                    for (const baitoPoint of baito) {
                        if (point.distanceTo(baitoPoint) < radius * 2) {
                            farEnough = false;
                            break;
                        }
                    }

                    if (farEnough) {
                        secondBestPoint = point;
                        maxNeighbors = neighborCount;
                    }
                }
            }

            if (secondBestPoint) {
                baito.push(secondBestPoint); // Adicionar o ponto encontrado a baito para evitar repetições
            }

            // Chamada recursiva para encontrar mais pontos
            return this.findSecondLowestPoints(mesh, radius, baito, n);
        }
        ,
        findLowestPoints(mesh, radius = 2) {
            const vertices = mesh.geometry.attributes.position.array;
            const points = [];

            // Extrair os vértices e ordená-los por Y (altura)
            for (let i = 0; i < vertices.length; i += 3) {
                points.push(new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]));
            }
            points.sort((a, b) => a.y - b.y);

            let bestPoints = [];
            let maxNeighbors = 0;
            let maxHeightDifference = 1;

            // Iterar pelos pontos ordenados
            for (let i = 0; i < points.length; i++) {
                const point = points[i];

                // Verificar se está dentro da diferença máxima de altura
                if (point.y - points[0].y > maxHeightDifference) {
                    break; // Se ultrapassou a diferença máxima de altura, parar a busca
                }

                let neighborCount = 0;

                // Contar vizinhos dentro do raio especificado
                for (let j = 0; j < points.length; j++) {
                    if (i !== j && point.distanceTo(points[j]) < radius) {
                        neighborCount++;
                    }
                }

                // Verificar se é melhor que os pontos atuais selecionados
                if (neighborCount > maxNeighbors) {
                    // Verificar se o ponto está suficientemente distante dos pontos selecionados
                    let tooClose = false;
                    for (const selectedPoint of bestPoints) {
                        if (point.distanceTo(selectedPoint) < radius * 2) {
                            tooClose = true;
                            break;
                        }
                    }

                    if (!tooClose) {
                        bestPoints = [point];
                        maxNeighbors = neighborCount;
                    }
                } else if (neighborCount === maxNeighbors) {
                    // Se tiver o mesmo número de vizinhos, adicionar à lista
                    bestPoints.push(point);
                }
            }


            const secondBest = this.findSecondLowestPoints(mesh, radius, [bestPoints[0]], 10)
            return secondBest;
        }

        ,
        createCentersMesh(points, radius) {
            const centersMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
            const areaMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.05 });

            points.forEach(point => {
                // Criar a esfera azul no ponto encontrado
                const sphereGeometry = new THREE.SphereGeometry(0.5, 32, 32);
                const sphere = new THREE.Mesh(sphereGeometry, centersMaterial);
                sphere.position.copy(point);
                this.scene.add(sphere);

                // Criar a esfera transparente branca para mostrar a área de atuação
                const areaGeometry = new THREE.SphereGeometry(radius, 32, 32);
                const areaSphere = new THREE.Mesh(areaGeometry, areaMaterial);
                areaSphere.position.copy(point);
                this.scene.add(areaSphere);
            });
        },

        handleKeyDown(event) {
            const step = 0.1; // Quantidade a mover a esfera a cada pressionamento de tecla

            if (this.sphereMesh)
                switch (event.key) {
                    case "ArrowUp":
                        this.moveSphere(new THREE.Vector3(0, 1, 0).multiplyScalar(step).add(this.sphere.position));
                        break;
                    case "ArrowDown":
                        this.moveSphere(new THREE.Vector3(0, -1, 0).multiplyScalar(step).add(this.sphere.position));
                        break;
                    case "ArrowLeft":
                        this.moveSphere(new THREE.Vector3(-1, 0, 0).multiplyScalar(step).add(this.sphere.position));
                        break;
                    case "ArrowRight":
                        this.moveSphere(new THREE.Vector3(1, 0, 0).multiplyScalar(step).add(this.sphere.position));
                        break;
                    case "PageUp":
                        this.moveSphere(new THREE.Vector3(0, 0, 1).multiplyScalar(step).add(this.sphere.position));
                        break;
                    case "PageDown":
                        this.moveSphere(new THREE.Vector3(0, 0, -1).multiplyScalar(step).add(this.sphere.position));
                        break;
                    case "Home":
                        console.table(this.sphere.position);
                        console.table(this.findCylindricalSurfaces(this.activeObject));
                        break;
                    default:
                        break;
                }
            if (this.transformControl) {
                if (event.key === 'r') {
                    this.transformControl.setMode('rotate');
                } else if (event.key === 't') {
                    this.transformControl.setMode('translate');
                } else if (event.key === 'a') {
                    this.addAxisPlanes();
                } else if (event.key === 'c') {
                    this.commitTransform();
                } else if (event.key === 'b') {
                    this.createBoundingBoxMeshForTeeth();
                    this.drawDentalArch();
                } else if (event.key === 'm') {
                    //this.toggleMethodsExecution();
                    this.cleanGumFragments();
                    this.alignDentalMapToArch();
                    //this.alignInterdental();
                    this.alignDentalAngularToTangent();
                    this.closeGapsBetweenTeethAndGum()
                } else if (event.key === 'p') {
                    //this.findDenseRegions(4);
                    //this.dynamicSurfaceProximityMethod();
                    //this.handleDownload();
                    //const centerPoint = new THREE.Vector3(-24.901537819468096, -14.142497814219723, -5.940455473710053);
                    const partialGeometry = this.activeObject.geometry;
                    const seeds = this.findSeedPoints(partialGeometry, 'maxillary');
                    //const region = this.regionGrowing(seeds, partialGeometry, 1000);
                    // Inicializando o processo de crescimento da região
                    let segmentedPoints = new Set();
                    seeds.forEach(seedPoint => {
                        this.regionGrowingRecursive(seedPoint, partialGeometry, 4, segmentedPoints);
                    });
                    this.scene.add(this.visualizeConqueredVertices(partialGeometry, segmentedPoints));
                } else if (event.key === '.') {
                    this.adjustArchScale(this.activeArchMoveType, 1.002)
                    this.alignDentalMapToArch();
                    this.alignDentalAngularToTangent();
                    this.closeGapsBetweenTeethAndGum()
                } else if (event.key === ',') {
                    this.adjustArchScale(this.activeArchMoveType, 0.998)
                    this.alignDentalMapToArch();
                    this.alignDentalAngularToTangent();
                    this.closeGapsBetweenTeethAndGum()
                } else if (event.key === 'n') {
                    //this.scanDentalMapAndStartWorker()
                    this.scanDentalMapforMiniGun()
                } else if (event.key === 'u') {
                    this.simpleAnalysis();
                } else if (event.key === 'h') {
                    //this.initializeOctreeWithTeeth();
                    //this.generateVertexColorMesh();
                    this.initVoxelGrid();
                    console.table(this.voxelGrid);
                    const closestVertices = this.voxelGrid.findClosestVertices();
                    const numberOfObjects = Object.keys(closestVertices).length;
                    console.log(numberOfObjects);
                    this.voxelGrid.generateVertexColorMesh(this);
                } else if (event.key === 's') {
                    this.drawSphere();
                } else if (event.key === 'o') {
                    const teethUpper = this.findTeethInScene('upper');
                    const projectedImageUpper = this.generate2DProjection(teethUpper, true)
                    this.drawImageInDiv(projectedImageUpper, 'vision2D')

                    const teethLower = this.findTeethInScene('lower');
                    const projectedImageLower = this.generate2DProjection(teethLower)
                    this.drawImageInDiv(projectedImageLower, 'vision2D', false)
                } else if (event.key === '-') {
                    this.gumCutter();
                } else if (event.key === 'Home') {
                    this.filterMeshes('base')
                } else if (event.key === 'End') {
                    this.showAllMeshes();
                } else if (event.key === 'PageUp') {
                    this.filterMeshes(['segmented','tooth','gums'])
                }
            }
            if (event.key === '6') {
                //this.currentIndex.col = (this.currentIndex.col + 1) % this.dataMatrix[0].length;
                this.miniGun.neighbor += 5;
                this.updateMarker();
            } else if (event.key === '4') {
                //this.currentIndex.col = (this.currentIndex.col - 1 + this.dataMatrix[0].length) % this.dataMatrix[0].length;
                this.miniGun.neighbor -= 5;
                this.updateMarker();
            } else if (event.key === '8') {
                //this.currentIndex.row = (this.currentIndex.row - 1 + this.dataMatrix.length) % this.dataMatrix.length;
                this.miniGun.variation += 5;
                this.updateMarker();
            } else if (event.key === '2') {
                //this.currentIndex.row = (this.currentIndex.row + 1) % this.dataMatrix.length;
                this.miniGun.variation -= 5;
                this.updateMarker();
            } else if (event.key === '9') {
                //this.currentIndex.row = (this.currentIndex.row - 1 + this.dataMatrix.length) % this.dataMatrix.length;
                this.miniGun.angleBetween += 1;
                this.updateMarker();
            } else if (event.key === '7') {
                //this.currentIndex.row = (this.currentIndex.row + 1) % this.dataMatrix.length;
                this.miniGun.angleBetween -= 1;
                this.updateMarker();
            } else if (event.key === '*') {
                //this.currentIndex.row = (this.currentIndex.row + 1) % this.dataMatrix.length;
                this.miniGun.active = !this.miniGun.active;
                this.iaActive = this.miniGun.active ? 'yellow' : 'gray';
                this.statusMessage = this.miniGun.active ? 'Learning On' : '';
                //this.gumSearch();
            } else if (event.key === "/") {
                this.dbLoadModel(4);
            } else if (event.key === "g") {
                this.makeGradientMesh();
            }
            // Se estiver no modo de marcação de contorno e a tecla Delete for pressionada
            if ((this.$store.state.editor3D.currentAction === 'markingContour' || this.$store.state.editor3D.currentAction === 'creatingDentalArch') && event.key === 'Delete') {
                // Chame um método para remover o último ponto do contorno
                this.removeLastContourPoint();
            } else if (event.key === 'Delete' && this.currentAction === 'definingMedialDistalPoints' && this.selectedTooth) {
                this.dentalMap[this.selectedTooth].medial = null;
                this.dentalMap[this.selectedTooth].distal = null;
                this.updateInterfaceWithDentalData(this.dentalMap[this.selectedTooth]);
            } else if (this.activeObject && event.key === 'Delete') {
                // Se um objeto estiver ativo e a tecla Delete for pressionada
                this.removeObjectFromScene(this.activeObject);
                this.activeObject = null;
            }
        },
        removeLastContourPoint() {
            // Despache uma ação para remover o último ponto do contorno
            this.$store.dispatch('editor3D/removeLastContourPoint');
            // Atualize o contorno na cena
            this.updateContour();
        },
        toggleJaw(arcType) {
            this.scene.children.forEach((objeto) => {

                if (objeto.userData.modelType !== undefined) {

                    if (objeto.userData.modelType === arcType) {
                        objeto.visible = !objeto.visible;
                    }
                }
            })
        },
        toggleBoxHelper() {
            this.scene.children.forEach((objeto) => {

                if (objeto.userData.boxHelper !== undefined) {
                    objeto.visible = !objeto.visible;
                }
            })
        },
        toggleLight(type) {
            this.scene.children.forEach((objeto) => {

                if (objeto.userData.light !== undefined) {

                    if (objeto.userData.light === type) {
                        objeto.visible = !objeto.visible;
                    }
                }
            })
        },
        removeTriangle(activeMesh, triangleIndex) {
            if (!activeMesh || !activeMesh.geometry) {
                console.error('Nenhum objeto ativo ou geometria definida.');
                return;
            }

            const geometry = activeMesh.geometry;
            const positions = geometry.attributes.position.array;
            const normals = geometry.attributes.normal.array;

            // Calculando os índices corretos para remoção
            const start = triangleIndex * 9; // Cada triângulo tem 9 valores (3 vértices * 3 coordenadas)
            const end = start + 9;

            // Criando novos arrays excluindo os dados do triângulo selecionado
            const newPositionArray = [
                ...positions.slice(0, start),
                ...positions.slice(end, positions.length)
            ];
            const newNormalArray = [
                ...normals.slice(0, start),
                ...normals.slice(end, normals.length)
            ];

            // Atualizando a geometria
            geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(newPositionArray), 3));
            geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(newNormalArray), 3));

            // Se a geometria tiver índices, você precisará atualizá-los aqui

            // Sinalizando a necessidade de atualização
            geometry.attributes.position.needsUpdate = true;
            geometry.attributes.normal.needsUpdate = true;
        },
        handleModelClick(event) {
            const intersection = this.getPointOnModel(event);
            if (!intersection) return;

            if (this.currentMode !== "") return;

            const currentAction = this.$store.state.editor3D.currentAction;
            console.table(intersection)


            if (this.miniGun.active) {
                this.miniGun.object = this.activeObject.clone();
                this.miniGun.point = intersection.point;

                this.updateMarker();
                // const { candidateTriangles, borderTriangleIndices } = this.findFakeBorderTriangles(intersection.point);
                // const contourPoints = this.findContourPoints(candidateTriangles, borderTriangleIndices, intersection.point);
                // console.table(contourPoints);
                // this.$store.dispatch(
                //     "editor3D/setContourPoints",
                //     contourPoints
                // );
                // this.updateContour();
            }


            if (currentAction === 'creatingOcclusionPlan') {
                this.addPlaneSphereAtPoint(intersection.point);
                this.$store.dispatch('editor3D/addPointToOcclusionPlan', intersection);
            } else if (currentAction === 'markingContour') {
                this.$store.dispatch('editor3D/addContourPoint', intersection.point);
                this.updateContour(); // Atualiza a visualização do contorno
            } else if (currentAction === 'creatingDentalArch') {
                //this.addPlaneSphereAtPoint(intersection.point);
                this.$store.dispatch('editor3D/addPointToDentalArch', intersection.point);
                this.updateContour(); // Atualiza a visualização do contorno
            } else if (intersection.objectRef.userData.objectType && intersection.objectRef.userData.objectType === 'tooth') {
                this.transformControl.attach(intersection.objectRef);
                this.analyzeAdjacentTeeth(intersection.objectRef.userData.toothNumber);
            } else if (currentAction === 'definingMedialDistalPoints') {
                if (this.selectedTooth) {
                    if (!this.dentalMap[this.selectedTooth].medial) {
                        this.dentalMap[this.selectedTooth].medial = intersection.point.clone();
                        this.updateInterfaceWithDentalData(this.dentalMap[this.selectedTooth])
                    } else if (!this.dentalMap[this.selectedTooth].distal) {
                        this.dentalMap[this.selectedTooth].distal = intersection.point.clone();
                        console.table({ tooth: this.selectedTooth, dentalMapa: this.dentalMap[this.selectedTooth] });
                        this.$store.dispatch('editor3D/enterMedialDistalEditMode', 0);
                        this.updateInterfaceWithDentalData(this.dentalMap[this.selectedTooth])

                    }
                }
            }
        },
        scanDentalMapAndStartWorker() {
            if (!this.activeObject) {
                console.warn("No active object selected.");
                return;
            }

            Object.keys(this.dentalMap).forEach(selectedTooth => {
                const toothData = this.dentalMap[selectedTooth];
                if (toothData.medial && toothData.distal) {
                    this.startSegmentationWorker(selectedTooth);
                }
            });
        },
        startSegmentationWorker(selectedTooth) {
            // Obtenha os dados de posição da malha ativa (supondo que activeObject é uma malha)
            const positions = this.activeObject.geometry.attributes.position.array;
            const normals = this.activeObject.geometry.attributes.normal.array;

            // Suponha que medialPoint e distalPoint já estão definidos
            const medialPoint = this.dentalMap[selectedTooth].medial;
            const distalPoint = this.dentalMap[selectedTooth].distal;

            // Calcula o ponto central e o raio para a busca
            const centerPoint = {
                x: (medialPoint.x + distalPoint.x) / 2,
                y: (medialPoint.y + distalPoint.y) / 2,
                z: (medialPoint.z + distalPoint.z) / 2,
            };
            const length = Math.sqrt(
                (distalPoint.x - medialPoint.x) ** 2 +
                (distalPoint.y - medialPoint.y) ** 2 +
                (distalPoint.z - medialPoint.z) ** 2
            );
            const searchRadius = length;

            // Os dados precisam ser copiados para um novo ArrayBuffer se eles não forem instâncias de ArrayBuffer
            const positionsArrayBuffer = positions.buffer.slice(0);
            const normalsArrayBuffer = normals.buffer.slice(0);

            // Inicialize o Web Worker
            const worker = new Worker(new URL('@/workers/edgeFinder360.js', import.meta.url), { type: 'module' });

            this.iaActive = "orange";

            // Envie os dados clonados para o worker
            worker.postMessage({
                positions: positionsArrayBuffer,
                normals: normalsArrayBuffer,
                toothId: selectedTooth,
                medialPoint: { x: medialPoint.x, y: medialPoint.y, z: medialPoint.z },
                distalPoint: { x: distalPoint.x, y: distalPoint.y, z: distalPoint.z },
                centerPoint: centerPoint,
                searchRadius: searchRadius
            }, [positionsArrayBuffer, normalsArrayBuffer]); // Transfer list

            // Ouvir por mensagens do worker
            worker.onmessage = (event) => {
                // Atualize a interface com os pontos de borda recebidos
                const { type, data } = event.data;
                if (type == 'result') {
                    this.iaActive = (this.iaActive === "orange" ? "gray" : "orange");
                    const { borderPoints, toothId, dataMatrix } = data;
                    this.dentalMap[toothId].borderPoints = borderPoints;
                    this.updateInterfaceWithDentalData(this.dentalMap[toothId]);
                    this.dataMatrix = dataMatrix;
                    this.statusMessage = "";
                    this.iaActive = "gray";
                    // Encerra o worker
                    worker.terminate();
                } else if (type == 'point') {
                    const geometry = new THREE.SphereGeometry(data.radius, 4, 4); // Tamanho da esfera ajustável
                    const material = new THREE.MeshBasicMaterial({ color: data.color });
                    const sphere = new THREE.Mesh(geometry, material);
                    sphere.position.set(data.point.x, data.point.y, data.point.z);
                    this.activeObject.add(sphere);
                } else if (type === 'line') {
                    // Cria a geometria da linha com dois pontos: start e end
                    const material = new THREE.LineBasicMaterial({ color: data.color });
                    const geometry = new THREE.BufferGeometry().setFromPoints([
                        new THREE.Vector3(data.start.x, data.start.y, data.start.z),
                        new THREE.Vector3(data.end.x, data.end.y, data.end.z)
                    ]);
                    const line = new THREE.Line(geometry, material);

                    this.activeObject.add(line);
                } else if (type == 'message') {
                    const { text } = data;
                    this.statusMessage = text;
                    //console.warn(text);
                } else if (type == 'sphere') {
                    const geometry = new THREE.SphereGeometry(data.radius, 16, 16); // Tamanho da esfera ajustável
                    const material = new THREE.MeshBasicMaterial({ color: data.color, transparent: true, opacity: 0.25 });
                    const sphere = new THREE.Mesh(geometry, material);

                    sphere.position.set(data.point.x, data.point.y, data.point.z);
                    this.activeObject.add(sphere);
                } else if (type === 'bboxData') {
                    const min = new THREE.Vector3(data.min.x, data.min.y, data.min.z);
                    const max = new THREE.Vector3(data.max.x, data.max.y, data.max.z);

                    // Cria os vértices da BoundingBox
                    const vertices = [
                        new THREE.Vector3(min.x, min.y, min.z), new THREE.Vector3(max.x, min.y, min.z),
                        new THREE.Vector3(min.x, max.y, min.z), new THREE.Vector3(max.x, max.y, min.z),
                        new THREE.Vector3(min.x, min.y, max.z), new THREE.Vector3(max.x, min.y, max.z),
                        new THREE.Vector3(min.x, max.y, max.z), new THREE.Vector3(max.x, max.y, max.z)
                    ];

                    // Define as arestas da BoundingBox
                    const edges = [
                        [0, 1], [1, 3], [3, 2], [2, 0], // base inferior
                        [4, 5], [5, 7], [7, 6], [6, 4], // base superior
                        [0, 4], [1, 5], [2, 6], [3, 7]  // lados verticais
                    ];

                    const material = new THREE.LineBasicMaterial({ color: 0xff00ff }); // Cor magenta para a BoundingBox

                    edges.forEach(pair => {
                        const geometry = new THREE.BufferGeometry().setFromPoints([
                            vertices[pair[0]], vertices[pair[1]]
                        ]);
                        const line = new THREE.Line(geometry, material);
                        this.activeObject.add(line); // Adiciona a linha ao objeto ativo na cena
                    });
                }



            };
        },
        addTriangleToGeometry(geometry, v1, v2, v3) {
            // Assume que v1, v2, e v3 são instâncias de THREE.Vector3 para os vértices do novo triângulo

            // Obtenha o atributo de posição existente
            const positionAttribute = geometry.getAttribute('position');
            const vertexCount = positionAttribute.count;

            // Crie um novo array com espaço extra para os novos vértices
            const newPositions = new Float32Array((vertexCount + 3) * 3);

            // Copie os vértices existentes para o novo array
            newPositions.set(positionAttribute.array);

            // Adicione os novos vértices no final do array
            const startIndex = vertexCount * 3;
            newPositions.set([v1.x, v1.y, v1.z], startIndex);
            newPositions.set([v2.x, v2.y, v2.z], startIndex + 3);
            newPositions.set([v3.x, v3.y, v3.z], startIndex + 6);

            // Atualize o atributo de posição com o novo array
            geometry.setAttribute('position', new THREE.BufferAttribute(newPositions, 3));
            geometry.computeVertexNormals(); // Recalcular as normais para a iluminação correta

            // Informa ao Three.js que os atributos da geometria mudaram
            geometry.attributes.position.needsUpdate = true;
        },
        addTriangleToSmoothMesh(v1, v2, v3) {
            // Adiciona os vértices do triângulo à lista de vértices da geometria suavizada
            this.smoothGeometryVertices.push(v1.x, v1.y, v1.z);
            this.smoothGeometryVertices.push(v2.x, v2.y, v2.z);
            this.smoothGeometryVertices.push(v3.x, v3.y, v3.z);
        },
        updateActiveObject() {
            // Extrair os vértices existentes do activeObject
            const existingVertices = this.activeObject.geometry.getAttribute('position').array;

            // Criar um novo array para combinar os vértices existentes com os novos
            const combinedVertices = new Float32Array(existingVertices.length + this.smoothGeometryVertices.length);

            // Copiar os vértices existentes para o novo array
            combinedVertices.set(existingVertices);

            // Adicionar os novos vértices ao final do novo array
            combinedVertices.set(this.smoothGeometryVertices, existingVertices.length);

            // Criar a nova BufferGeometry com os vértices combinados
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(combinedVertices, 3));

            // Calcula as normais para a iluminação
            geometry.computeVertexNormals();
            this.calculateVertexNormals(geometry)
            geometry.getAttribute('position').setUsage(THREE.DynamicDrawUsage);

            // Substitui a geometria do activeObject pela nova geometria
            this.activeObject.geometry.dispose(); // Descarta a geometria antiga
            this.activeObject.geometry = geometry;

            this.activeObject.geometry.attributes.position.needsUpdate = true;
            this.activeObject.geometry.attributes.normal.needsUpdate = true;
            this.activeObject.material.needsUpdate = true;

            this.activeObject.geometry.computeBoundingSphere();
            this.activeObject.geometry.computeBoundingBox();


            // Reseta os arrays para uso futuro
            this.newVertices = [];
        },
        createSmoothGeometry() {
            const vertices = new Float32Array(this.smoothGeometryVertices);
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

            let smoothMesh = this.scene.children.find(obj => obj.userData.name === "smoothMesh");
            if (!smoothMesh) {
                // Se não existir, crie-o e adicione à cena
                const material = this.activeObject.material ? this.activeObject.material.clone() :
                    new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide, transparent: true, opacity: 0.5 });
                smoothMesh = new THREE.Mesh(geometry, material);
                smoothMesh.userData.name = "smoothMesh";
                this.scene.add(smoothMesh);
            } else {
                // Se o smoothMesh já existe, atualize sua geometria
                smoothMesh.geometry.dispose(); // Descarte a geometria antiga
                smoothMesh.geometry = geometry;
                smoothMesh.geometry.attributes.position.needsUpdate = true; // Marca a geometria para atualização
            }
            smoothMesh.geometry.computeVertexNormals();
        },
        findClosestVertex(index, vertex, vertices) {
            let closestVertex = null;
            let shortestDistance = Infinity;

            vertices.forEach((v, i) => {
                // Ignora o próprio vértice baseado no índice
                if (i > index && v.x !== vertex.x && v.y !== vertex.y && v.z !== vertex.z) {
                    const distance = vertex.distanceTo(v);
                    if (distance < shortestDistance && distance <= 5) {
                        closestVertex = i;
                        shortestDistance = distance;
                    }
                }
            });

            return closestVertex;
        },
        uniqueVerticesFunction(vertices) {
            const uniqueStrings = new Set();
            const uniqueVertices = [];

            vertices.forEach(v => {
                const vString = JSON.stringify(v);
                if (!uniqueStrings.has(vString)) {
                    uniqueStrings.add(vString);
                    uniqueVertices.push(v.clone());
                }
            });

            return uniqueVertices;
        },
        deindexGeometry(indexedGeometry) {
            const nonIndexedGeometry = new THREE.BufferGeometry();

            // Se a geometria original é indexada, ela terá um atributo 'index'
            if (indexedGeometry.index) {
                const indices = indexedGeometry.index.array;
                const positions = indexedGeometry.attributes.position;
                const newPositions = [];
                // Se você tem outros atributos como normal, uv, etc, você deverá processá-los de maneira similar

                // Replicar vértices com base nos índices
                for (let i = 0; i < indices.length; i++) {
                    const index = indices[i];
                    newPositions.push(positions.getX(index), positions.getY(index), positions.getZ(index));
                }

                // Atualizar a geometria não indexada com os novos dados
                nonIndexedGeometry.setAttribute('position', new THREE.Float32BufferAttribute(newPositions, 3));
                // Repetir para outros atributos se necessário

            } else {
                // Se a geometria não é indexada, simplesmente clonar
                return indexedGeometry.clone();
            }

            return nonIndexedGeometry;
        },
        identifyBorderVerticesDelaunay(uniqueVertices) {
            const activeObject = this.activeObject;

            // Verificar se existe um activeObject
            if (!activeObject) {
                console.error('No active object found.');
                return;
            }

            // Encontrar o mesh com userData.objectType igual a "gums" dentro do activeObject
            const gumsMesh = activeObject.children.find(child => child.userData.objectType === "gums");
            if (!gumsMesh) {
                console.error('No gums mesh found in the active object.');
                return;
            }

            // Verificar se o gumsMesh tem geometria
            if (!gumsMesh.geometry) {
                console.error('No geometry found in the gums mesh.');
                return;
            }

            this.intersectionPoints = [];
            this.intermediatePoints = [];

            const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane);
            const negateNormal = (activeObject.userData.modelType === 'mandibular') ? true : false;
            const cuttingPlaneNormal = new THREE.Vector3(0, 0, 1); // Normal local do plano é Z+
            cuttingPlaneNormal.applyQuaternion(cuttingPlane.quaternion).normalize();
            const rayDirection = (negateNormal) ? cuttingPlaneNormal.clone().negate() : cuttingPlaneNormal.clone();

            for (let i = 0; i < uniqueVertices.length; i++) {
                const vertex = uniqueVertices[i];
                let raycaster = new THREE.Raycaster(vertex.clone(), rayDirection);

                let intersects = raycaster.intersectObject(cuttingPlane);
                if (intersects.length > 0) {
                    const intersectionPoint = intersects[0].point;
                    const scaleFactor = 1.0;
                    const scaledPoint = intersectionPoint.clone().multiplyScalar(scaleFactor);
                    // Adicionando o ponto intermediário
                    const midPoint = new THREE.Vector3().lerpVectors(vertex, scaledPoint, 0.5);

                    this.intermediatePoints.push(midPoint); // Adiciona o ponto intermediário
                    this.intersectionPoints.push(scaledPoint); // Adiciona o ponto escalado como antes
                }
            }


            this.interIntersectionPoints = [];
            const maxPoints = 1000;

            // Encontrar outros meshes com o mesmo userData.modelType na cena, mas que não estejam dentro de activeObject
            const modelType = this.activeObject.userData.modelType;
            const otherMeshes = this.scene.children.filter(child =>
                child !== this.activeObject && // Certifique-se de não incluir o próprio activeObject
                child.userData.modelType === modelType && // Mesmo modelType
                child.geometry // Verifique se o mesh tem geometria
            );

            // Iterar sobre todos os otherMeshes encontrados
            otherMeshes.forEach(mesh => {
                const positionAttribute = mesh.geometry.getAttribute('position');

                // Criar um array com os índices dos vértices
                const vertexIndices = Array.from({ length: positionAttribute.count }, (_, i) => i);

                // Embaralhar os índices para selecionar um subconjunto aleatório
                for (let i = vertexIndices.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [vertexIndices[i], vertexIndices[j]] = [vertexIndices[j], vertexIndices[i]];
                }

                // Iterar sobre um número máximo de pontos aleatórios (até maxPoints)
                const selectedIndices = vertexIndices.slice(0, maxPoints);
                for (let i = 0; i < selectedIndices.length; i++) {
                    const index = selectedIndices[i];
                    const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, index);

                    // Disparar o raio a partir do vértice na direção do plano de corte
                    const raycaster = new THREE.Raycaster(vertex.clone(), rayDirection.clone().normalize());
                    const intersects = raycaster.intersectObject(cuttingPlane);

                    if (intersects.length > 0) {
                        // Obter o ponto de interseção
                        const intersectionPoint = intersects[0].point;

                        // Escalar o ponto de interseção, se necessário
                        const scaleFactor = 1.0 + Math.random() * 0;
                        const scaledPoint = intersectionPoint.clone().multiplyScalar(scaleFactor);

                        // Adicionar o ponto de interseção à nuvem de pontos
                        this.interIntersectionPoints.push(scaledPoint);

                        // Se já atingiu o número máximo de pontos, pare
                        if (this.interIntersectionPoints.length >= maxPoints) {
                            break;
                        }
                    }
                }
            });

            // Verificar se foram encontrados pontos de interseção
            if (this.interIntersectionPoints.length === 0) {
                console.warn('No intersection points were found.');
            } else {
                console.log('Intersection points generated:', this.interIntersectionPoints.length);
            }

            const combinedVertices = [...this.intersectionPoints, ...this.intermediatePoints, ...uniqueVertices];
            const newGeometry = this.delaunayTriangulation([...this.intersectionPoints, ...this.interIntersectionPoints]);
            this.removeLargeTrianglesRaycaster(newGeometry, 15, gumsMesh);

            const newGeometryWalls = this.delaunayTriangulation(combinedVertices);
            this.removeLargeTrianglesRaycaster(newGeometryWalls, 15, gumsMesh);

            const originalGeometry = BufferGeometryUtils.mergeGeometries([gumsMesh.geometry]);

            if (newGeometry) {
                const deIndexedGeometry = this.deindexGeometry(newGeometry);
                deIndexedGeometry.computeVertexNormals();

                const deIndexedGeometryWall = this.deindexGeometry(newGeometryWalls);
                deIndexedGeometryWall.computeVertexNormals();

                const deIndexedOriginalGeometry = this.deindexGeometry(originalGeometry);
                deIndexedOriginalGeometry.computeVertexNormals();

                console.table({ deIndexedGeometry, deIndexedGeometryWall, deIndexedOriginalGeometry });

                const geometries = [deIndexedOriginalGeometry, deIndexedGeometryWall, deIndexedGeometry];
                const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries, false);

                if (mergedGeometry) {
                    gumsMesh.geometry.dispose();
                    gumsMesh.material.dispose();

                    gumsMesh.geometry = mergedGeometry;
                    gumsMesh.geometry.computeVertexNormals();

                    gumsMesh.material = new THREE.MeshPhongMaterial({
                        color: 0xe3d3d9, //0xffc0cb 
                        specular: 0x333366,
                        shininess: 100,
                        side: THREE.DoubleSide,
                        transparent: true,
                        opacity: 1,
                    });

                    this.calculateVertexNormals(gumsMesh.geometry);
                    gumsMesh.updateMatrix();
                    gumsMesh.material.needsUpdate = true;
                } else {
                    console.error('Falha ao mesclar as geometrias.');
                }
            } else {
                console.error('Falha ao criar a costura 3D com a triangulação de Delaunay.');
            }
        },
        identifyBorderVerticesDelaunay_(uniqueVertices) {
            const activeObject = this.activeObject;
            if (activeObject && activeObject.geometry) {
                this.intersectionPoints = [];

                const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane);
                const negateNormal = (activeObject.userData.modelType === 'mandibular') ? true : false;


                for (let i = 0; i < uniqueVertices.length; i++) {
                    const vertex = uniqueVertices[i];
                    const rayDirection = (negateNormal) ? cuttingPlane.userData.normal.clone().negate().normalize() : cuttingPlane.userData.normal.clone().normalize();
                    let raycaster = new THREE.Raycaster(vertex.clone(), rayDirection);

                    let intersects = raycaster.intersectObject(cuttingPlane);
                    if (intersects.length > 0) {
                        const intersectionPoint = intersects[0].point;
                        // Definir o fator de escala
                        const scaleFactor = 1.05;
                        // Escalar o ponto de interseção
                        const scaledPoint = intersectionPoint.clone().multiplyScalar(scaleFactor);
                        // Adicionar o ponto escalado ao array
                        this.intersectionPoints.push(scaledPoint);

                    }
                }

                // Combina os vértices únicos e os pontos de interseção em um único array
                // eslint-disable-next-line no-unused-vars
                const combinedVertices = [...this.intersectionPoints, ...uniqueVertices];

                // Utiliza a função genérica de triangulação de Delaunay para gerar a nova geometria
                const newGeometry = this.delaunayTriangulation(this.intersectionPoints);
                this.removeLargeTrianglesRaycaster(newGeometry, 15, activeObject)

                const newGeometryWalls = this.delaunayTriangulation(combinedVertices);
                this.removeLargeTrianglesRaycaster(newGeometryWalls, 15, activeObject)

                const originalGeometry = BufferGeometryUtils.mergeGeometries([activeObject.geometry]);

                if (newGeometry) {
                    const deIndexedGeometry = this.deindexGeometry(newGeometry);
                    deIndexedGeometry.computeVertexNormals();

                    const deIndexedGeometryWall = this.deindexGeometry(newGeometryWalls);
                    deIndexedGeometryWall.computeVertexNormals();

                    const deIndexedOriginalGeometry = this.deindexGeometry(originalGeometry);
                    deIndexedOriginalGeometry.computeVertexNormals();

                    // console.table({ deIndexedGeometry, deIndexedGeometryWall, deIndexedOriginalGeometry })

                    // Utiliza BufferGeometryUtils para mesclar a geometria existente com a nova geometria
                    const geometries = [deIndexedOriginalGeometry, deIndexedGeometryWall, deIndexedGeometry];
                    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries, false);

                    if (mergedGeometry) {
                        activeObject.geometry.dispose(); // Libera a memória da geometria antiga
                        activeObject.material.dispose();

                        // const iterations = 2;

                        //     const params = {
                        //       split: true, // optional, default: true
                        //       uvSmooth: false, // optional, default: false
                        //       preserveEdges: false, // optional, default: false
                        //       flatOnly: false, // optional, default: false
                        //       maxTriangles: 50000, // optional, default: Infinity
                        //     };

                        //     activeObject.geometry  = LoopSubdivision.modify(
                        //         mergedGeometry,
                        //       iterations,
                        //       params
                        //     );

                        activeObject.geometry = mergedGeometry;
                        activeObject.geometry.computeVertexNormals(); // Recalcula as normais para iluminação correta

                        activeObject.material = new THREE.MeshPhongMaterial({ color: 0x02BEBE, specular: 0x000000, emissive: 0x000000, shininess: 0, side: THREE.DoubleSide, transparent: true, opacity: 1 });
                        this.calculateVertexNormals(activeObject.geometry);
                        activeObject.updateMatrix();
                        activeObject.material.needsUpdate = true;
                    } else {
                        console.error('Falha ao mesclar as geometrias.');
                    }
                } else {
                    console.error('Falha ao criar a costura 3D com a triangulação de Delaunay.');
                }
            }
        },
        identifyBorderVertices() {
            const activeObject = this.activeObject;
            if (activeObject && activeObject.geometry) {
                const edgeVertices = [];

                const positionAttribute = this.smoothingMeshEdges.attributes.position;
                for (let i = 0; i < positionAttribute.count; i++) {
                    const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                    edgeVertices.push(vertex);
                }

                const uniqueVertices = this.uniqueVerticesFunction(edgeVertices);
                this.smoothGeometryVertices = [];
                this.intersectionPoints = [];

                const negateNormal = (activeObject.userData.modelType === 'mandibular') ? true : false;

                // Certifique-se de que a normal está correta e apontando para fora da borda
                const cuttingPlane = this.scene.children.find(child => child.userData.isCuttingPlane);
                for (let i = 0; i < uniqueVertices.length; i++) {
                    const vertex = uniqueVertices[i];
                    const nextVertex = uniqueVertices[this.findClosestVertex(i, vertex.clone(), uniqueVertices)];

                    if (nextVertex !== undefined) {
                        // Crie um raio na direção oposta à normal do plano de corte para o vertex atual
                        const rayDirection = (negateNormal) ? cuttingPlane.userData.normal.clone().negate().normalize() : cuttingPlane.userData.normal.clone().normalize();
                        let raycaster = new THREE.Raycaster(vertex.clone(), rayDirection);

                        // Intersecta o raio com o plano de corte para o vertex atual
                        let intersects = raycaster.intersectObject(cuttingPlane);

                        if (intersects.length > 0) {
                            const intersectionPoint = intersects[0].point;

                            // Armazena o ponto de interseção
                            this.intersectionPoints.push(intersectionPoint);

                            // Repita o processo para o nextVertex
                            raycaster.set(nextVertex.clone(), rayDirection);

                            intersects = raycaster.intersectObject(cuttingPlane);

                            if (intersects.length > 0) {
                                const nextIntersectionPoint = intersects[0].point;

                                // Armazena o ponto de interseção
                                this.intersectionPoints.push(intersectionPoint);

                                // Primeiro triângulo: vertex -> intersectionPoint -> nextIntersectionPoint
                                const v1 = vertex;
                                const v2 = intersectionPoint;
                                const v3 = nextIntersectionPoint;
                                this.addTriangleToSmoothMesh(v1, v2, v3);

                                // Segundo triângulo: nextIntersectionPoint -> nextVertex -> vertex
                                // Este triângulo preenche o espaço entre os vértices e suas projeções no plano
                                const v4 = nextIntersectionPoint;
                                const v5 = nextVertex.clone();
                                const v6 = vertex.clone();
                                this.addTriangleToSmoothMesh(v4, v5, v6);
                            }
                        }
                    }

                }
                this.updateActiveObject();
            }
        },
        getPointOnModel(event, ignoreObject = null) {
            const mouse = new THREE.Vector2();
            const rect = this.renderer.domElement.getBoundingClientRect();
            mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            mouse.y = - ((event.clientY - rect.top) / rect.height) * 2 + 1;

            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, this.camera);

            // Filtra apenas objetos visíveis e, se fornecido, ignora o objeto especificado (a esfera sendo arrastada)
            let objects = this.scene.children.filter(child => child.visible);

            if (ignoreObject) {
                const ignoreUUID = ignoreObject.uuid;
                objects = objects.filter(child => (child.type !== "Group" || child.userData.modelType) && child.uuid !== ignoreUUID);
                //objects = objects.filter(child => child.uuid !== ignoreUUID);
            }

            const intersects = raycaster.intersectObjects(objects, true);

            if (intersects.length > 0) {
                if (event.ctrlKey) {
                    this.removeTriangle(intersects[0].object, intersects[0].faceIndex);
                }
                // Retorna o ponto mais próximo da câmera que não é o objeto ignorado
                for (let i = 0; i < intersects.length; i++) {
                    if (intersects[i].object !== ignoreObject) {
                        return { point: intersects[i].point, objectRef: intersects[i].object, intersects: intersects[0].faceIndex };
                    }
                }
            }

            return null;
        },
        findGumInScene() {
            let foundGum = null;

            // Função auxiliar para buscar recursivamente a gengiva dentro de grupos
            function searchGum(object) {
                if (object instanceof THREE.Group) {
                    object.traverse((child) => {
                        if (child.userData.objectType === "gums") {
                            foundGum = child;
                        }
                    });
                }
            }

            // Função auxiliar para buscar pela maxila ou mandíbula caso a gengiva não seja encontrada
            function searchMaxillaryOrMandibular(object) {
                if (object.userData.modelType === "maxillary" || object.userData.modelType === "mandibular") {
                    foundGum = object;
                }
            }

            // Busca inicial no nível superior da cena
            this.scene.children.forEach((child) => {
                if (child instanceof THREE.Group) {
                    searchGum(child);
                } else if (child.userData.objectType === "gums") {
                    // Caso a gengiva não esteja dentro de um grupo, mas diretamente na cena
                    foundGum = child;
                }
            });

            // Se não encontrar a gengiva, procura pela maxila ou mandíbula
            if (!foundGum) {
                this.scene.traverse((child) => {
                    searchMaxillaryOrMandibular(child);
                });
            }

            return foundGum;
        },
        findGumsInScene() {
            let foundGums = []; // Lista para acumular gengivas encontradas
            let alternativeObjects = []; // Lista para maxila ou mandíbula, se não encontrar gengivas

            // Função auxiliar para buscar recursivamente a gengiva dentro de grupos
            function searchGum(object) {
                if (object instanceof THREE.Group) {
                    object.traverse((child) => {
                        if (child.userData.objectType === "gums") {
                            child.userData.modelType = object.userData.modelType;
                            foundGums.push(child); // Adiciona à lista de gengivas encontradas
                        }
                    });
                }
            }

            // Função auxiliar para buscar pela maxila ou mandíbula
            function searchMaxillaryOrMandibular(object) {
                if (object.userData.modelType === "maxillary" || object.userData.modelType === "mandibular") {
                    alternativeObjects.push(object); // Adiciona à lista alternativa
                }
            }

            // Busca inicial no nível superior da cena
            this.scene.children.forEach((child) => {
                if (child instanceof THREE.Group) {
                    searchGum(child);
                } else if (child.userData.objectType === "gums") {
                    foundGums.push(child); // Adiciona diretamente à lista de gengivas encontradas
                }
            });

            // Se não encontrar gengivas, procura pela maxila ou mandíbula
            if (foundGums.length === 0) {
                this.scene.traverse((child) => {
                    searchMaxillaryOrMandibular(child);
                });
            }

            // Retorna as gengivas encontradas ou, se não houver, os objetos alternativos
            return foundGums.length > 0 ? foundGums : alternativeObjects;
        },
        findDynamicGumInScene() {
            let foundDynamicGum = null;

            // Função auxiliar para buscar recursivamente a gengiva dentro de grupos
            function searchGum(object) {
                if (object instanceof THREE.Group) {
                    object.traverse((child) => {
                        if (child.userData.objectType === "dynamic") {
                            foundDynamicGum = child;
                        }
                    });
                }
            }

            // Busca inicial no nível superior da cena
            this.scene.children.forEach((child) => {
                if (child instanceof THREE.Group) {
                    searchGum(child);
                } else if (child.userData.objectType === "dynamic") {
                    // Caso a gengiva não esteja dentro de um grupo, mas diretamente na cena
                    foundDynamicGum = child;
                }
            });

            return foundDynamicGum;
        },
        storeOriginalVertices(gumGeometry) {
            const gumVertices = gumGeometry.attributes.position;
            const originalVertices = [];

            for (let i = 0; i < gumVertices.count; i++) {
                const vertex = new THREE.Vector3().fromBufferAttribute(gumVertices, i);
                originalVertices.push(vertex);
            }

            return originalVertices;
        },
        deformGumToFollowTooth(gumGeometry, toothMesh, originalVertices) {
            const gumVertices = gumGeometry.attributes.position;
            const toothSphereRadius = 0.5; // Ajuste conforme necessário
            const toothPosition = toothMesh.position.clone();
            const vertex = new THREE.Vector3();
            const waveAmplitude = 0.1; // Ajuste a amplitude da "onda"
            const waveSteepness = 10 // Ajuste a íngreme da "onda"

            for (let i = 0; i < gumVertices.count; i++) {
                vertex.fromBufferAttribute(gumVertices, i);
                const originalVertex = originalVertices[i];
                const distanceToTooth = vertex.distanceTo(toothPosition);

                if (distanceToTooth < toothSphereRadius) {
                    // Calcular um fator de deformação baseado na distância
                    const scalarField = 1 - (distanceToTooth / toothSphereRadius);
                    const deformation = Math.exp(-waveSteepness * scalarField) * waveAmplitude;

                    // Direção da deformação
                    const direction = toothPosition.clone().add(vertex).normalize();
                    direction.multiplyScalar(deformation);

                    // Elevação adicional para criar o efeito de "onda"
                    direction.z += Math.sin(scalarField * Math.PI) * deformation;

                    vertex.add(direction);
                    gumVertices.setXYZ(i, vertex.x, vertex.y, vertex.z);
                } else {
                    // Interpolar de volta para a posição original se o dente estiver longe
                    vertex.lerp(originalVertex, 0.1); // Ajuste o fator de interpolação conforme necessário
                    gumVertices.setXYZ(i, vertex.x, vertex.y, vertex.z);
                }
            }

            gumVertices.needsUpdate = true;
            gumGeometry.computeVertexNormals();
        },
        updateCameraFocusBasedOnDentalArches() {
            // Reinicia a bounding box para cada chamada
            let combinedBBox = new THREE.Box3();

            // Percorre todos os objetos na cena
            this.scene.traverse(object => {
                // Verifica se o objeto é um dos modelos dentários de interesse
                if (object.userData.modelType === 'maxillary' || object.userData.modelType === 'mandibular') {
                    // Se sim, atualiza a bounding box combinada para incluir este objeto
                    combinedBBox.union(new THREE.Box3().setFromObject(object));
                }
            });

            // Calcula o centro da bounding box combinada
            let center = new THREE.Vector3();
            combinedBBox.getCenter(center);

            // Verifica se o centro realmente mudou antes de atualizar
            if (!this.controls.target.equals(center)) {
                this.controls.target.copy(center);
                this.controls.update();
            }
        },
        calculateSceneBoundingBox() {
            const box = new THREE.Box3();
            box.setFromObject(this.activeObject); // 'this.scene' é o objeto THREE.Scene

            return box;
        },
        initializeOctreeWithTeeth() {
            const sceneBoundingBox = this.calculateSceneBoundingBox();
            const octreeBoundary = new Box(
                (sceneBoundingBox.min.x + sceneBoundingBox.max.x) / 2,
                (sceneBoundingBox.min.y + sceneBoundingBox.max.y) / 2,
                (sceneBoundingBox.min.z + sceneBoundingBox.max.z) / 2,
                Math.max(sceneBoundingBox.max.x - sceneBoundingBox.min.x, sceneBoundingBox.max.y - sceneBoundingBox.min.y, sceneBoundingBox.max.z - sceneBoundingBox.min.z) / 2
            );

            this.octree = new Octree(octreeBoundary, 2);

            const teeth = this.findTeethInScene();

            teeth.forEach(tooth => {
                const toothGeometry = tooth.geometry;

                if (toothGeometry instanceof THREE.BufferGeometry) {
                    const positions = toothGeometry.attributes.position;
                    const vertices = [];

                    for (let i = 0; i < positions.count; i++) {
                        const position = new THREE.Vector3().fromBufferAttribute(positions, i);
                        // Ajuste a posição com a posição do objeto "dente"
                        const realPosition = position.applyMatrix4(tooth.matrixWorld);
                        vertices.push(realPosition);
                    }

                    const boundingBox = computeBoundingBox(vertices);

                    const octreeObject = {
                        boundary: boundingBox,
                        vertices: vertices,
                        position: tooth.position.clone(), // Use a posição do objeto "dente"
                        userData: tooth.userData
                    };

                    this.octree.insert(octreeObject);
                }
            });
            console.table(this.octree)
        },
        initThree() {
            // Cria a cena
            this.scene = new THREE.Scene();

            THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
            THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
            THREE.Mesh.prototype.raycast = acceleratedRaycast;

            // Cria a câmera
            const width = this.$refs.threejsContainer.clientWidth;
            const height = this.$refs.threejsContainer.clientHeight;
            this.camera = new THREE.PerspectiveCamera(5, width / height, 0.1, 5000);
            this.camera.position.x = 0;
            this.camera.position.y = 0;
            this.camera.position.z = 700;

            // Cria o renderer e anexa o canvas ao DOM
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setPixelRatio(window.devicePixelRatio * 3);
            this.renderer.setSize(width, height);
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.renderer.setClearColor(this.backgroundColor);
            this.$refs.threejsContainer.appendChild(this.renderer.domElement);

            this.controls = new ArcballControls(this.camera, this.renderer.domElement);

            // Configura os limites de zoom
            this.controls.minDistance = 50;
            this.controls.maxDistance = 2000;

            // Ativa o amortecimento para uma experiência de zoom mais suave (opcional)
            this.controls.enableDamping = false;
            this.controls.dampingFactor = 0.25;
            this.controls.enableZoom = true;
            this.controls.enablePan = true;
            this.controls.enableAnimations = false;
            this.controls.rotateSpeed = 0.5
            this.controls.wMax = 0;

            this.controls.zoomSpeed = 0.2;

            this.controls.setMouseAction("ROTATE", 2, null);
            this.controls.setMouseAction("PAN", 1, null);
            this.controls.setMouseAction("FOV", 0, "CTRL");
            this.controls.unsetMouseAction(0, null);
            //this.controls.unsetMouseAction(2, null);

            this.controls.update();

            // Luz ambiente
            const ambientLight = new THREE.AmbientLight(0x888888, 1);
            ambientLight.userData = { light: "ambient", name: this.$t('ambientLight'), hide: true }
            ambientLight.visible = true;
            this.scene.add(ambientLight);

            // Luz direcional principal
            const directionalLight1 = new THREE.DirectionalLight(0xffffff, 2);
            directionalLight1.position.set(0, 1500, 1500); // Posicionada para iluminar de cima e da frente
            directionalLight1.userData = { light: "directional1", name: this.$t('directionalLight'), hide: true }
            directionalLight1.visible = true;
            this.scene.add(directionalLight1);

            // Luz direcional secundária para iluminar o lado oposto do modelo
            const directionalLight2 = new THREE.DirectionalLight(0xffffff, 2);
            directionalLight2.position.set(0, -1500, -1500); // Posicionada para iluminar de baixo e de trás
            directionalLight2.userData = { light: "directional2", name: this.$t('directionalLight'), hide: true }
            directionalLight2.visible = true;
            this.scene.add(directionalLight2);


            // Luz direcional principal
            const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight3.position.set(1500, 0, 0); // Posicionada para iluminar de cima e da frente
            directionalLight3.userData = { light: "directional3", name: this.$t('directionalLight'), hide: true }
            this.scene.add(directionalLight3);


            // Luz direcional secundária para iluminar o lado oposto do modelo
            const directionalLight4 = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight4.position.set(-1500, 0, 0); // Posicionada para iluminar de baixo e de trás
            directionalLight4.userData = { light: "directional4", name: this.$t('directionalLight'), hide: true }
            this.scene.add(directionalLight4);

            // Luz direcional principal
            const directionalLight5 = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight5.position.set(0, 1500, 0); // Posicionada para iluminar de cima
            directionalLight5.userData = { light: "directional5", name: this.$t('directionalLight'), hide: true }
            directionalLight5.visible = false;
            this.scene.add(directionalLight5);


            // Luz direcional secundária para iluminar o lado oposto do modelo
            const directionalLight6 = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight6.position.set(0, -1500, 0); // Posicionada para iluminar de baixo
            directionalLight6.userData = { light: "directional6", name: this.$t('directionalLight'), hide: true }
            directionalLight6.visible = false;
            this.scene.add(directionalLight6);


            this.transformControl = new TransformControls(this.camera, this.renderer.domElement);
            this.transformControl.setMode("translate");
            this.transformControl.userData = { name: this.$t('transformControl'), hide: true }

            // Removendo a child com isTransformControlsPlane = true
            for (let i = this.transformControl.children.length - 1; i >= 0; i--) {
                const child = this.transformControl.children[i];
                if (child.isTransformControlsPlane) {
                    //this.transformControl.remove(child);
                }
            }


            // Removendo a child do rotate
            // for (let i = this.transformControl.children[0].gizmo.rotate.children.length - 1; i >= 0; i--) {
            //     const child = this.transformControl.children[0].gizmo.rotate.children[i];
            //     if (child.name !== 'E') {
            //         this.transformControl.children[0].gizmo.rotate.remove(child);
            //     }
            // }


            this.transformControl.traverse(function (child) {
                if (child.material) {
                    child.material.transparent = true;
                    child.material.opacity = 0.20; // Ajuste o valor da opacidade conforme necessário
                    child.material.needsUpdate = true;
                }
            });


            // Evento dragging-changed para ajustar a visibilidade e opacidade durante o arraste
            this.transformControl.addEventListener('dragging-changed', event => {
                if (event.value) {
                    this.transformControl.children.forEach(child => {
                        if (child.type === 'TransformControlsGizmo') {
                            child.visible = true;
                        }
                    });
                } else {
                    if (this.transformControl.object.userData.objectType && this.transformControl.object.userData.objectType === 'tooth') {
                        const tooth = this.transformControl.object.userData.toothNumber;
                        this.dentalMap[tooth].centerOfMass = this.transformControl.object.position.clone();
                        this.closeGapsBetweenTeethAndGum()
                    }
                    this.transformControl.children.forEach(child => {
                        if (child.type === 'TransformControlsGizmo') {
                            child.visible = true;
                        }
                    });
                }
            });

            this.scene.add(this.transformControl);


            // Adicionar o ouvinte de evento ao TransformControls
            // this.transformControl.addEventListener('change', (event) => {
            //     if (this.transformControl.object.userData.objectType && this.transformControl.object.userData.objectType === 'tooth') {
            //         this.analyzeAdjacentTeeth(this.transformControl.object.userData.toothNumber)
            //     }
            // });

            this.composer = new EffectComposer(this.renderer);

            // Render Pass
            this.renderPass = new RenderPass(this.scene, this.camera);
            this.composer.addPass(this.renderPass);

            this.fxaaPass = new SMAAPass(width * this.renderer.getPixelRatio(), height * this.renderer.getPixelRatio());
            this.composer.addPass(this.fxaaPass);

            this.animate();

        },
        animate() {
            // Salva o ID do requestAnimationFrame para poder cancelar no futuro
            this.frameId = window.requestAnimationFrame(this.animate);
            //const deltaTime = this.clock.getDelta(); // Certifique-se de criar um THREE.Clock no seu data
            //this.updatePhysics(deltaTime);
            this.composer.render(this.scene, this.camera);
        },
        stopAnimate() {
            if (this.frameId) {
                window.cancelAnimationFrame(this.frameId);
            }
        },
        processTask(task) {
            switch (task.action) {
                case 'loadModel':
                    this.$refs.progressDialog.showProgressDialog("Carregando e Preparando STL. Aguarde...", 10)
                    setTimeout(() => { this.loadModel(task.params) }, 1000)
                    break;
                case 'createOcclusionPlan':
                    this.createOcclusionPlan(task.params);
                    this.$store.dispatch('editor3D/endOcclusionPlanCreation');
                    break;
                case 'createDentalArch':
                    this.newCreatingDentalArch(task.params);
                    break;
            }
            this.$store.dispatch('editor3D/executeTask', task.id);
        },
        cloneOcclusionPlanForCutting() {
            // Encontrar o plano de oclusão na cena
            const occlusionPlane = this.scene.children.find(child =>
                child.userData.isOcclusionPlan === true
            );

            // Se o plano de oclusão for encontrado, clonar para criar o plano de corte
            if (occlusionPlane) {
                const cuttingPlane = occlusionPlane.clone();
                cuttingPlane.userData = {
                    name: this.$t('cuttingPlane'),
                    hide: false,
                    isCuttingPlane: true,
                    normal: occlusionPlane.userData.normal
                };

                // Adicionar o cuttingPlane à cena e aplicar o TransformControl a ele
                this.scene.add(cuttingPlane);
                this.$store.dispatch('editor3D/updateScene', this.scene);
                this.sceneUpdate = new Date()
                this.$store.dispatch('editor3D/updateDate', this.sceneUpdate);

                this.transformControl.attach(cuttingPlane);
                this.transformControl.setMode('translate'); // ou outro modo conforme necessário

                // Adicionar o indicador de direção
                //this.addDirectionIndicatorToPlane(cuttingPlane);

                // Ouvinte para atualizar a cena quando o TransformControl é manipulado
                this.transformControl.addEventListener('change', () => {
                    this.cuttingPlanePosition = cuttingPlane.position;
                    // Outras ações necessárias após a movimentação do plano
                });

                // Não esqueça de adicionar o TransformControl à cena se ainda não tiver sido adicionado
                if (!this.scene.children.includes(this.transformControl)) {
                    this.scene.add(this.transformControl);
                }
            } else {
                console.error('Plano de oclusão não encontrado na cena.');
            }
        },
        addDirectionIndicatorToPlane(cuttingPlane) {
            const cylinderHeight = 5;
            const coneHeight = 1;
            const radius = 0.75;

            // A normal é clonada diretamente do userData do plano de corte
            const planeNormal = cuttingPlane.userData.normal.clone();

            // Cria o cilindro e o cone
            const cylinderGeometry = new THREE.CylinderGeometry(radius, radius, cylinderHeight, 32);
            const cylinderMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);

            const coneGeometry = new THREE.ConeGeometry(radius, coneHeight, 32);
            const coneMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
            const cone = new THREE.Mesh(coneGeometry, coneMaterial);

            // Ajustar a posição do cone para a extremidade do cilindro
            cone.position.set(0, cylinderHeight / 2 + coneHeight / 2, 0);

            // Agrupar o cilindro e o cone para formar a seta
            const arrow = new THREE.Group();
            arrow.add(cylinder);
            arrow.add(cone);

            // Posicionar a seta acima do plano de corte para visibilidade
            const offset = 1; // Define o quanto a seta estará acima do plano
            arrow.position.copy(planeNormal.clone().multiplyScalar(offset));

            // Direcionar a seta para cima ao longo da normal do plano
            arrow.lookAt(planeNormal);

            // Adicionar a seta como um filho do plano de corte
            cuttingPlane.add(arrow);

            // Se a normal estiver apontando para baixo, inverter a orientação da seta
            if (planeNormal.y < 0) {
                arrow.rotateX(Math.PI);
            }
        },
        createOcclusionPlan(params) {
            // Extracts the points and objectRef from params
            const { points, objectRef } = params;

            // Assumes that points[0], points[1], and points[2] are the THREE.Vector3 points
            const [point1, point2, point3] = points;

            // Calculates the plane's normal
            const normal = new THREE.Vector3()
                .crossVectors(
                    new THREE.Vector3().subVectors(point2, point1),
                    new THREE.Vector3().subVectors(point3, point1)
                )
                .normalize();

            // Calculates the centroid of the three points
            const centroid = new THREE.Vector3(
                (point1.x + point2.x + point3.x) / 3,
                (point1.y + point2.y + point3.y) / 3,
                (point1.z + point2.z + point3.z) / 3
            );

            // Defines the size and material of the plane
            const size = params.size || 40;
            const geometry = new THREE.CircleGeometry(size, 64);
            const material = new THREE.MeshPhongMaterial({
                color: 0xffa726, specular: 0x222222, shininess: 400, side: THREE.DoubleSide, transparent: true,
                opacity: 0.5
            })

            // Creates the plane
            const plane = new THREE.Mesh(geometry, material);
            plane.lookAt(normal);
            plane.position.copy(centroid);

            // Adds information to the userData of the plane
            plane.userData = {
                name: this.$t('occlusionPlan'),
                hide: false, // Initially hidden, assuming that's the desired logic
                objectRef: objectRef, // Stores the reference to the original object
                isOcclusionPlan: true,
                normal: normal
            };

            // Adds the plane to the scene
            this.scene.add(plane);
            this.$store.dispatch('editor3D/updateScene', this.scene);
            this.sceneUpdate = new Date()
            this.$store.dispatch('editor3D/updateDate', this.sceneUpdate);

            // Returns the created plane
            return plane;
        },
        distanceBetweenPoints(pointA, pointB) {
            let dx = pointA.x - pointB.x;
            let dz = pointA.z - pointB.z;
            return Math.sqrt(dx * dx + dz * dz);
        },
        degreesToRadians(degrees) {
            return degrees * (Math.PI / 180);
        },
        adjustAngleTowards90(actualAngle) {
            // Converte o ângulo para distância de 90 graus
            const distanceTo90 = Math.abs(actualAngle - 90);

            // Se o ângulo estiver a mais de 1 grau de distância de 90, ajusta de 1 grau em direção a 90
            if (distanceTo90 > 2) {
                return actualAngle + (actualAngle < 90 ? 2 : -2);
            }

            return actualAngle;
        },
        generateGumCurve(teethCenters) {

            if (!this.activeObject || !this.activeObject.userData.modelType) return [];

            const isUpperArch = (this.activeObject.userData.modelType === 'maxillary');
            const teeth = [];

            // Separa e ordena os centroids com base no número do dente
            Object.keys(teethCenters).forEach(toothNumber => {
                const toothInt = parseInt(toothNumber);
                if (isUpperArch ? (toothInt >= 11 && toothInt <= 28) : (toothInt >= 31 && toothInt <= 48)) {
                    teeth.push({ toothNumber: toothInt, centerOfMass: teethCenters[toothNumber] });
                }
            });

            // Ordena os dentes superiores e inferiores
            teeth.sort((a, b) => a.toothNumber - b.toothNumber);

            // Define a ordem específica para os dentes da arcada superior e inferior
            const upperTeethOrder = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];
            const lowerTeethOrder = [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];

            // Função para ordenar os dentes conforme a ordem definida
            const orderTeeth = (teeth, order) => {
                return order.map(toothNumber => teeth.find(tooth => tooth.toothNumber === toothNumber)).filter(tooth => tooth !== undefined);
            };

            // Verifica se é arcada superior para usar a ordem correspondente
            const orderedTeeth = isUpperArch ? orderTeeth(teeth, upperTeethOrder) : orderTeeth(teeth, lowerTeethOrder);

            // Filtra possíveis valores nulos e cria os vetores de posição para a curva Catmull-Rom
            const curvePoints = orderedTeeth
                .map(tooth => tooth.centerOfMass)
                .filter(centerOfMass => centerOfMass.centerOfMass !== undefined)
                .map(tooth => new THREE.Vector3(tooth.centerOfMass.x, tooth.centerOfMass.y, tooth.centerOfMass.z));

            // Cria a curva Catmull-Rom
            const curve = new THREE.CatmullRomCurve3(curvePoints, false, 'centripetal', 0.5);

            // Gera os pontos da curva com 50 divisões entre cada par de centroids
            const points = curve.getPoints(curvePoints.length * 24);

            // Crie o shape da gengiva com um perfil mais anatômico
            const shape = new THREE.Shape();
            const width = 1.2; // Ajuste para a largura desejada da base
            const height = isUpperArch ? -4 : 4; // A altura do arco da gengiva

            // Mova para a posição inicial (canto esquerdo inferior do "U" invertido)
            shape.moveTo(-5 * width, 5 * height);

            // Primeiro, desenhe a curva de Bézier indo para baixo da base (se desejar uma curva)
            shape.bezierCurveTo(-2 * width, 2.5 * height, 1.5 * width, 2.5 * height, 5 * width, 5 * height);

            // Adicione uma linha reta para o lado direito, formando a base do "U"
            shape.lineTo(-5 * width, 5 * height);

            // Chamada do método para criar a extrusão
            const extrudedMesh = this.extrudeAlongCurve(points, shape);
            if (isUpperArch) {
                extrudedMesh.geometry.translate(-2, 3.2 * height, -1.5 * width);
                extrudedMesh.geometry.rotateX(this.degreesToRadians(-5));
            }
            else { extrudedMesh.geometry.translate(2, 2.75 * height, -6 * width); }


            this.replaceGumGeometry(extrudedMesh.geometry, isUpperArch)

            return points;
        },
        replaceGumGeometry(newGumGeometry, isUpperArch) {
            // Encontra o objeto gengiva na cena.
            const modelType = isUpperArch ? 'maxillary' : 'mandibular';
            const gumsInScene = this.findGumsInScene();
            const gumInScene = gumsInScene.filter((gum) => (gum.userData.modelType === modelType))[0]

            if (gumInScene) {
                // Remove a geometria antiga da memória
                if (gumInScene.geometry) {
                    gumInScene.geometry.dispose();
                }

                // Substitui a geometria antiga pela nova
                gumInScene.geometry = newGumGeometry;
                if (gumInScene.scale.x === 1) {
                    gumInScene.scale.x *= (isUpperArch ? 0.999 : 1.2);
                    gumInScene.scale.z *= 1.2;
                }

                // Aviso: Se as propriedades como a posição, rotação ou escala precisarem ser ajustadas,
                // você deverá fazê-lo após substituir a geometria.
            } else {
                console.error('Gengiva não encontrada na cena.');
            }
        },
        extrudeAlongCurve(points, shapeGeometry) {
            // Cria a curva Catmull-Rom a partir dos pontos
            const curve = new THREE.CatmullRomCurve3(points);

            // Define as configurações da extrusão
            const extrudeSettings = {
                steps: 64, // Número de pontos ao longo da curva para construir a extrusão
                bevelEnabled: false, // Desativa o chanfro
                extrudePath: curve // A curva ao longo da qual a extrusão ocorrerá
            };

            // Cria a geometria de extrusão
            const extrudeGeometry = new THREE.ExtrudeGeometry(shapeGeometry, extrudeSettings);

            // Cria o material
            const material = new THREE.MeshPhongMaterial({
                color: 0xffc0cb,
                specular: 0x333366,
                shininess: 100,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 1,
            });

            // Cria a mesh de extrusão
            const extrudedObject = new THREE.Mesh(extrudeGeometry, material);
            //this.calculateVertexNormals(extrudeGeometry)

            // Retorna a mesh
            return extrudedObject;
        },
        moveDentalToIdealPosition() {
            // Sorteia um dos dentes
            const dentalKeys = Object.keys(this.dentalMap);
            // Usa o módulo para lidar com o contador ultrapassando o número de chaves
            const toothKeyIndex = this.toothIndexCounter % dentalKeys.length;
            const selectedToothKey = dentalKeys[toothKeyIndex];

            // Incrementa o contador para o próximo dente na sequência
            this.toothIndexCounter += 1;

            const selectedTooth = this.dentalMap[selectedToothKey];

            if (selectedTooth.borderPoints.length === 0) return;

            let selectedPointIndex;
            let closestDistance = Infinity;
            let closestPoint = null;

            // Encontra o ponto mais próximo e seu índice
            this.idealArchPoints.forEach((point, index) => {
                const dist = this.distanceBetweenPoints(point, selectedTooth.centerOfMass);
                if (dist < closestDistance) {
                    closestDistance = dist;
                    closestPoint = point;
                    selectedPointIndex = index;
                }
            });

            // Verifica se precisamos nos mover mais próximo ou mais distante do vizinho
            const needsToBeCloser = closestDistance > 0.5;
            const needsToBeFarther = closestDistance < 0.2;

            let newIdealPoint = closestPoint;
            let prevPointDistance = Infinity;
            let nextPointDistance = Infinity;

            // Calcula a distância do ponto anterior e posterior
            if (selectedPointIndex > 0) {
                prevPointDistance = this.distanceBetweenPoints(this.idealArchPoints[selectedPointIndex - 1], selectedTooth.centerOfMass);
            }
            if (selectedPointIndex < this.idealArchPoints.length - 1) {
                nextPointDistance = this.distanceBetweenPoints(this.idealArchPoints[selectedPointIndex + 1], selectedTooth.centerOfMass);
            }

            // Define o novo ponto ideal com base na necessidade de se aproximar ou se afastar
            if (needsToBeCloser) {
                // Escolhe o ponto que está mais próximo do centro de massa
                newIdealPoint = prevPointDistance < nextPointDistance ? this.idealArchPoints[selectedPointIndex - 1] : this.idealArchPoints[selectedPointIndex + 1];
            } else if (needsToBeFarther) {
                // Escolhe o ponto que está mais longe do centro de massa
                newIdealPoint = prevPointDistance > nextPointDistance ? this.idealArchPoints[selectedPointIndex - 1] : this.idealArchPoints[selectedPointIndex + 1];
            }


            // Calcula o movimento necessário em x e z, mas limita a 0.1
            let moveX = Math.min(Math.max(newIdealPoint.x - selectedTooth.centerOfMass.x, -0.1), 0.1);
            let moveZ = Math.min(Math.max(newIdealPoint.z - selectedTooth.centerOfMass.z, -0.1), 0.1);

            // Atualiza o centro de massa
            selectedTooth.centerOfMass.x += moveX;
            selectedTooth.centerOfMass.z += moveZ;

            // Encontra e atualiza o dente na cena
            const teethInScene = this.findTeethInScene();
            const toothInScene = teethInScene.find(t => t.userData.toothNumber == selectedToothKey);

            if (toothInScene) {

                const originalPosition = toothInScene.position.clone();
                const originalRotation = toothInScene.rotation.clone();

                toothInScene.position.x = selectedTooth.centerOfMass.x;
                toothInScene.position.z = selectedTooth.centerOfMass.z;

                const lastDigit = parseInt(dentalKeys[toothKeyIndex]) % 10;

                if (lastDigit >= 5) {

                    // Aplica a rotação em X e Z em direção a 90 graus
                    selectedTooth.actualAngles.x = this.adjustAngleTowards90(selectedTooth.actualAngles.x);
                    selectedTooth.actualAngles.z = this.adjustAngleTowards90(selectedTooth.actualAngles.z);

                    // Aplica as rotações no objeto da cena
                    toothInScene.rotation.x = this.degreesToRadians(selectedTooth.angles.x - selectedTooth.actualAngles.x);
                    toothInScene.rotation.z = this.degreesToRadians(selectedTooth.angles.z - selectedTooth.actualAngles.z);

                }
                // Verifica colisões para cada vizinho
                const adjacentTeeth = this.getAdjacentTeeth(parseInt(selectedToothKey));
                let collision = false;

                for (let i = 0; i < adjacentTeeth.length; i++) {
                    const result = this.calculateClosestDistanceBetweenTeeth(selectedToothKey, adjacentTeeth[i]);

                    // Se houver colisão ou a distância mínima não for atendida, reverte as transformações
                    if (result && result.collisionDetected) {
                        collision = true;
                        break; // Sai do loop se uma colisão for detectada
                    }
                }

                // Se uma colisão foi detectada, reverte as mudanças
                if (collision) {
                    toothInScene.position.copy(originalPosition);
                    toothInScene.rotation.copy(originalRotation);
                }
            }
        },
        newCreatingDentalArch(params) {

            this.activeDentalArch = params.arch;
            this.idealArchStartPoints[this.activeDentalArch] = params.points;

            const curve = new THREE.CatmullRomCurve3(params.points, false, 'catmullrom', 0.5);
            const curvePoints = curve.getPoints(256);
            this.idealArchPoints[this.activeDentalArch] = curvePoints;

            // Atualiza a cena e a data no Vuex
            this.$store.dispatch('editor3D/updateScene', this.scene);
            this.sceneUpdate = new Date();
            this.$store.dispatch('editor3D/updateDate', this.sceneUpdate);
            this.clearContourPoints();
        },
        creatingDentalArch(params) {
            // Extrai os pontos dos parâmetros
            const { points } = params;

            // Extrai os pontos diretamente usando map para melhorar a legibilidade
            const pointsCoordinates = points.map(p => ({ x: p.point.x, z: p.point.z }));

            // Calcula os coeficientes da parábola no plano xz
            const coefficients = this.calculateParabolaCoefficients(
                pointsCoordinates[0].x, pointsCoordinates[0].z,  // Coordenadas do leftMolar
                pointsCoordinates[1].x, pointsCoordinates[1].z,  // Coordenadas do incisor
                pointsCoordinates[2].x, pointsCoordinates[2].z   // Coordenadas do rightMolar
            );

            // Gera um conjunto de pontos ao longo da parábola usando os coeficientes calculados
            // Você pode decidir sobre o número de pontos que deseja gerar na parábola
            const archPoints = this.generateArchPoints(
                pointsCoordinates[0].x, pointsCoordinates[2].x, coefficients, 160
            );

            // Converte os pontos de Vector2 para Vector3, assumindo y=0 pois a parábola está no plano xz
            const curvePoints = archPoints.map(p => new THREE.Vector3(p.x, 0, p.z));

            this.idealArchPoints = curvePoints;

            // Encontra ou cria um grupo para a parábola
            let parent = this.scene.getObjectByName('Dental Arch');
            if (!parent) {
                parent = new THREE.Group();
                parent.name = 'Dental Arch';
                parent.userData.name = this.$t('idealDentalArch');
                this.scene.add(parent);
            } else {
                // Se o grupo já existir, remove os filhos antigos
                while (parent.children.length > 0) {
                    const child = parent.children[0];
                    parent.remove(child);
                    // Limpando geometria e material do child, se necessário
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                }
            }

            // Usa a função addLinesBetweenPoints para adicionar o tubo ao grupo 'Dental Arch'
            //this.addLinesBetweenPoints(curvePoints, parent, false, 0.3, 0x00ff00); // false indica que a curva não é fechada

            // Adiciona esferas ao grupo 'Dental Arch'
            const sphereGeometry = new THREE.SphereGeometry(0.5, 4, 4);
            const sphereMaterial = new THREE.MeshPhongMaterial({ color: 0x2652ff, specular: 0x222222, shininess: 400, side: THREE.DoubleSide, transparent: true, opacity: 0.5 });
            archPoints.forEach(point => {
                const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                sphere.position.copy(point);
                parent.add(sphere);
            });

            // Atualiza a cena e a data no Vuex
            this.$store.dispatch('editor3D/updateScene', this.scene);
            this.sceneUpdate = new Date();
            this.$store.dispatch('editor3D/updateDate', this.sceneUpdate);
        },
        generateArchPoints(startX, endX, coeficients, numPoints) {

            let points = [];
            let step = (endX - startX) / (numPoints - 2);

            for (let i = 0; i < numPoints + 3; i++) {
                let x = startX + (step * i) - step * 2;
                let z = ((coeficients.a) * x * x) + (coeficients.b * x) + coeficients.c;
                points.push(new THREE.Vector3(x, 0, z)); // Assumimos que o arco está alinhado com o plano y = 0
            }

            return points;
        },
        calculateParabolaCoefficients(x1, y1, x2, y2, x3, y3) {
            // Primeiro, montamos a matriz A usando os valores de x dos pontos
            let A = [
                [x1 * x1, x1, 1],
                [x2 * x2, x2, 1],
                [x3 * x3, x3, 1]
            ];

            // Função para calcular o determinante de uma matriz 3x3
            function determinant(M) {
                return M[0][0] * (M[1][1] * M[2][2] - M[1][2] * M[2][1]) -
                    M[0][1] * (M[1][0] * M[2][2] - M[1][2] * M[2][0]) +
                    M[0][2] * (M[1][0] * M[2][1] - M[1][1] * M[2][0]);
            }

            // Função para substituir uma coluna de uma matriz por um vetor
            function substituteColumn(M, col, B) {
                return M.map((row, i) => {
                    let newRow = row.slice();
                    newRow[col] = B[i];
                    return newRow;
                });
            }

            // Depois, montamos a matriz B usando os valores de y dos pontos
            let B = [y1, y2, y3];

            // Usamos a regra de Cramer para resolver para a, b e c
            let detA = determinant(A);
            let detAa = determinant(substituteColumn(A, 0, B));
            let detAb = determinant(substituteColumn(A, 1, B));
            let detAc = determinant(substituteColumn(A, 2, B));

            // Se o determinante de A for zero, a matriz é singular e o sistema não tem uma solução única
            if (detA === 0) {
                throw new Error('Singular matrix, cannot solve for parabola coefficients.');
            }

            let a = detAa / detA;
            let b = detAb / detA;
            let c = detAc / detA;

            return { a, b, c };
        },
        processPendingTasks() {
            this.tasks.forEach(task => {
                if (!task.executed) {
                    this.processTask(task);
                }
            });
        },
        processExecutedTasks() {
            this.tasks.forEach(task => {
                if (task.executed) {
                    this.processTask(task);
                }
            });
        },
        calculateNormalFromVertices(vertices, index) {
            // Obter os três vértices do triângulo
            let p1 = new THREE.Vector3(
                vertices[index],
                vertices[index + 1],
                vertices[index + 2]
            );
            let p2 = new THREE.Vector3(
                vertices[index + 3],
                vertices[index + 4],
                vertices[index + 5]
            );
            let p3 = new THREE.Vector3(
                vertices[index + 6],
                vertices[index + 7],
                vertices[index + 8]
            );

            // Calcular as arestas do triângulo
            let edge1 = new THREE.Vector3().subVectors(p2, p1);
            let edge2 = new THREE.Vector3().subVectors(p3, p1);

            // Usar o produto vetorial das arestas para calcular a normal
            let normal = new THREE.Vector3().crossVectors(edge1, edge2).normalize();
            return normal;
        },
        initializeSES() {
            // Verificar se existe um activeObject
            if (!this.activeObject) {
                console.error('No active object to initialize SES.');
                return;
            }

            // Encontrar o mesh com userData.objectType igual a "gums" dentro do activeObject
            const gumsMesh = this.activeObject.children.find(child => child.userData.objectType === "gums");
            if (!gumsMesh) {
                console.error('No gums mesh found in the active object.');
                return;
            }

            // Verificar se o gumsMesh tem geometria
            if (!gumsMesh.geometry) {
                console.error('No geometry found in the gums mesh.');
                return;
            }

            const modelType = this.activeObject.userData.modelType;
            const otherMeshes = this.scene.children.filter(child =>
                child !== this.activeObject && // Certifique-se de não incluir o próprio activeObject
                child.userData.modelType === modelType && // Mesmo modelType
                child.geometry // Verifique se o mesh tem geometria
            );

            // Criar a geometria das arestas a partir da geometria do gumsMesh
            const edges = new THREE.EdgesGeometry(otherMeshes[0].geometry, 90);

            // Material para as linhas das arestas
            const edgeMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff, linewidth: 2 });

            let vertices = [];

            for (let i = 0; i < edges.attributes.position.count; i++) {
                const x = edges.attributes.position.getX(i);
                const y = edges.attributes.position.getY(i);
                const z = edges.attributes.position.getZ(i);
                vertices.push(new THREE.Vector3(x, y, z));
            }

            // Filtrar outliers
            vertices = this.filterOutliers(vertices);

            // Atualizar o BufferAttribute com os vértices filtrados
            this.updatePositionAttribute(edges, vertices);

            // Criar um objeto de linha com a geometria das arestas e o material
            const edgeLines = new THREE.LineSegments(edges, edgeMaterial);

            this.smoothingMeshEdges = edgeLines.geometry;

            // Adicionar as linhas de aresta à cena
            //this.scene.add(edgeLines);

            // Determina o tipo de arcada baseado no userData.modelType do objeto ativo
            const isUpperArc = this.activeObject.userData.modelType === 'maxillary';

            let referencePoint = isUpperArc ? -Infinity : Infinity;
            const positionAttribute = this.smoothingMeshEdges.attributes.position;

            // Encontra o ponto mais alto (para maxillary) ou mais baixo (para mandibular)
            for (let i = 0; i < positionAttribute.count; i++) {
                const y = positionAttribute.getY(i);
                referencePoint = isUpperArc ? Math.max(y, referencePoint) : Math.min(y, referencePoint);
            }

            // Armazena o ponto mais alto/mais baixo encontrado
            this.highestOrLowestPoint = referencePoint;

            return this.smoothEdgeContours();

        },
        initializeSES_() {
            if (!this.activeObject || !this.activeObject.geometry) {
                console.error('No active object with geometry to initialize SES.');
                return;
            }

            //this.$store.dispatch('editor3D/startEdgeSmoothing', 1);
            //this.updateSESCursor();

            const edges = new THREE.EdgesGeometry(this.activeObject.geometry, 90);

            // Material para as linhas das arestas
            const edgeMaterial = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 2 });

            let vertices = [];

            for (let i = 0; i < edges.attributes.position.count; i++) {
                const x = edges.attributes.position.getX(i);
                const y = edges.attributes.position.getY(i);
                const z = edges.attributes.position.getZ(i);
                vertices.push(new THREE.Vector3(x, y, z));
            }

            // Filtrar outliers
            vertices = this.filterOutliers(vertices);

            // Atualizar o BufferAttribute com os vértices filtrados
            this.updatePositionAttribute(edges, vertices);

            // Criar um objeto de linha com a geometria das arestas e o material
            const edgeLines = new THREE.LineSegments(edges, edgeMaterial);

            this.smoothingMeshEdges = edgeLines.geometry;

            // Adicionar as linhas de aresta à cena
            // this.scene.add(edgeLines);

            // Determina o tipo de arcada baseado no userData.modelType do objeto ativo
            const isUpperArc = this.activeObject.userData.modelType === 'maxillary';

            let referencePoint = isUpperArc ? -Infinity : Infinity;
            const positionAttribute = this.smoothingMeshEdges.attributes.position;

            // Encontra o ponto mais alto (para maxillary) ou mais baixo (para mandibular)
            for (let i = 0; i < positionAttribute.count; i++) {
                const y = positionAttribute.getY(i);
                referencePoint = isUpperArc ? Math.max(y, referencePoint) : Math.min(y, referencePoint);
            }

            // Armazena o ponto mais alto/mais baixo encontrado
            this.highestOrLowestPoint = referencePoint;

            return this.smoothEdgeContours();

        },
        filterOutliers(vertices) {
            const k = 4; // Número de vizinhos mais próximos a considerar
            const thresholdFactor = 5; // Fator multiplicativo para a distância média

            // Calcular a distância média entre os pontos e seus k vizinhos mais próximos
            let distances = [];
            vertices.forEach((vertex, index) => {
                let dists = vertices.map(v => vertex.distanceTo(v)).sort((a, b) => a - b).slice(1, k + 1);
                distances[index] = dists.reduce((a, b) => a + b, 0) / k;
            });

            let meanDistance = distances.reduce((a, b) => a + b, 0) / distances.length;
            let threshold = meanDistance * thresholdFactor;

            // Filtrar os pontos que têm uma distância média aos vizinhos menor que o limite
            let filteredVertices = vertices.filter((vertex, index) => distances[index] < threshold);

            return filteredVertices;
        },
        updatePositionAttribute(geometry, vertices) {
            const positions = new Float32Array(vertices.length * 3);
            vertices.forEach((vertex, i) => {
                positions[i * 3] = vertex.x;
                positions[i * 3 + 1] = vertex.y;
                positions[i * 3 + 2] = vertex.z;
            });

            // Substituir o buffer de posição existente
            geometry.attributes.position = new THREE.BufferAttribute(positions, 3);
            geometry.attributes.position.needsUpdate = true; // Sinaliza que o buffer precisa ser atualizado
        },
        connectEdgePoints(vertices) {
            let path = [];
            let remaining = new Set(vertices.map(v => v.toArray().toString())); // Todos os pontos como strings

            let currentPoint = vertices[0]; // Ponto inicial arbitrário
            path.push(currentPoint);
            remaining.delete(currentPoint.toArray().toString());

            while (remaining.size > 0) {
                let nextPoint = null;
                let minDistance = 5;

                remaining.forEach(vStr => {
                    let v = new THREE.Vector3().fromArray(vStr.split(',').map(Number));
                    let distance = currentPoint.distanceTo(v);
                    if (distance < minDistance) {
                        minDistance = distance;
                        nextPoint = v;
                    }
                });

                if (nextPoint) {
                    path.push(nextPoint);
                    remaining.delete(nextPoint.toArray().toString());
                    currentPoint = nextPoint;
                } else {
                    break; // No valid next point found
                }
            }

            return path;
        },
        smoothEdgeContours() {
            if (!this.smoothingMeshEdges) {
                console.error('No edge geometry available to process.');
                return;
            }

            const vertices = [];
            const positionAttribute = this.smoothingMeshEdges.attributes.position;

            for (let i = 0; i < positionAttribute.count; i += 3) {
                const vertex = new THREE.Vector3(
                    positionAttribute.getX(i),
                    positionAttribute.getY(i),
                    positionAttribute.getZ(i)
                );
                vertices.push(vertex);
            }

            // Traça o caminho completo usando os vértices
            const orderedVertices = this.connectEdgePoints(vertices);

            // Cria a curva Catmull-Rom com os vértices ordenados e adiciona ao cenário
            const curve = new THREE.CatmullRomCurve3(orderedVertices, false, 'catmullrom', 0.25);
            const points = curve.getPoints(Math.floor(vertices.length / 2));

            // const curveGeometry = new THREE.BufferGeometry().setFromPoints(points);
            // const material = new THREE.LineBasicMaterial({ color: 0xff00ff });
            // const curveObject = new THREE.Line(curveGeometry, material);
            // this.scene.add(curveObject);
            return points;
        },
        updateSESCursor() {
            const radius = this.$store.state.editor3D.smoothingRadius;

            // Cria ou atualiza a geometria do cursor
            const cursorGeometry = new THREE.SphereGeometry(radius, 32, 32);

            // Material translúcido para o cursor
            const cursorMaterial = new THREE.MeshBasicMaterial({
                color: 0x00ff00, // Verde, por exemplo
                transparent: true,
                opacity: 0.5
            });

            if (this.brushMesh) {
                // Atualiza a geometria do cursor existente
                this.brushMesh.geometry.dispose(); // Descarta a geometria antiga
                this.brushMesh.geometry = cursorGeometry;
                this.brushMesh.material = cursorMaterial;
                this.brushMesh.visible = true;
            } else {
                // Cria um novo cursor e o adiciona à cena
                const cursorMesh = new THREE.Mesh(cursorGeometry, cursorMaterial);
                cursorMesh.userData.name = 'Brush';
                this.scene.add(cursorMesh);
                this.brushMesh = cursorMesh;
            }

            // Certifique-se de que a cena seja atualizada
            this.renderer.render(this.scene, this.camera);
        },
        commitTransform() {
            this.statusMessage = "Transformação Aplicada";
            const object = this.activeObject;

            if (!object || !object.geometry) {
                console.error('Não foi fornecido um objeto ou geometria válida.');
                return;
            }

            // Aplica a matriz de transformação à geometria do objeto
            if (object.geometry.isBufferGeometry) {
                // Para BufferGeometry, aplicar a matriz diretamente
                object.geometry.applyMatrix4(object.matrix);
                object.geometry.attributes.position.needsUpdate = true;
                if (object.geometry.attributes.normal) {
                    object.geometry.attributes.normal.needsUpdate = true;
                }
            } else if (object.geometry.isGeometry) {
                // Para THREE.Geometry (legado)
                object.geometry.applyMatrix(object.matrix);
                object.geometry.verticesNeedUpdate = true;
                object.geometry.normalsNeedUpdate = true;
            }

            // Recalcular as bounding boxes e spheres
            object.geometry.computeBoundingBox();
            object.geometry.computeBoundingSphere();
            object.updateMatrixWorld(true);

            // Resetar a matriz de transformação do objeto
            object.position.set(0, 0, 0);
            object.rotation.set(0, 0, 0);
            object.scale.set(1, 1, 1);
            object.matrix.identity(); // Reseta a matriz para a identidade
            object.matrixWorld.identity(); // Reseta a matriz do mundo para a identidade

            // Recalcular as normais para geometrias
            if (object.geometry.isBufferGeometry) {
                object.geometry.computeVertexNormals();
                object.geometry.computeBoundsTree();
                this.calculateVertexNormals(object.geometry);
                this.$store.dispatch('editor3D/updateScene', this.scene);
                this.sceneUpdate = new Date()
                this.$store.dispatch('editor3D/updateDate', this.sceneUpdate);
            }
        },
        addAxisPlanes() {
            // Define o raio dos círculos
            const circleRadius = 40;

            // Cria o material para os círculos
            const materialX = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide, transparent: true, opacity: 0.25 });
            const materialY = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide, transparent: true, opacity: 0.25 });
            const materialZ = new THREE.MeshBasicMaterial({ color: 0x0000ff, side: THREE.DoubleSide, transparent: true, opacity: 0.25 });

            // Cria três geometrias circulares
            const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);

            // Cria um grupo para conter os três círculos
            const axisPlanesGroup = new THREE.Group();

            // Cria e adiciona os círculos ao grupo
            const circleX = new THREE.Mesh(circleGeometry, materialX);
            circleX.rotation.y = Math.PI / 2;
            axisPlanesGroup.add(circleX);

            const circleY = new THREE.Mesh(circleGeometry, materialY);
            circleY.rotation.x = Math.PI / 2;
            axisPlanesGroup.add(circleY);

            const circleZ = new THREE.Mesh(circleGeometry, materialZ);
            axisPlanesGroup.add(circleZ);

            // Move o grupo para o centro da cena
            axisPlanesGroup.position.set(0, 0, 0);

            // Adiciona o grupo à cena
            this.scene.add(axisPlanesGroup);
        },
        rotateGeometry(geometry, angle) {
            // Aplicar a rotação de -90 graus em torno do eixo X a todos os vértices
            const rotationMatrix = new THREE.Matrix4();
            rotationMatrix.makeRotationX(angle);

            geometry.applyMatrix4(rotationMatrix);

            // Rotacionar as normais para que a iluminação seja calculada corretamente
            geometry.computeVertexNormals();
        },
        alignModelUsingPCA(geometry) {
            // Verifica se a geometria possui o atributo de posição
            if (!geometry.attributes.position) {
                console.error('A geometria fornecida não tem atributos de posição.');
                return;
            }

            // Extrair os vértices da geometria
            const positionAttribute = geometry.attributes.position;
            const vertices = [];
            for (let i = 0; i < positionAttribute.count; i++) {
                vertices.push([
                    positionAttribute.getX(i),
                    positionAttribute.getY(i),
                    positionAttribute.getZ(i)
                ]);
            }

            // Aplicar PCA aos pontos
            const pca = new PCA(vertices);
            const eigenVectors = pca.getEigenvectors();
            // Reordenar os eigenvectors se necessário
            const alignX = new THREE.Vector3(...eigenVectors.data[1]); // Segundo eigenvector alinha com X
            const alignY = new THREE.Vector3(...eigenVectors.data[2]); // Terceiro eigenvector alinha com Y
            const alignZ = new THREE.Vector3(...eigenVectors.data[0]); // Primeiro eigenvector alinha com Z

            // Criar uma matriz de rotação usando os eigenvectors
            const rotationMatrix = new THREE.Matrix4().set(
                alignX.x, alignY.x, alignZ.x, 0,
                alignX.y, alignY.y, alignZ.y, 0,
                alignX.z, alignY.z, alignZ.z, 0,
                0, 0, 0, 1
            );

            // Converter a matriz de rotação para ângulos de Euler
            const currentEuler = new THREE.Euler().setFromRotationMatrix(rotationMatrix, 'XYZ');
            console.log(`Rotation Angles: X=${currentEuler.x * 180 / Math.PI}°, Y=${currentEuler.y * 180 / Math.PI}°, Z=${currentEuler.z * 180 / Math.PI}°`);

            // Criar um novo objeto Euler com ângulos negativos
            const inverseEuler = new THREE.Euler(-currentEuler.x, -currentEuler.y, -currentEuler.z, 'XYZ');

            // Criar uma matriz de rotação a partir do Euler inverso
            const inverseRotationMatrix = new THREE.Matrix4().makeRotationFromEuler(inverseEuler);


            // Aplicar a matriz de rotação à geometria
            geometry.applyMatrix4(inverseRotationMatrix);



        },
        async dbSaveDentalMap(modelId, dentalMap) {
            try {
                let response = await fetch(`${process.env.VUE_APP_API_URL}dentalmaps`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ modelId, dentalMap })
                });

                let result = await response.json();
                if (response.ok) {
                    console.log('DentalMap salvo com sucesso:', result);
                } else {
                    console.error('Erro ao salvar o dentalMap:', result);
                }
            } catch (error) {
                console.error('Erro ao salvar o dentalMap:', error);
            }
        },
        async dbLoadDentalMap(modelId) {
            try {
                let response = await fetch(`${process.env.VUE_APP_API_URL}dentalmaps/${modelId}`);
                let result = await response.json();

                if (response.ok) {
                    console.log('DentalMap carregado com sucesso:', result.dentalMap);
                    return result.dentalMap;
                } else {
                    console.error('Erro ao carregar o dentalMap:', result);
                    return false
                }
            } catch (error) {
                console.error('Erro ao carregar o dentalMap:', error);
                return false
            }
        },
        async dbSearchModel(modelName, fileName, fileSize, geometry) {
            try {
                const url = `${process.env.VUE_APP_API_URL}models/search?name=${encodeURIComponent(modelName)}&fileName=${encodeURIComponent(fileName)}&fileSize=${encodeURIComponent(fileSize)}`;

                let response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                let result = await response.json();
                console.table(result);

                if (response.ok) {
                    const modelId = result.modelId;

                    // Procurar o mesh na scene
                    this.scene.traverse((object) => {
                        if (object.isMesh && object.userData.fromFile && object.userData.modelType === modelName) {
                            object.userData.modelId = modelId;
                            console.log(`ModelId ${modelId} added to mesh with modelType ${modelName}`);
                        }
                    });

                    if (result.dentalMap) {
                        this.$refs.progressDialog.showProgressDialog("Carregando Segmentação. Aguarde...", 10)
                        setTimeout(() => { this.updateDentalMapAfterLoading(result.dentalMap, modelId); }, 1000)

                    }
                } else {
                    console.error('Erro ao procurar modelo:', result);
                    return await this.dbSaveModel(modelName, fileName, fileSize, geometry); // Chama o método para salvar o modelo;
                }
            } catch (error) {
                console.error('Erro ao procurar modelo:', error);
                return false;
            }
        }
        ,
        async dbSaveModel(modelName, fileName, fileSize, threeJsGeometry) {
            const vertices = threeJsGeometry.attributes.position.array;
            const geometry = { attributes: { position: { array: vertices } } };

            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}models`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ name: modelName, fileName, fileSize, geometry })
                });

                const data = await response.json();

                if (response.ok) {
                    const modelId = data.modelId;

                    // Procurar o mesh na scene
                    this.scene.traverse((object) => {
                        if (object.isMesh && object.userData.fromFile && object.userData.modelType === modelName) {
                            object.userData.modelId = modelId;
                            console.log(`ModelId ${modelId} added to mesh with modelType ${modelName}`);
                        }
                    });
                } else {
                    console.error('Erro ao salvar o modelo:', data);
                }
            } catch (error) {
                console.error('Erro ao salvar o modelo:', error);
            }
        }
        ,

        createThreeJsGeometry(vertices, name, modelId) {

            const geometry = new THREE.BufferGeometry();
            const positions = new Float32Array(vertices);
            geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

            this.rotateGeometry(geometry, -Math.PI / 2);

            geometry.computeBoundsTree();
            this.calculateVertexNormals(geometry);

            const material = new THREE.MeshPhongMaterial({ color: 0x02BEBE, specular: 0x000000, emissive: 0x000000, shininess: 0, side: THREE.DoubleSide, transparent: true, opacity: 1 });

            const mesh = new THREE.Mesh(geometry, material);
            mesh.userData = { modelType: name, name: this.$t(name), hide: false, modelId: modelId };

            this.scene.add(mesh);

            // Box3Helper
            const box = new THREE.Box3().setFromObject(mesh);
            const boxHelper = new THREE.Box3Helper(box, 0xffff00); // cor amarela para a caixa
            boxHelper.visible = false;
            boxHelper.userData = { boxHelper: name, name: this.$t('boxHelper'), hide: true }

            this.scene.add(boxHelper);

            // Armazenando referências aos helpers no userData do mesh para fácil acesso
            mesh.userData.helpers = {
                boxHelper: boxHelper
            };

            this.handleObjectClickFromList(mesh);

            this.$store.dispatch('editor3D/updateScene', this.scene);
            this.sceneUpdate = new Date()
            this.$store.dispatch('editor3D/updateDate', this.sceneUpdate);

            // Termina o worker e libera o URL do blob
            this.onWindowResize();
        },

        async dbLoadModel(modelId) {
            fetch(`${process.env.VUE_APP_API_URL}models/${modelId}`)
                .then(response => response.json())
                .then(data => {
                    const buffer = data.geometry.data.attributes.position.array;
                    this.createThreeJsGeometry(buffer, data.name, modelId)
                })
                .catch(error => console.error('Erro ao recuperar o modelo:', error));
        },

        loadModel(params) {

            const loader = new STLLoader();

            fetch('./simplify/worker0.2.51.js')
                .then(response => response.blob())
                .then((blob) => {
                    const workerURL = URL.createObjectURL(blob);
                    const worker = new Worker(workerURL);

                    worker.onmessage = (e) => {

                        if (e.data.status && e.data.status === 'ready') {
                            console.warn('Worker ready');
                            console.table(params.file);
                            worker.postMessage({
                                "blob": params.file,
                                "percentage": 0.4,
                                "simplify_name": "simplified_model.stl"
                            });
                        }
                        const file = e.data.blob;
                        if (file !== undefined) {
                            const reader = new FileReader();
                            reader.readAsArrayBuffer(file);
                            reader.onload = (e) => {
                                const buffer = e.target.result;
                                const geometry = loader.parse(buffer);

                                //this.dbSaveModel(params.modelType, params.file.name, params.file.size, geometry);
                                this.dbSearchModel(params.modelType, params.file.name, params.file.size, geometry);

                                this.rotateGeometry(geometry, -Math.PI / 2);

                                //console.table(this.determineAlignmentAndFrontDirection(geometry))

                                geometry.computeBoundsTree();
                                this.calculateVertexNormals(geometry);

                                const material = new THREE.MeshPhongMaterial({ color: 0x02BEBE, specular: 0x000000, emissive: 0x000000, shininess: 0, side: THREE.DoubleSide, transparent: true, opacity: 1 });

                                const mesh = new THREE.Mesh(geometry, material);
                                mesh.userData = { modelType: params.modelType, name: this.$t(params.modelType), hide: false, fromFile: true };

                                this.setMeshCategory(mesh, 'base');

                                this.scene.add(mesh);

                                // Box3Helper
                                const box = new THREE.Box3().setFromObject(mesh);
                                const boxHelper = new THREE.Box3Helper(box, 0xffff00); // cor amarela para a caixa
                                boxHelper.visible = false;
                                boxHelper.userData = { boxHelper: params.modelType, name: this.$t('boxHelper'), hide: true }

                                this.setMeshCategory(boxHelper, 'aux');

                                this.scene.add(boxHelper);

                                // Armazenando referências aos helpers no userData do mesh para fácil acesso
                                mesh.userData.helpers = {
                                    boxHelper: boxHelper
                                };

                                this.handleObjectClickFromList(mesh);

                                this.$store.dispatch('editor3D/updateScene', this.scene);
                                this.sceneUpdate = new Date()
                                this.$store.dispatch('editor3D/updateDate', this.sceneUpdate);

                                // Termina o worker e libera o URL do blob
                                worker.terminate();
                                URL.revokeObjectURL(workerURL);
                                this.onWindowResize();
                                this.$refs.progressDialog.cancelProcess();

                            }

                            return;
                        }
                    }

                })
                .catch(error => console.error('Erro ao carregar o worker:', error));

        },
        onWindowResize() {
            const container = document.querySelector('.threejs-canvas-container');

            const width = container.clientWidth;
            const height = container.clientHeight;
            const needResize = this.renderer.domElement.width !== width || this.renderer.domElement.height !== height;
            if (needResize) {
                this.renderer.setSize(width, height);
                this.camera.aspect = width / height;
                this.camera.updateProjectionMatrix();
            }
        }
    },
    mounted() {
        this.initThree();
        this.processExecutedTasks();
        this.processPendingTasks();
        window.addEventListener('resize', this.onWindowResize);
        document.documentElement.style.overflowY = 'hidden';
        document.addEventListener('keydown', this.handleKeyDown);
        this.renderer.domElement.addEventListener('click', this.handleModelClick);
        document.addEventListener('contextmenu', function (event) {
            event.preventDefault();
        }, false);
        this.renderer.domElement.addEventListener('mousedown', this.onDocumentMouseDown);
        window.addEventListener('mousemove', this.onDocumentMouseMove);
        window.addEventListener('mouseup', this.onDocumentMouseUp);
    },
    beforeUnmount() {
        this.stopAnimate();
        if (this.renderer) {
            this.renderer.dispose();
        }
        window.removeEventListener('resize', this.onWindowResize);
        document.documentElement.style.overflowY = null;
        document.removeEventListener('keydown', this.handleKeyDown);
        this.renderer.domElement.removeEventListener('click', this.handleModelClick);

        this.renderer.domElement.removeEventListener('mousedown', this.onDocumentMouseDown);
        window.removeEventListener('mousemove', this.onDocumentMouseMove);
        window.removeEventListener('mouseup', this.onDocumentMouseUp);

        if (process.env.VUE_APP_CLEAR_TASKS == "yes") {
            console.warn("Fila de tarefas removida")
            this.$store.dispatch('editor3D/clearTasks');
        }


    },
    computed: {
        tasks() {
            return this.$store.state.editor3D.tasks;
        },
        currentAction() {
            return this.$store.state.editor3D.currentAction;
        },
        centroid() {
            const contourPoints = this.$store.getters['editor3D/contourPoints'];
            let centroid = new THREE.Vector3();
            contourPoints.forEach(point => {
                centroid.add(new THREE.Vector3(point.x, point.y, point.z));
            });
            centroid.divideScalar(contourPoints.length);
            return centroid;
        }
    },
    watch: {
        tasks(newTasks) {
            newTasks.forEach(task => {
                if (!task.executed) {
                    this.processTask(task);
                }
            })
        },
        currentAction(newAction, oldAction) {
            if (newAction !== oldAction) {
                this.$store.dispatch('editor3D/clearOcclusionPlanData');
                this.removeAllHelperObjects();
            }
        },
        'this.$store.getters["editor3D/contourPoints"]': {
            handler() {
                this.updateContour();
            },
            deep: true,
        },
    },
};

</script>

<style scoped>
.threejs-canvas-container {
    width: 100%;
    height: 95vh;
    overflow: hidden;
}

.v-container {
    padding: 1px !important;
}
</style>
@/components/methods/segmentationMethods