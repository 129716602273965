<template>
  <v-app class="ortho-login-background">
    <ortho-languages />
    <v-container style="min-height: 95vh;" class="d-flex align-center justify-center ">
      <v-row justify="center">
        <v-col cols="12" sm="4" md="4" lg="3">
          <v-card class="elevation-12 d-flex flex-column justify-space-evenly" style="min-height: 70vh;">
            <v-card-title>
              <div class="login-logo">
                <img src="@/assets/images/logo-smart.webp" alt="Smart Evolution Logo">
              </div>
            </v-card-title>
            <v-form @submit.prevent="handleLogin" class="login-form">
              <v-card-text class="my-4">
                <v-text-field :label="$t('login.username')" type="text" id="username" v-model="loginData.username"
                  autocomplete="username" variant="underlined" dense required
                  prepend-icon="mdi-account-circle"></v-text-field>

                <v-text-field :label="$t('login.password')" type="password" id="password" v-model="loginData.password"
                  autocomplete="current-password" variant="underlined" dense required
                  prepend-icon="mdi-lock"></v-text-field>
              </v-card-text>
              <v-card-actions class="flex-column align-center pa-4">
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn variant="tonal" class="bg-primary rounded-0" type="submit" block v-bind="props"
                    :elevation="isHovering ? 8 : 2">
                    <v-icon left class="mr-2">mdi-login</v-icon>{{ $t('login.submitButton') }}
                  </v-btn>
                </v-hover>
              </v-card-actions>
              <div v-if="alertMessage" class="alert-message">{{ alertMessage }}</div>
              <div v-if="loginErrors.username" class="alert-message">* {{ loginErrors.username }}</div>
              <div v-if="loginErrors.password" class="alert-message">* {{ loginErrors.password }}</div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>


<script>
import OrthoLanguages from '@/components/OrthoLanguages.vue';

export default {
  components: {
    OrthoLanguages
  },
  name: 'OrthoLogin',
  data() {
    return {
      loginData: {
        username: '',
        password: ''
      },
      loginErrors: {
        username: null,
        password: null
      },
      alertMessage: ''
    };
  },
  watch: {
    'loginData.username'() {
      this.alertMessage = '';
    },
    'loginData.password'() {
      this.alertMessage = '';
    }
  },
  computed: {
    isFormInvalid() {
      return !this.loginData.username || !this.loginData.password;
    }
  },
  methods: {
    handleLogin() {
      this.resetErrors();
      let isValid = true;

      if (!this.loginData.username) {
        this.loginErrors.username = this.$t('login.usernameRequired');
        isValid = false;
      }

      if (!this.loginData.password) {
        this.loginErrors.password = this.$t('login.passwordRequired');
        isValid = false;
      }

      // Verificação da senha padrão para testes
      const senhaPadrao = 'senhaTeste';
      if (isValid && this.loginData.password !== senhaPadrao) {
        this.alertMessage = this.$t('login.wrongPassword');
        return;
      }

      if (isValid && this.loginData.password === senhaPadrao) {
        this.$store.commit('setLoggedIn', true);
        this.$router.push({ name: 'OrthoDash' });
      } else if (isValid) {
        console.log('Processo de login deve continuar');
      }
    },
    resetErrors() {
      this.loginErrors.username = null;
      this.loginErrors.password = null;
    }
  },
  mounted() {
    console.log(`%c${process.env.NODE_ENV}`, "font-size:24px;background-color:red;")
    console.table(process.env.VUE_APP_CLEAR_TASKS);
  
    if (this.$store.state.redirectMessage) {
      // Traduz a chave de mensagem armazenada no Vuex
      this.alertMessage = this.$t(this.$store.state.redirectMessage);
      // Limpa a chave da mensagem no Vuex
      this.$store.commit('setRedirectMessage', '');
    }
    document.documentElement.style.overflowY = 'hidden';
  },
  unmounted() {
    document.documentElement.style.overflowY = null;
  }
};
</script>


<style scoped>
.v-card-title {
  font-size: 1rem;
}

.login-logo img {
  margin-bottom: 8px;
  width: 100%;
  height: auto;
}

.login-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-logo h2 {
  margin: 0;
  font-weight: bold;
  color: rgb(var(--v-theme-highlight));
}

.error {
  color: rgb(var(--v-theme-error-base));
  margin-top: -4px;
  margin-bottom: 16px;
  font-size: 0.875em;
}

.alert-message {
  color: rgb(var(--v-theme-error));
  margin-top: 0px;
  text-align: center;
}

.ortho-login-background {
  background-image: url('../assets/images/background-login.jpeg');
  /* Insira o caminho correto da imagem */
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100vh;
  /* Ajuste a altura conforme necessário para o seu design */
}
</style>

